import { css, html, LitElement } from 'lit';
import WebmoduleIconButton from '../components/icon-button/icon-button.js';
import type { WebmoduleRemoveEvent } from '../events/webmodule-remove.js';
import type { WebmoduleRequestCloseEvent } from '../events/webmodule-request-close.js';
import type { WebmoduleSelectEvent } from '../events/webmodule-select.js';
import type WebmoduleAlert from '../components/alert/alert.js';
import type WebmoduleButton from '../components/button/button.js';
import type WebmoduleDrawer from '../components/drawer/drawer.js';
import type WebmoduleModal from '../components/modal/modal.js';
import type WebmoduleTab from '../components/tab/tab.js';
import type WebmoduleTabGroup from '../components/tab-group/tab-group.js';
import type WebmoduleTabPanel from '../components/tab-panel/tab-panel.js';

export default class ComponentApp extends LitElement {
  // Define styles
  static styles = css`
    #app {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .sidebar {
      flex: 0 0 10%;
      position: sticky;
      top: 1rem;
    }

    .navigation ul {
      margin: 1rem 0;
      padding: 0;
      list-style: none;
    }

    .navigation ul li {
      margin: 0;
      padding: 0;
    }

    .navigation ul li a {
      padding: 0.25rem 0;
      display: block;
      color: var(--webmodule-color-primary-600);
    }

    .content {
      flex: 0 0 88%;
    }

    .switcher {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      margin-bottom: 2rem;
    }

    h2 {
      margin: 1rem 0;
      font-size: var(--webmodule-font-size-x-large);
    }

    h3 {
      margin: 0.75rem 0;
      font-size: var(--webmodule-font-size-large);
    }

    .switcher h2 {
      margin: 0 1rem 0 0 !important;
      flex-basis: auto;
    }

    .section-wrapper {
      padding: 2rem;
      border: 1px solid var(--webmodule-color-gray-300);
      margin-bottom: 2rem;
      border-radius: var(--webmodule-border-radius-x-large);
      background: var(--webmodule-color-gray-50);
    }

    .section-content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .section-content .content-column {
      flex: 0 0 100%;
    }

    .section-content .content-column > h2:first-child {
      margin-top: 0 !important;
    }

    .section-content.four-col .content-column {
      flex: 0 0 23%;
    }

    .section-content.three-col .content-column {
      flex: 0 0 31.333333%;
    }

    .section-content.two-col .content-column {
      flex: 0 0 48%;
    }

    .todo {
      border: 1px solid var(--webmodule-color-red-500);
      background: var(--webmodule-color-red-100);
      padding: 0.5rem;
      margin: 1rem 0;
    }

    .todo:before {
      content: 'TO DO';
      display: block;
      margin-bottom: 0.5rem;
      color: var(--webmodule-color-red-500);
      font-weight: var(--webmodule-font-weight-bold);
    }

    .my-card {
      max-width: 300px;
    }

    .my-card [slot='header'] {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .my-card h3 {
      margin: 0;
    }

    .my-card [slot='footer'] {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .placeholder-example header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .placeholder-example header webmodule-placeholder:last-child {
      flex: 0 0 auto;
      width: 30%;
    }

    .placeholder-example webmodule-placeholder {
      margin-bottom: 1rem;
    }

    .placeholder-example .speech-bubble {
      float: left;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      vertical-align: middle;
    }

    .placeholder-example .speech-bubble::part(display) {
      --border-raduis: 0;
      clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
    }

    .placeholder-example header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .placeholder-example header webmodule-placeholder:last-child {
      flex: 0 0 auto;
      width: 30%;
    }

    .placeholder-example webmodule-placeholder {
      margin-bottom: 1rem;
    }

    .placeholder-example .speech-bubble {
      float: left;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      vertical-align: middle;
    }

    .placeholder-example .speech-bubble::part(display) {
      --border-raduis: 0;
      clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
    }

    .webmodule-control-left-label + .webmodule-control-left-label {
      margin-top: var(--webmodule-spacing-medium);
    }

    .webmodule-control-left-label::part(form-control) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .webmodule-control-left-label::part(form-control-label) {
      flex: 0 0 30%;
      max-width: 200px;
      padding-right: var(--webmodule-spacing-medium);
    }

    .webmodule-control-left-label::part(form-control-input) {
      flex: 1 1 100%;
    }

    .indent-lines {
      --indent-guide-width: 1px;
    }
  `;

  switchTheme(e: WebmoduleSelectEvent) {
    const allThemes = ['webmodule-theme-gray', 'webmodule-theme-dark', 'webmodule-theme-light', 'webmodule-theme-dev'];

    function setTheme(name: string) {
      document.documentElement.classList.remove(...allThemes.filter(x => x !== name));
      document.documentElement.classList.add(name);
    }

    switch (e.detail.item.value) {
      case 'light':
        setTheme('webmodule-theme-light');
        break;
      case 'gray':
        setTheme('webmodule-theme-gray');
        break;
      case 'dark':
        setTheme('webmodule-theme-dark');
        break;
      case 'developer':
        setTheme('webmodule-theme-dev');
        break;
    }
  }

  // Define the template
  render() {
    return html`
      <div id="app">
        <sidebar class="sidebar">
          <div class="switcher">
            <div class="buttonContainer">
              <div>Switch Themes?</div>
              <webmodule-dropdown distance="8">
                <webmodule-button slot="trigger" caret>Select Theme</webmodule-button>
                <webmodule-menu
                  id="switch-theme-menu"
                  @webmodule-select=${(e: WebmoduleSelectEvent) => this.switchTheme(e)}
                >
                  <webmodule-menu-item value="light">Light</webmodule-menu-item>
                  <webmodule-menu-item value="gray">Gray</webmodule-menu-item>
                  <webmodule-menu-item value="dark">Dark</webmodule-menu-item>
                  <webmodule-menu-item value="developer">Developer</webmodule-menu-item>
                </webmodule-menu>
              </webmodule-dropdown>
            </div>
          </div>
          <div class="navigation">
            <ul>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-tooltip"
                  >Tooltip
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-button"
                  >Button
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-menu"
                  >Menu and Dropdown
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-icon"
                  >Icon
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-input"
                  >Input Control
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-money"
                  >Input Money Control
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-select"
                  >Select Control
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-textarea"
                  >Textarea Control
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-radio"
                  >Radio Group + Checkbox
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-tag"
                  >Tag
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-navigation"
                  >Navigation
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-details"
                  >Detail
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-alert"
                  >Alert
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-card"
                  >Card
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-dialog"
                  >Dialog
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-format"
                  >Format
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-other"
                  >Other
                </webmodule-button>
              </li>
              <li>
                <webmodule-button
                  @click="${(e: PointerEvent) => this.navToSection(e)}"
                  variant="text"
                  href="#section-color"
                  >Color Swatches
                </webmodule-button>
              </li>
            </ul>
          </div>
        </sidebar>

        <section id="section-tooltip" class="content">
          <!--          Tooltips-->
          <div class="section-wrapper">
            <div class="section-header">
              <h1>Tootip</h1>
            </div>
            <div class="section-content">
              <div class="content-column">
                <h2>Placement</h2>
                <webmodule-button-group>
                  <webmodule-tooltip content="top-start" placement="top-start">
                    <webmodule-button>top-start</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="top" placement="top">
                    <webmodule-button>top</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="top-end" placement="top-end">
                    <webmodule-button>top-end</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="left-start" placement="left-start">
                    <webmodule-button>left-start</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="left" placement="left">
                    <webmodule-button>left</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="left-end" placement="left-end">
                    <webmodule-button>left-end</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="right-start" placement="right-start">
                    <webmodule-button>right-start</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="right" placement="right">
                    <webmodule-button>right</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="right-end" placement="right-end">
                    <webmodule-button>right-end</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="bottom-start" placement="bottom-start">
                    <webmodule-button>bottom-start</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="bottom" placement="bottom">
                    <webmodule-button>bottom</webmodule-button>
                  </webmodule-tooltip>

                  <webmodule-tooltip content="bottom-end" placement="bottom-end">
                    <webmodule-button>bottom-end</webmodule-button>
                  </webmodule-tooltip>
                </webmodule-button-group>
              </div>
            </div>
          </div>

          <!--          Buttons-->
          <div id="section-button" class="section-wrapper">
            <div class="section-header">
              <h1>Button</h1>
            </div>
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Variants</h2>
                <div>
                  <webmodule-button variant="default">Default</webmodule-button>
                  <webmodule-button variant="primary">Primary</webmodule-button>
                  <webmodule-button variant="success">Success</webmodule-button>
                  <webmodule-button variant="neutral">Neutral</webmodule-button>
                  <webmodule-button variant="warning">Warning</webmodule-button>
                  <webmodule-button variant="danger">Danger</webmodule-button>
                  <webmodule-button variant="text">Text</webmodule-button>
                </div>
                <div>
                  <h3>Outlined</h3>
                  <webmodule-button variant="default" outline>Default</webmodule-button>
                  <webmodule-button variant="primary" outline>Primary</webmodule-button>
                  <webmodule-button variant="success" outline>Success</webmodule-button>
                  <webmodule-button variant="neutral" outline>Neutral</webmodule-button>
                  <webmodule-button variant="warning" outline>Warning</webmodule-button>
                  <webmodule-button variant="danger" outline>Danger</webmodule-button>
                  <webmodule-button variant="text" outline>Text</webmodule-button>
                </div>
                <div>
                  <h3>Disabled</h3>
                  <webmodule-button variant="default" disabled>Default</webmodule-button>
                  <webmodule-button variant="primary" disabled>Primary</webmodule-button>
                  <webmodule-button variant="success" disabled>Success</webmodule-button>
                  <webmodule-button variant="neutral" disabled>Neutral</webmodule-button>
                  <webmodule-button variant="warning" disabled>Warning</webmodule-button>
                  <webmodule-button variant="danger" disabled>Danger</webmodule-button>
                </div>
                <div>
                  <h3>Loading</h3>
                  <webmodule-button variant="default" loading>Default</webmodule-button>
                  <webmodule-button variant="primary" loading>Primary</webmodule-button>
                  <webmodule-button variant="success" loading>Success</webmodule-button>
                  <webmodule-button variant="neutral" loading>Neutral</webmodule-button>
                  <webmodule-button variant="warning" loading>Warning</webmodule-button>
                  <webmodule-button variant="danger" loading>Danger</webmodule-button>
                </div>

                <div>
                  <h3>Button Group</h3>
                  <webmodule-button-group>
                    <webmodule-button>Button 1</webmodule-button>
                    <webmodule-button>Button 2</webmodule-button>
                    <webmodule-button>Button 3</webmodule-button>
                  </webmodule-button-group>

                  <h3>Button Group with Pill Buttons</h3>
                  <webmodule-button-group>
                    <webmodule-button pill>Button 1</webmodule-button>
                    <webmodule-button>Button 2</webmodule-button>
                    <webmodule-button pill>Button 3</webmodule-button>
                  </webmodule-button-group>
                </div>
              </div>
              <div class="content-column">
                <h2>Size</h2>
                <webmodule-button size="small">Small</webmodule-button>
                <webmodule-button size="medium">Medium</webmodule-button>
                <webmodule-button size="large">Large</webmodule-button>

                <h2>Pill</h2>
                <webmodule-button size="small" pill>Small</webmodule-button>
                <webmodule-button size="medium" pill>Medium</webmodule-button>
                <webmodule-button size="large" pill>Large</webmodule-button>

                <h2>Circle</h2>
                <webmodule-button size="small" circle>
                  <webmodule-icon name="eye"></webmodule-icon>
                </webmodule-button>
                <webmodule-button size="medium" circle>
                  <webmodule-icon name="eye"></webmodule-icon>
                </webmodule-button>
                <webmodule-button size="large" circle>
                  <webmodule-icon name="eye"></webmodule-icon>
                </webmodule-button>

                <div>
                  <h2>With Badge</h2>
                  <webmodule-button>
                    Inbox
                    <webmodule-badge>30</webmodule-badge>
                  </webmodule-button>
                </div>
              </div>
            </div>
          </div>

          <!-- Menu and Dropdown-->
          <div id="section-menu" class="section-wrapper">
            <div class="section-header">
              <h1>Menu and Dropdown</h1>
            </div>

            <div class="section-content two-col">
              <div class="content-column">
                <h2>Menu</h2>
                <webmodule-menu style="max-width: 200px">
                  <webmodule-menu-label>Menu Label</webmodule-menu-label>
                  <webmodule-menu-item>Option 1</webmodule-menu-item>
                  <webmodule-menu-item>Option 2</webmodule-menu-item>
                  <webmodule-divider></webmodule-divider>
                  <webmodule-menu-item type="checkbox" checked>Checkbox</webmodule-menu-item>
                  <webmodule-menu-item disabled>Disabled</webmodule-menu-item>
                  <webmodule-divider></webmodule-divider>
                  <webmodule-menu-item>
                    Prefix Icon
                    <webmodule-icon slot="prefix" name="bin"></webmodule-icon>
                  </webmodule-menu-item>
                  <webmodule-menu-item>
                    Suffix Icon
                    <webmodule-icon slot="suffix" name="eye"></webmodule-icon>
                  </webmodule-menu-item>

                  <webmodule-menu-label>Actions</webmodule-menu-label>
                  <webmodule-menu-item>
                    Find
                    <webmodule-menu slot="submenu">
                      <webmodule-menu-item value="find">Find…</webmodule-menu-item>
                      <webmodule-menu-item value="find-previous">Find Next</webmodule-menu-item>
                      <webmodule-menu-item value="find-next">Find Previous</webmodule-menu-item>
                    </webmodule-menu>
                  </webmodule-menu-item>
                </webmodule-menu>
              </div>
              <div class="content-column">
                <h2>Dropdown</h2>
                <h3>Size</h3>
                <webmodule-dropdown>
                  <webmodule-button slot="trigger" caret size="small">Small</webmodule-button>
                  <webmodule-menu>
                    <webmodule-menu-item>Dropdown Item 1</webmodule-menu-item>
                    <webmodule-menu-item>Dropdown Item 2</webmodule-menu-item>
                  </webmodule-menu>
                </webmodule-dropdown>

                <webmodule-dropdown>
                  <webmodule-button slot="trigger" caret size="medium">Medium</webmodule-button>
                  <webmodule-menu>
                    <webmodule-menu-item>Dropdown Item 1</webmodule-menu-item>
                    <webmodule-menu-item>Dropdown Item 2</webmodule-menu-item>
                  </webmodule-menu>
                </webmodule-dropdown>

                <webmodule-dropdown>
                  <webmodule-button slot="trigger" caret size="large">Large</webmodule-button>
                  <webmodule-menu>
                    <webmodule-menu-item>Dropdown Item 1</webmodule-menu-item>
                    <webmodule-menu-item>Dropdown Item 2</webmodule-menu-item>
                  </webmodule-menu>
                </webmodule-dropdown>

                <div>
                  <h3>Placement</h3>
                  <p>
                    Valid values:
                    <code
                      >'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start',
                      'right-end', 'left', 'left-start', 'left-end'</code
                    >
                  </p>
                  <webmodule-dropdown placement="left">
                    <webmodule-button slot="trigger" size="small">left</webmodule-button>
                    <webmodule-menu>
                      <webmodule-menu-item>Dropdown Item 1</webmodule-menu-item>
                      <webmodule-menu-item>Dropdown Item 2</webmodule-menu-item>
                    </webmodule-menu>
                  </webmodule-dropdown>
                </div>
              </div>
            </div>
          </div>

          <!--          Icon-->
          <div id="section-icon" class="section-wrapper">
            <div class="section-header">
              <h1>Icon</h1>
            </div>
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Icon</h2>
                <webmodule-icon style="font-size: 24px" name="eye" label="settings"></webmodule-icon>
              </div>
              <div class="content-column">
                <h2>Icon Button</h2>
                <webmodule-icon-button style="font-size: 24px" name="eye" label="settings"></webmodule-icon-button>
              </div>
            </div>
          </div>

          <!--          Input Control-->
          <div id="section-input" class="section-wrapper">
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Input Control</h2>
                <h3>Sizes</h3>
                <webmodule-input placeholder="Small" size="small"></webmodule-input>
                <br />
                <webmodule-input placeholder="Medium" size="medium"></webmodule-input>
                <br />
                <webmodule-input placeholder="Large" size="large"></webmodule-input>
                <h3>Pill</h3>
                <webmodule-input placeholder="Small" size="small" pill></webmodule-input>
                <br />
                <webmodule-input placeholder="Medium" size="medium" pill></webmodule-input>
                <br />
                <webmodule-input placeholder="Large" size="large" pill></webmodule-input>
                <br />

                <h2>Types</h2>
                <webmodule-input type="number" placeholder="number" size="small"></webmodule-input>
                <br />
                <webmodule-input type="password" placeholder="password" size="small"></webmodule-input>
                <br />
                <webmodule-input type="date" placeholder="number" size="small"></webmodule-input>
                <br />
                <webmodule-input type="datetime-local" size="small"></webmodule-input>
                <br />
                <webmodule-input type="time" size="small"></webmodule-input>

                <h2>Input with Indicator slots</h2>
                <webmodule-input placeholder="prefix slot" size="small">
                  <span slot="prefix">$</span>
                </webmodule-input>
                <br />
                <webmodule-input placeholder="suffix slot" size="small">
                  <span slot="suffix">%</span>
                </webmodule-input>
              </div>
              <div class="content-column">
                <h2>Label Position</h2>
                <h3>Top</h3>
                <webmodule-input placeholder="Preferred to be called?" label="Name:" size="small"></webmodule-input>
                <h3>Left</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Preferred to be called?"
                  label="Name:"
                  size="small"
                ></webmodule-input>

                <h2>Attributes</h2>
                <h3>required</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Required attribute set"
                  size="small"
                  required
                  label="Name:"
                ></webmodule-input>
                <h3>clearable</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Clearable attribute set"
                  size="small"
                  clearable
                  label="Name:"
                  value="Johan"
                ></webmodule-input>
                <h3>placeholder</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Control with Placeholder"
                  size="small"
                  clearable
                  label="Name:"
                ></webmodule-input>
                <h3>password-toggle</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Password"
                  size="small"
                  password-toggle
                  label="Password:"
                  type="password"
                ></webmodule-input>
                <h3>filled</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Filled attribute set"
                  size="small"
                  filled
                  label="Name:"
                ></webmodule-input>
                <h3>disabled</h3>
                <webmodule-input
                  class="webmodule-control-left-label"
                  placeholder="Disabled attribute set"
                  size="small"
                  disabled
                  label="Name:"
                ></webmodule-input>
              </div>
            </div>
          </div>

          <!--          Input-Money Control-->
          <div id="section-money" class="section-wrapper">
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Input Money Control</h2>
                <h3>Sizes</h3>
                <webmodule-input-money placeholder="Small" size="small" value="1234567.34"></webmodule-input-money>
                <br />
                <webmodule-input-money placeholder="Medium" size="medium" value="1234567.34"></webmodule-input-money>
                <br />
                <webmodule-input-money placeholder="Large" size="large" value="1234567.34"></webmodule-input-money>
                <h3>Pill</h3>
                <webmodule-input-money placeholder="Small" size="small" pill value="1234567.34"></webmodule-input-money>
                <br />
                <webmodule-input-money
                  placeholder="Medium"
                  size="medium"
                  pill
                  value="1234567.34"
                  currency-display="narrowSymbol"
                ></webmodule-input-money>
                <br />
                <webmodule-input-money placeholder="Large" size="large" pill value="1234567.34"></webmodule-input-money>
                <h3>Negative Value</h3>
                <webmodule-input-money placeholder="Small" size="small" value="-1234567.34"></webmodule-input-money>
              </div>
              <div class="content-column">
                <h2>Label Position</h2>
                <h3>Top</h3>
                <webmodule-input-money placeholder="Budget?" label="Budget:" size="small"></webmodule-input-money>
                <h3>Left</h3>
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  placeholder="Budget?"
                  label="Budget:"
                  size="small"
                ></webmodule-input-money>

                <h3>Currency Display</h3>
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  label="symbol"
                  placeholder="Small"
                  size="small"
                  value="123"
                  currency-display="symbol"
                ></webmodule-input-money>
                <br />
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  label="code"
                  placeholder="Small"
                  size="small"
                  value="123"
                  currency-display="code"
                ></webmodule-input-money>
                <br />
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  label="name"
                  placeholder="Small"
                  size="small"
                  value="123"
                  currency-display="name"
                ></webmodule-input-money>

                <h3>Currency</h3>
                <p><code>currency</code> set to any valid ISO 4217 code (Default = 'USD')</p>
                <br />
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  label="NZD"
                  placeholder="Small"
                  size="small"
                  value="123"
                  currency="NZD"
                ></webmodule-input-money>
                <br />
                <webmodule-input-money
                  class="webmodule-control-left-label"
                  label="ZAR"
                  placeholder="Small"
                  size="small"
                  value="123"
                  currency="ZAR"
                ></webmodule-input-money>
              </div>
            </div>
          </div>

          <!--          Select Control-->
          <div id="section-select" class="section-wrapper">
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Select Control</h2>
                <h3>Sizes</h3>
                <webmodule-select placeholder="Small" size="small">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <br />
                <webmodule-select placeholder="Medium" size="medium">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <br />
                <webmodule-select placeholder="Large" size="large">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <h3>Pill</h3>
                <webmodule-select placeholder="Small" size="small" pill>
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <br />
                <webmodule-select placeholder="Medium" size="medium" pill>
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <br />
                <webmodule-select placeholder="Large" size="large" pill>
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>

                <h2>With option divider</h2>
                <webmodule-select placeholder="Large" size="small">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                  <webmodule-divider></webmodule-divider>
                  <webmodule-option value="option3">Option 3</webmodule-option>
                  <webmodule-option value="option4">Option 4</webmodule-option>
                </webmodule-select>

                <h2>Multi-Select</h2>
                <webmodule-select label="Select a Few" value="option-1 option-2 option-3" multiple clearable>
                  <webmodule-option value="option-1">Option 1</webmodule-option>
                  <webmodule-option value="option-2">Option 2</webmodule-option>
                  <webmodule-option value="option-3">Option 3</webmodule-option>
                  <webmodule-option value="option-4">Option 4</webmodule-option>
                  <webmodule-option value="option-5">Option 5</webmodule-option>
                  <webmodule-option value="option-6">Option 6</webmodule-option>
                </webmodule-select>
              </div>
              <div class="content-column">
                <h2>Label Position</h2>
                <h3>Top</h3>
                <webmodule-select size="small" label="Select Option">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <h3>Left</h3>
                <webmodule-select class="webmodule-control-left-label" size="small" label="Select Option">
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>

                <h2>Attributes</h2>
                <h3>clearable</h3>
                <webmodule-select
                  class="webmodule-control-left-label"
                  size="small"
                  label="Select Option"
                  value="option1"
                  clearable
                >
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>

                <h3>placeholder</h3>
                <webmodule-select
                  class="webmodule-control-left-label"
                  placeholder="Control with placeholder"
                  size="small"
                  label="Select Option"
                >
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <h3>filled</h3>
                <webmodule-select class="webmodule-control-left-label" label="Select Option" filled>
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
                <h3>disabled</h3>
                <webmodule-select
                  class="webmodule-control-left-label"
                  disabled
                  size="small"
                  label="Select Option"
                  value="option1"
                >
                  <webmodule-option value="option1">Option 1</webmodule-option>
                  <webmodule-option value="option2">Option 2</webmodule-option>
                </webmodule-select>
              </div>
            </div>
          </div>

          <!--          Textarea Control-->
          <div id="section-textarea" class="section-wrapper">
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Textarea Control</h2>
                <h3>Sizes</h3>
                <webmodule-textarea placeholder="Small" size="small"></webmodule-textarea>
                <br />
                <webmodule-textarea placeholder="Medium" size="medium"></webmodule-textarea>
                <br />
                <webmodule-textarea placeholder="Large" size="large"></webmodule-textarea>
              </div>
              <div class="content-column">
                <h2>Label Position</h2>
                <h3>Top</h3>
                <webmodule-textarea placeholder="Notes?" label="Notes:" size="small"></webmodule-textarea>
                <h3>Left</h3>
                <webmodule-textarea
                  class="webmodule-control-left-label"
                  placeholder="Notes?"
                  label="Notes:"
                  size="small"
                ></webmodule-textarea>

                <h2>Attributes</h2>
                <h3>filled</h3>
                <webmodule-textarea
                  class="webmodule-control-left-label"
                  placeholder="filled"
                  label="Notes:"
                  size="small"
                  filled
                ></webmodule-textarea>
                <h3>disabled</h3>
                <webmodule-textarea
                  class="webmodule-control-left-label"
                  placeholder="disabled"
                  label="Notes:"
                  size="small"
                  disabled
                ></webmodule-textarea>
                <h3>resize="none"</h3>
                <webmodule-textarea
                  class="webmodule-control-left-label"
                  label="Notes:"
                  size="small"
                  resize="none"
                ></webmodule-textarea>
              </div>
            </div>
          </div>

          <!--          Radio/checkbox and toggle-->
          <div id="section-radio" class="section-wrapper">
            <div class="section-content three-col">
              <div class="content-column">
                <h2>Radio Group - Radio</h2>
                <h3>Size</h3>
                <h4>Small</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="small">
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>
                <h4>Medium</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="medium">
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>
                <h4>Large</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="large">
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>

                <h3>Label on Top</h3>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1">
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>

                <h3>Label on left</h3>
                <webmodule-radio-group
                  class="webmodule-control-left-label"
                  label="Choice of fruit:"
                  name="a"
                  value="1"
                  size="small"
                >
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>

                <h3>Inline</h3>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" inline size="small">
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>

                <h3>Inline and Left label</h3>
                <webmodule-radio-group
                  class="webmodule-control-left-label"
                  label="Choice of fruit:"
                  name="a"
                  value="1"
                  inline
                  size="small"
                >
                  <webmodule-radio value="1">Apple</webmodule-radio>
                  <webmodule-radio value="2">Orange</webmodule-radio>
                  <webmodule-radio value="3">Grape</webmodule-radio>
                </webmodule-radio-group>
              </div>
              <div class="content-column">
                <h2>Radio Group - Buttons</h2>
                <h3>Size</h3>
                <h4>Small</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="small">
                  <webmodule-radio-button value="1">Apple</webmodule-radio-button>
                  <webmodule-radio-button value="2">Orange</webmodule-radio-button>
                  <webmodule-radio-button value="3">Grape</webmodule-radio-button>
                </webmodule-radio-group>
                <h4>Medium</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="medium">
                  <webmodule-radio-button value="1">Apple</webmodule-radio-button>
                  <webmodule-radio-button value="2">Orange</webmodule-radio-button>
                  <webmodule-radio-button value="3">Grape</webmodule-radio-button>
                </webmodule-radio-group>
                <h4>Large</h4>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1" size="large">
                  <webmodule-radio-button value="1">Apple</webmodule-radio-button>
                  <webmodule-radio-button value="2">Orange</webmodule-radio-button>
                  <webmodule-radio-button value="3">Grape</webmodule-radio-button>
                </webmodule-radio-group>

                <h3>Label on Top</h3>
                <webmodule-radio-group label="Choice of fruit:" name="a" value="1">
                  <webmodule-radio-button value="1">Apple</webmodule-radio-button>
                  <webmodule-radio-button value="2">Orange</webmodule-radio-button>
                  <webmodule-radio-button value="3">Grape</webmodule-radio-button>
                </webmodule-radio-group>

                <h3>Label on left</h3>
                <webmodule-radio-group class="webmodule-control-left-label" label="Choice of fruit:" name="a" value="1">
                  <webmodule-radio-button value="1">Apple</webmodule-radio-button>
                  <webmodule-radio-button value="2">Orange</webmodule-radio-button>
                  <webmodule-radio-button value="3">Grape</webmodule-radio-button>
                </webmodule-radio-group>
              </div>
              <div class="content-column">
                <h2>Checkbox</h2>
                <webmodule-checkbox size="small">Small CheckBox</webmodule-checkbox>
                <br />
                <webmodule-checkbox size="medium"> Medium CheckBox</webmodule-checkbox>
                <br />
                <webmodule-checkbox size="large">Large CheckBox</webmodule-checkbox>
                <div class="todo">Add Inline checkbox group</div>
                <h2>Toggle</h2>
                <webmodule-toggle size="small">Small Toggle</webmodule-toggle>
                <br />
                <webmodule-toggle size="medium">Medium Toggle</webmodule-toggle>
                <br />
                <webmodule-toggle size="large">Large Toggle</webmodule-toggle>
              </div>
            </div>
          </div>

          <!--          Tag and Badge-->
          <div id="section-tag" class="section-wrapper">
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Tag</h2>
                <h3>Tag Variant</h3>
                <webmodule-tag variant="primary">Primary</webmodule-tag>
                <webmodule-tag variant="success" removable>Success</webmodule-tag>
                <webmodule-tag variant="neutral">Neutral</webmodule-tag>
                <webmodule-tag variant="warning" removable>Warning</webmodule-tag>
                <webmodule-tag variant="danger">Danger</webmodule-tag>
              </div>

              <div class="content-column">
                <h2>Badge</h2>
                <h3>Variants</h3>
                <webmodule-badge variant="success">success</webmodule-badge>
                <webmodule-badge variant="danger">danger</webmodule-badge>
                <webmodule-badge variant="warning">warning</webmodule-badge>
                <webmodule-badge variant="neutral">neutral</webmodule-badge>
                <webmodule-badge variant="primary">primary</webmodule-badge>

                <webmodule-divider></webmodule-divider>
                <h3>Pill</h3>
                <webmodule-badge variant="primary" pill>pill added</webmodule-badge>

                <webmodule-divider></webmodule-divider>
                <h3>Pulse</h3>
                <webmodule-badge variant="success" pulse>pulse added</webmodule-badge>
              </div>
            </div>
          </div>

          <!--          navigation-->
          <div id="section-navigation" class="section-wrapper">
            <div class="section-header">
              <h1>Navigation</h1>
            </div>

            <div class="section-content two-col">
              <div class="content-column">
                <h2>Tree Menu</h2>
                <webmodule-tree>
                  <webmodule-tree-item>
                    Top Level Item
                    <webmodule-tree-item>2nd Level Item 1</webmodule-tree-item>
                    <webmodule-tree-item>
                      2nd Level Item with Nested Items
                      <webmodule-tree-item>3rd Level Item 1</webmodule-tree-item>
                      <webmodule-tree-item>3rd Level Item 2</webmodule-tree-item>
                      <webmodule-tree-item>3rd Level Item 3</webmodule-tree-item>
                    </webmodule-tree-item>
                    <webmodule-tree-item>2nd Level Item 3</webmodule-tree-item>
                  </webmodule-tree-item>
                </webmodule-tree>

                <webmodule-divider></webmodule-divider>
                <h3>Expanded</h3>
                <webmodule-tree>
                  <webmodule-tree-item expanded>
                    Top Level Item
                    <webmodule-tree-item>2nd Level Item 1</webmodule-tree-item>
                    <webmodule-tree-item expanded>
                      2nd Level Item with Nested Items
                      <webmodule-tree-item>3rd Level Item 1</webmodule-tree-item>
                    </webmodule-tree-item>
                  </webmodule-tree-item>
                </webmodule-tree>

                <webmodule-divider></webmodule-divider>
                <h3>Selected</h3>
                <webmodule-tree>
                  <webmodule-tree-item expanded>
                    Top Level Item
                    <webmodule-tree-item selected>2nd Level</webmodule-tree-item>
                  </webmodule-tree-item>
                </webmodule-tree>

                <webmodule-divider></webmodule-divider>
                <h3>Indentation Line (via css) and Expander</h3>
                <webmodule-tree class="indent-lines">
                  <span slot="expand-icon">+</span>
                  <span slot="collapse-icon">|</span>
                  <webmodule-tree-item expanded>
                    Top Level Item
                    <webmodule-tree-item>2nd Level Item 1</webmodule-tree-item>
                    <webmodule-tree-item>
                      2nd Level Item with Nested Items
                      <webmodule-tree-item>3rd Level Item 1</webmodule-tree-item>
                    </webmodule-tree-item>
                  </webmodule-tree-item>
                </webmodule-tree>
              </div>

              <div class="content-column">
                <h2>Breadcrumbs</h2>
                <webmodule-breadcrumb>
                  <webmodule-breadcrumb-item>First</webmodule-breadcrumb-item>
                  <webmodule-breadcrumb-item>Second</webmodule-breadcrumb-item>
                  <webmodule-breadcrumb-item>Third</webmodule-breadcrumb-item>
                </webmodule-breadcrumb>

                <h2>Custom separator</h2>
                <webmodule-breadcrumb>
                  <span slot="separator">~</span>
                  <webmodule-breadcrumb-item> First</webmodule-breadcrumb-item>
                  <webmodule-breadcrumb-item>Second</webmodule-breadcrumb-item>
                  <webmodule-breadcrumb-item>Third</webmodule-breadcrumb-item>
                </webmodule-breadcrumb>

                <webmodule-divider></webmodule-divider>
                <h2>Tab Group</h2>
                <div class="todo">
                  Add Primary Large, Secondary Medium and Tertiary Small sizes/styling. Forward size property from Tab
                  group to a Tab element
                </div>

                <h3>Tabs Tertiary Small</h3>
                <div>
                  <webmodule-tab-group
                    id="tab-group-removeable-example"
                    size="small"
                    @webmodule-remove=${(e: WebmoduleRemoveEvent) => this.removeTab(e)}
                  >
                    <webmodule-tab slot="nav" panel="p1" active>Tab 1</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p2">Tab 2</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p3">Tab 3</webmodule-tab>

                    <webmodule-tab-panel name="p1">
                      <div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec quam et arcu euismod
                          pellentesque. In tempus diam diam, vitae efficitur mauris sodales et. Curabitur sit amet
                          malesuada risus, eu ultricies velit. Sed eget mauris quis odio consectetur rhoncus. Nullam ut
                          mauris id velit dictum tincidunt. Nam eu massa sagittis dui auctor viverra nec ornare ex.
                          Aliquam quam massa, cursus a enim finibus, pretium venenatis nisl.
                        </p>
                      </div>
                    </webmodule-tab-panel>
                    <webmodule-tab-panel name="p2">
                      <div>
                        <p>
                          Vivamus porta, tellus non commodo posuere, nunc lacus auctor justo, id aliquam lectus urna vel
                          tortor. Nulla mollis dui in tincidunt convallis. Ut eget augue mattis, suscipit libero ut,
                          finibus enim. Aenean aliquam turpis orci, non vestibulum ligula rhoncus at. Nullam dapibus
                          malesuada dui et efficitur. Sed molestie ornare condimentum. In lobortis dapibus lobortis. Sed
                          consectetur, ipsum sit amet viverra egestas, mauris urna dictum quam, sit amet dictum massa
                          felis at leo. Morbi non porttitor velit. Proin ac sapien mauris.
                        </p>
                      </div>
                    </webmodule-tab-panel>
                    <webmodule-tab-panel name="p3">
                      <div>
                        <p>
                          Etiam tortor nibh, condimentum id luctus in, aliquet pharetra erat. Cras in nunc in justo
                          vulputate viverra. In ac luctus ipsum. Class aptent taciti sociosqu ad litora torquent per
                          conubia nostra, per inceptos himenaeos. Suspendisse vestibulum vel mi eget congue. Curabitur
                          vulputate tortor lobortis vulputate congue. Orci varius natoque penatibus et magnis dis
                          parturient montes, nascetur ridiculus mus. Quisque eu congue tortor. Curabitur lacinia augue
                          vel nunc posuere bibendum. Sed egestas mauris ut elementum lacinia. Suspendisse metus massa,
                          elementum quis fermentum quis, congue imperdiet magna.
                        </p>
                      </div>
                    </webmodule-tab-panel>
                  </webmodule-tab-group>
                </div>

                <h3>Tabs Tertiary Scrollable</h3>
                <div style="max-width: 400px;">
                  <webmodule-tab-group
                    id="tab-group-removeable-example"
                    size="small"
                    @webmodule-remove=${(e: WebmoduleRemoveEvent) => this.removeTab(e)}
                  >
                    <webmodule-tab slot="nav" panel="p1" active>Tab 1</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p2" closable>Tab 2</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p3">Tab 3</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p4" closable>Tab 4</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p5" closable>Tab 5</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p6">Tab 6</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p7">Tab 7</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p8">Tab 8</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p9">Tab 9</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p10">Tab 10</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p11">Tab 11</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p12">Tab 12</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p13">Tab 13</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p14">Tab 14</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p15">Tab 15</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p16">Tab 16</webmodule-tab>

                    <webmodule-tab-panel name="p1">
                      <div>
                        <p>
                          If the control have more tabs than allowed to be visible, the control will render with scroll
                          controls
                        </p>
                      </div>
                    </webmodule-tab-panel>

                    <webmodule-tab-panel name="p2">
                      <div>
                        <p>Tab 2 Panel with <code>closeable</code> attribute added</p>
                        <p>
                          Listen for the <code>webmodule-remove</code> event on tab group and remove the tab and panel
                        </p>
                      </div>
                    </webmodule-tab-panel>
                    <webmodule-tab-panel name="p3">Tab 3 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p4">
                      <div>
                        <p>Tab 2 Panel with <code>closeable</code> attribute added</p>
                        <p>
                          Listen for the <code>webmodule-remove</code> event on tab group and remove the tab and panel
                        </p>
                      </div>
                    </webmodule-tab-panel>
                    <webmodule-tab-panel name="p5">
                      <div>
                        <p>Tab 2 Panel with <code>closeable</code> attribute added</p>
                        <p>
                          Listen for the <code>webmodule-remove</code> event on tab group and remove the tab and panel
                        </p>
                      </div>
                    </webmodule-tab-panel>
                    <webmodule-tab-panel name="p6">Tab 6 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p7">Tab 7 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p8">Tab 8 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p9">Tab 9 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p10">Tab 10 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p11">Tab 11 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p12">Tab 12 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p13">Tab 13 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p14">Tab 14 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p15">Tab 15 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p16">Tab 16 Panel</webmodule-tab-panel>
                  </webmodule-tab-group>
                </div>

                <h3>Tab Group - Placement</h3>
                <div>
                  <webmodule-tab-group placement="start" id="tab-group-placement-example">
                    <webmodule-tab slot="nav" panel="p1" active>Tab 1</webmodule-tab>
                    <webmodule-tab slot="nav" panel="p2">Tab 2</webmodule-tab>

                    <webmodule-tab-panel name="p1">Tab 1 Panel</webmodule-tab-panel>
                    <webmodule-tab-panel name="p2">Tab 2 Panel</webmodule-tab-panel>
                  </webmodule-tab-group>
                </div>
                <webmodule-button-group size="small" @click="${(e: PointerEvent) => this.changeTabGroupPlacement(e)}">
                  <webmodule-button variant="primary" value="start">start</webmodule-button>
                  <webmodule-button variant="primary" value="top">top</webmodule-button>
                  <webmodule-button variant="primary" value="end">end</webmodule-button>
                  <webmodule-button variant="primary" value="bottom">bottom</webmodule-button>
                </webmodule-button-group>
              </div>
            </div>
          </div>

          <!--          details-->
          <div id="section-details" class="section-wrapper">
            <div class="section-header">
              <h1>Details</h1>
            </div>

            <div class="section-content two-col">
              <div class="content-column">
                <webmodule-details summary="Want to see more... Click me">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum
                </webmodule-details>
              </div>
            </div>
          </div>

          <!--          alerts-->
          <div id="section-alert" class="section-wrapper">
            <div class="section-header">
              <h1>Alert</h1>
            </div>

            <div class="section-content two-col">
              <div class="content-column">
                <h2>Variants</h2>
                <webmodule-alert variant="primary" open> variant=<strong>primary</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="success" open> variant=<strong>success</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="neutral" open> variant=<strong>neutral</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="warning" open> variant=<strong>warning</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="danger" open> variant=<strong>danger</strong></webmodule-alert>

                <webmodule-divider></webmodule-divider>
                <h3>Toast Notifications</h3>
                <div class="alert-toast">
                  <webmodule-button-group @click=${(e: PointerEvent) => this.showToast(e)}>
                    <webmodule-button variant="primary">Primary Toast</webmodule-button>
                    <webmodule-button variant="success">Success Toast</webmodule-button>
                    <webmodule-button variant="neutral">Neutral Toast</webmodule-button>
                    <webmodule-button variant="warning">Warning Toast</webmodule-button>
                    <webmodule-button variant="danger">Danger Toast</webmodule-button>
                  </webmodule-button-group>

                  <webmodule-alert variant="primary" duration="3000">
                    <strong>Primary Toast</strong>
                  </webmodule-alert>

                  <webmodule-alert variant="success" duration="3000" closable>
                    <strong>Success Toast that is closable</strong>
                  </webmodule-alert>

                  <webmodule-alert variant="neutral" duration="3000">
                    <strong>Neutral Toast</strong>
                  </webmodule-alert>

                  <webmodule-alert variant="warning" duration="3000" closable>
                    <strong>Warning Toast that is closable</strong>
                  </webmodule-alert>

                  <webmodule-alert variant="danger" duration="3000">
                    <strong>Danger Toast</strong>
                  </webmodule-alert>
                </div>
              </div>

              <div class="content-column">
                <h2><code>closeable</code></h2>
                <webmodule-alert variant="primary" open closable>
                  <code>closable</code> attribute added
                </webmodule-alert>

                <webmodule-divider></webmodule-divider>
                <h3>With Icon</h3>
                <webmodule-alert variant="danger" open>
                  <span slot="icon">$</span>
                  Element in the <code>icon</code> slot.
                </webmodule-alert>

                <webmodule-divider></webmodule-divider>
                <h3>With <code>snack</code></h3>
                <h2>Variants</h2>
                <webmodule-alert variant="primary" open snack> variant=<strong>primary</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="success" open snack> variant=<strong>success</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="neutral" open snack> variant=<strong>neutral</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="warning" open snack> variant=<strong>warning</strong></webmodule-alert>
                <webmodule-divider></webmodule-divider>
                <webmodule-alert variant="danger" open snack>variant=<strong>danger</strong></webmodule-alert>
              </div>
            </div>
          </div>

          <!--          Card-->
          <div id="section-card" class="section-wrapper">
            <div class="section-header">
              <h1>Card</h1>
            </div>

            <div class="section-content">
              <div class="content-column">
                <h2>Card with Image and Footer slot</h2>
                <webmodule-card class="my-card">
                  <img slot="image" src="assets/cat.jpg" alt="Playful kitten" />

                  <strong>Zoid</strong><br />
                  Playful kitten exploring a flower.<br />
                  <small>Only a couple of weeks old</small>

                  <div slot="footer">
                    <webmodule-button variant="primary" pill>See more information</webmodule-button>
                  </div>
                </webmodule-card>

                <h2>Card with no Image and no Footer</h2>
                <webmodule-card class="my-card">
                  <strong>No Image and no footer</strong><br />
                  Card with only the default slot
                </webmodule-card>

                <h2>Card with Footer slot</h2>
                <webmodule-card class="my-card">
                  <strong>No Image and no footer</strong><br />
                  Card with only the default slot

                  <div slot="footer">Extra information for this card</div>
                </webmodule-card>
              </div>
            </div>
          </div>

          <!--          Dialog-->
          <div id="section-dialog" class="section-wrapper">
            <div class="section-header">
              <h1>Dialog</h1>
            </div>
            <div class="todo">Add styling to Modal Window</div>

            <div class="section-content two-col">
              <div class="content-column">
                <h2>Modal</h2>
                <h3>Size</h3>
                <webmodule-button-group @click=${(e: PointerEvent) => this.showModal(e)}>
                  <webmodule-button value="small" variant="primary">Small</webmodule-button>
                  <webmodule-button value="medium" variant="primary">Medium</webmodule-button>
                  <webmodule-button value="large" variant="primary">Large</webmodule-button>
                </webmodule-button-group>

                <h3>Header Actions</h3>
                <webmodule-button-group @click=${(e: PointerEvent) => this.showModalHeaderActions(e)}>
                  <webmodule-button value="remove-header" variant="primary">No Header</webmodule-button>
                  <webmodule-button value="header-actions" variant="primary">Header Actions</webmodule-button>
                </webmodule-button-group>

                <h3>Prevent Close</h3>
                <webmodule-button variant="primary" @click=${() => this.showModalPreventClose()}>Open</webmodule-button>

                <h3>Scrolling</h3>
                <webmodule-button variant="primary" @click=${() => this.showModalScrolling()}>Open</webmodule-button>

                <webmodule-modal
                  label="Lorem Ipsum"
                  class="modal-test"
                  @webmodule-after-hide=${() => this.resetModal()}
                >
                  <div class="modal-lorem-ipsum">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum
                  </div>
                  <webmodule-button slot="footer" variant="primary" @click=${() => this.closeModal()}
                    >Close
                  </webmodule-button>
                </webmodule-modal>
              </div>

              <div class="content-column">
                <h2>Drawer</h2>
                <div
                  style="position: relative; height: 300px; width: 600px; border: dashed 2px var(--webmodule-color-neutral-200); padding: 0 1rem;"
                >
                  Containing element with <code>position: relative</code>.
                  <br />
                  If the contained attribute is set the drawer will render in container.
                  <webmodule-drawer
                    label="Lorem Ipsum"
                    class="drawer-test"
                    @webmodule-after-hide=${() => this.resetDrawer()}
                  >
                    <div class="drawer-lorem-ipsum">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                      in culpa qui officia deserunt mollit anim id est laborum
                    </div>
                    <webmodule-button slot="footer" variant="primary" @click=${() => this.closeDrawer()}
                      >Close
                    </webmodule-button>
                  </webmodule-drawer>
                </div>
                <webmodule-button-group @click=${(e: PointerEvent) => this.openDrawer(e)}>
                  <webmodule-button variant="primary">View port</webmodule-button>
                  <webmodule-button variant="primary" value="contained">Contained</webmodule-button>
                </webmodule-button-group>

                <h3>Placement</h3>
                <webmodule-button-group @click=${(e: PointerEvent) => this.showDrawerPlacement(e)}>
                  <webmodule-button value="top" variant="primary">Top</webmodule-button>
                  <webmodule-button value="start" variant="primary">Start</webmodule-button>
                  <webmodule-button value="end" variant="primary">End</webmodule-button>
                  <webmodule-button value="bottom" variant="primary">Bottom</webmodule-button>
                </webmodule-button-group>

                <h3>Header Actions</h3>
                <webmodule-button-group @click=${(e: PointerEvent) => this.showDrawerHeaderActions(e)}>
                  <webmodule-button value="remove-header" variant="primary">No Header</webmodule-button>
                  <webmodule-button value="header-actions" variant="primary">Header Actions</webmodule-button>
                </webmodule-button-group>
              </div>
            </div>
          </div>

          <!--          Format-->
          <div id="section-format" class="section-wrapper">
            <div class="section-header">
              <h1>Format</h1>
            </div>
            <div class="section-content four-col">
              <div class="content-column">
                <h2>Format Number</h2>
                <h3>Percent</h3>
                <webmodule-format-number type="percent" value="0"></webmodule-format-number>
                <br />
                <webmodule-format-number type="percent" value="0.25"></webmodule-format-number>
                <br />
                <webmodule-format-number type="percent" value="0.50"></webmodule-format-number>
                <br />
                <webmodule-format-number type="percent" value="0.75"></webmodule-format-number>
                <br />
                <webmodule-format-number type="percent" value="1"></webmodule-format-number>

                <h3>Currency</h3>
                <webmodule-format-number type="currency" currency="USD" value="10000"></webmodule-format-number>
                <br />
                <webmodule-format-number type="currency" currency="EUR" value="10000"></webmodule-format-number>
                <br />
                <webmodule-format-number type="currency" currency="ZAR" value="10000"></webmodule-format-number>

                <h3>Decimal</h3>
                <webmodule-format-number value="10000" minimum-fraction-digits="2"></webmodule-format-number>
                <br />
              </div>

              <div class="content-column">
                <h2>Format Date</h2>
                <webmodule-format-date></webmodule-format-date>
                <br />
                <webmodule-format-date month="long" day="numeric" year="numeric"></webmodule-format-date>
                <br />
                <webmodule-format-date weekday="long"></webmodule-format-date>
                <br />
                <webmodule-format-date month="long"></webmodule-format-date>
                <br />
                <webmodule-format-date year="numeric"></webmodule-format-date>
                <br />
                <webmodule-format-date hour="numeric" minute="numeric"></webmodule-format-date>
                <br />
                <webmodule-format-date hour="numeric" minute="numeric" hour-format="12"></webmodule-format-date>
                <br />
                <webmodule-format-date
                  month="long"
                  day="numeric"
                  year="numeric"
                  hour="numeric"
                  minute="numeric"
                  hour-format="12"
                ></webmodule-format-date>
              </div>

              <div class="content-column">
                <h2>Format List</h2>
                <h3>Conjunction</h3>
                <webmodule-format-list value="Johan Kyley Liselle Alex Alexey Shane"></webmodule-format-list>
                <h3>Disjunction</h3>
                <webmodule-format-list
                  value="Johan Kyley Liselle Alex Alexey Shane"
                  type="disjunction"
                ></webmodule-format-list>
              </div>

              <div class="content-column">
                <h2>Format Relative Time</h2>
                <webmodule-format-relative-time date="2025-07-02T09:17:00-04:00"></webmodule-format-relative-time>
                <br />
                <webmodule-format-relative-time date="2024-07-02T09:17:00-04:00"></webmodule-format-relative-time>
                <br />
                <webmodule-format-relative-time date="2023-07-02T09:17:00-04:00"></webmodule-format-relative-time>
                <br />
                <webmodule-format-relative-time
                  numeric="always"
                  date="2025-07-02T09:17:00-04:00"
                ></webmodule-format-relative-time>
                <br />
                <webmodule-format-relative-time
                  numeric="always"
                  date="2024-07-01T09:17:00-04:00"
                ></webmodule-format-relative-time>
                <br />
                <webmodule-format-relative-time
                  numeric="always"
                  date="2023-07-02T09:17:00-04:00"
                ></webmodule-format-relative-time>
              </div>
            </div>
          </div>

          <!--          misc-->
          <div id="section-other" class="section-wrapper">
            <div class="section-header">
              <h1>Other</h1>
            </div>
            <div class="section-content two-col">
              <div class="content-column">
                <h2>Divider</h2>
                <webmodule-divider></webmodule-divider>
                <h3>Vertical</h3>
                <div style="display: flex; align-items: center; height: 2rem;">
                  Element
                  <webmodule-divider vertical></webmodule-divider>
                  Element
                  <webmodule-divider vertical></webmodule-divider>
                  Element
                </div>

                <h2>Placeholder</h2>
                <div class="placeholder-example">
                  <p>With <code>shimmer</code> attribute added</p>
                  <header>
                    <webmodule-placeholder class="speech-bubble" shimmer></webmodule-placeholder>
                    <webmodule-placeholder shimmer></webmodule-placeholder>
                  </header>
                  <p>Without <code>shimmer</code> attribute added</p>
                  <header>
                    <webmodule-placeholder class="speech-bubble"></webmodule-placeholder>
                    <webmodule-placeholder></webmodule-placeholder>
                  </header>
                </div>
              </div>

              <div class="content-column">
                <h2>QR Code</h2>
                <webmodule-qr-code
                  value="https://www.kuaka-testing.softtech.com/"
                  label="Webmodule website"
                ></webmodule-qr-code>

                <h2>Spinner</h2>
                <webmodule-spinner></webmodule-spinner>
              </div>
            </div>
          </div>

          <!--          colors-->
          <div id="section-color" class="section-wrapper">
            <div class="section-header">
              <h1>Colors</h1>
            </div>
            <div class="section-content three-col">
              <div class="content-column">
                ${this.generateColorVariants('primary')} ${this.generateColorVariants('success')}
                ${this.generateColorVariants('warning')} ${this.generateColorVariants('danger')}
                ${this.generateColorVariants('neutral')} ${this.generateColorVariants('gray')}
                ${this.generateColorVariants('red')} ${this.generateColorVariants('orange')}
                ${this.generateColorVariants('amber')} ${this.generateColorVariants('yellow')}
                ${this.generateColorVariants('lime')}
              </div>
              <div class="content-column">
                ${this.generateColorVariants('green')} ${this.generateColorVariants('emerald')}
                ${this.generateColorVariants('teal')} ${this.generateColorVariants('cyan')}
                ${this.generateColorVariants('sky')} ${this.generateColorVariants('indigo')}
                ${this.generateColorVariants('violet')} ${this.generateColorVariants('purple')}
                ${this.generateColorVariants('fuchsia')} ${this.generateColorVariants('pink')}
                ${this.generateColorVariants('rose')}
              </div>
              <div class="content-column">${this.generateColorVariants('neutral', [0, 1000])}</div>
            </div>
          </div>
        </section>
      </div>
    `;
  }

  private generateColorVariants(color: string, variations = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]) {
    return html`
      <h3>${color}</h3>
      <div style="display: flex; justify-content: space-around">
        ${variations.map(
          x => html`
            <div
              style="background-color: var(--webmodule-color-${color}-${x}); height: 30px; width: 30px; text-align: center"
            >
              <p style="color: var(${x > 400 ? '--webmodule-color-neutral-0' : '--webmodule-color-neutral-1000'})">
                ${x}
              </p>
            </div>
          `
        )}
      </div>
    `;
  }

  private showToast(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const alert = this.shadowRoot!.querySelector(`webmodule-alert[variant="${button.variant}"]:not([open])`) as
      | WebmoduleAlert
      | undefined;
    alert?.toast();
  }

  private removeTab(e: WebmoduleRemoveEvent) {
    const tabGroup = this.shadowRoot!.querySelector<WebmoduleTabGroup>(`#tab-group-removeable-example`);
    const tab = e.target as WebmoduleTab;
    const panel = tabGroup?.querySelector<WebmoduleTabPanel>(`webmodule-tab-panel[name="${tab.panel}"]`);

    if (tab.active)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      tabGroup?.show((tab.previousElementSibling! as WebmoduleTab).panel);

    tab.remove();
    panel?.remove();
  }

  private changeTabGroupPlacement(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const tabGroup = this.shadowRoot?.querySelector<WebmoduleTabGroup>(`#tab-group-placement-example`);

    if (button && tabGroup) {
      // @ts-expect-error value is passed in
      tabGroup.placement = button.value;
    }
  }

  private showModal(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const modal = this.shadowRoot?.querySelector<WebmoduleModal>(`.modal-test`);

    // @ts-expect-error value is passed in
    modal.size = button.value;
    modal?.show();
  }

  private showModalHeaderActions(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const modal = this.shadowRoot?.querySelector<WebmoduleModal>(`.modal-test`);

    if (button.value === 'remove-header' && modal) modal.noHeader = true;
    if (button.value === 'header-actions' && modal) {
      const iconButton = new WebmoduleIconButton();
      iconButton.name = 'eye';
      iconButton.slot = 'header-actions';
      modal.append(iconButton);
    }

    modal?.show();
  }

  private closeModal() {
    const modal = this.shadowRoot?.querySelector<WebmoduleModal>(`.modal-test`);

    modal?.hide();
  }

  private showModalPreventClose() {
    const modal = this.shadowRoot!.querySelector<WebmoduleModal>(`.modal-test`);

    if (modal) {
      modal.addEventListener('webmodule-request-close', this.handleDialogRequestClose);

      modal.show();
    }
  }

  private handleDialogRequestClose(e: WebmoduleRequestCloseEvent) {
    if (e.detail.source !== 'close-button') {
      e.preventDefault();
    }
  }

  private showModalScrolling() {
    const modal = this.shadowRoot!.querySelector<WebmoduleModal>(`.modal-test`);
    const content = modal?.querySelector<HTMLDivElement>('.modal-lorem-ipsum');

    if (modal && content) {
      content.style.height = '150vh';

      modal.show();
    }
  }

  private resetModal() {
    // Reusing the same modal so this method just reset the modal to it's default as per original markup
    const modal = this.shadowRoot!.querySelector<WebmoduleModal>(`.modal-test`)!;
    const headerActions = modal.querySelector('[slot="header-actions"]');
    const content = modal?.querySelector<HTMLDivElement>('.modal-lorem-ipsum');

    modal.size = 'medium';
    modal.noHeader = false;
    modal.removeEventListener('webmodule-request-close', this.handleDialogRequestClose);

    if (headerActions) {
      modal.removeChild(headerActions);
    }

    if (content) {
      content.style.removeProperty('height');
    }
  }

  private resetDrawer() {
    const drawer = this.shadowRoot!.querySelector<WebmoduleDrawer>(`.drawer-test`)!;
    const headerActions = drawer.querySelector('[slot="header-actions"]');

    drawer.noHeader = false;
    drawer.contained = false;
    drawer.placement = 'end';

    if (headerActions) {
      drawer.removeChild(headerActions);
    }
  }

  private closeDrawer() {
    const drawer = this.shadowRoot!.querySelector<WebmoduleDrawer>(`.drawer-test`)!;

    drawer?.hide();
  }

  private openDrawer(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const drawer = this.shadowRoot!.querySelector<WebmoduleDrawer>(`.drawer-test`)!;

    if (drawer && button.value === 'contained') {
      drawer.contained = true;
    }

    drawer?.show();
  }

  private showDrawerPlacement(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const drawer = this.shadowRoot!.querySelector<WebmoduleDrawer>(`.drawer-test`)!;

    // @ts-expect-error value is passed in
    drawer.placement = button.value;
    drawer?.show();
  }

  private showDrawerHeaderActions(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const drawer = this.shadowRoot!.querySelector<WebmoduleDrawer>(`.drawer-test`)!;

    if (button.value === 'remove-header' && drawer) drawer.noHeader = true;
    if (button.value === 'header-actions' && drawer) {
      const iconButton = new WebmoduleIconButton();
      iconButton.name = 'eye';
      iconButton.slot = 'header-actions';
      drawer.append(iconButton);
    }

    drawer?.show();
  }

  private navToSection(e: PointerEvent) {
    const button = e.target as WebmoduleButton;
    const section = this.shadowRoot!.querySelector(button.href)!;

    if (section) section.scrollIntoView({ behavior: 'smooth' });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'component-app': ComponentApp;
  }
}
