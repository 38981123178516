import { css } from 'lit';

export default css`
  :host {
    display: inline-block;
  }

  //:host([size='small']) {
  //  font-size: var(--webmodule-control-font-size-small);
  //}
  //
  //:host([size='medium']) {
  //  font-size: var(--webmodule-control-font-size-medium);
  //}
  //
  //:host([size='large']) {
  //  font-size: var(--webmodule-control-font-size-large);
  //}

  .tab {
    display: inline-flex;
    align-items: center;
    font-family: var(--webmodule-font-sans);
    font-size: var(--webmodule-font-size-small);
    font-weight: var(--webmodule-font-weight-semibold);
    border-radius: var(--webmodule-border-radius-medium);
    color: var(--webmodule-color-neutral-600);
    padding: var(--webmodule-spacing-medium) var(--webmodule-spacing-large);
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition:
      var(--webmodule-animate-fast) box-shadow,
      var(--webmodule-animate-fast) color;
  }

  .tab:hover:not(.tab--disabled) {
    color: var(--webmodule-color-primary-600);
  }

  :host(:focus) {
    outline: transparent;
  }

  :host(:focus-visible):not([disabled]) {
    color: var(--webmodule-color-primary-600);
  }

  :host(:focus-visible) {
    outline: var(--webmodule-focus-border);
    outline-offset: calc(-1 * var(--webmodule-focus-border-width) - var(--webmodule-focus-border-offset));
  }

  .tab.tab--active:not(.tab--disabled) {
    color: var(--webmodule-color-primary-600);
  }

  .tab.tab--closable {
    padding-inline-end: var(--webmodule-spacing-small);
  }

  .tab.tab--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .tab__close-button {
    font-size: var(--webmodule-font-size-small);
    margin-inline-start: var(--webmodule-spacing-small);
  }

  .tab__close-button::part(base) {
    padding: var(--webmodule-spacing-3x-small);
  }

  @media (forced-colors: active) {
    .tab.tab--active:not(.tab--disabled) {
      outline: solid 1px transparent;
      outline-offset: -3px;
    }
  }
`;
