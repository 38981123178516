import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ViewSupportInboxItemEx } from '../../api/supplier-api-interface-franchiseenetwork';

export const getQuoteNumberDisplay = (quoteNumber?: number, quoteSuffix?: string): string => {
  if (!quoteNumber) return '';

  const suffix = !quoteSuffix || isEmptyOrSpace(quoteSuffix) ? '    ' : `-${quoteSuffix.padStart(3, ' ')}`;

  return quoteNumber == 0 ? '' : `${quoteNumber.toString().padStart(6, '0')}${suffix}`;
};

export const getQuoteNumberSuffix = (q: ViewSupportInboxItemEx | null) => {
  return getQuoteNumberDisplay(q?.quoteNumber, q?.quoteSuffix);
};
