interface TechDict {
  [key: string]: DictionaryEntry;
}

export function technicalDictionary(): TechDict {
  if (!globalThis.technicalDictionary) globalThis.technicalDictionary = {};

  return globalThis.technicalDictionary as TechDict;
}

export type DictionaryEntryValue = () => string;
export interface DictionaryEntry {
  single: DictionaryEntryValue;
  plural: DictionaryEntryValue;
}
