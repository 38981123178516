import { type CSSResultGroup, nothing } from 'lit';
import { html } from 'lit';
import { state } from 'lit/decorators.js';
import {
  type WebModuleLitTable,
  type WebModuleLitTableColumnDef,
  type WebModuleTableItemMove
} from '../table/lit-table.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './testcomp.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';

interface Quote {
  Id: number;
  Title: string;
  Options: string[];
  Price: number;
}

export default class WebmoduleTestcomp extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @state()
  _quotes: Quote[] = [
    { Id: 1, Title: 'Basic Package', Options: ['Option1', 'Option2'], Price: 100.0 },
    { Id: 2, Title: 'Standard Package', Options: ['Option3', 'Option4', 'Option5'], Price: 200.0 },
    { Id: 3, Title: 'Premium Package', Options: ['Option6', 'Option7', 'Option8', 'Option9'], Price: 300.0 },
    { Id: 4, Title: 'Enterprise Package', Options: ['Option10', 'Option11', 'Option12', 'Option13'], Price: 500.0 },
    { Id: 5, Title: 'Economy Package', Options: ['Option14', 'Option15'], Price: 80.0 },
    { Id: 6, Title: 'Deluxe Package', Options: ['Option16', 'Option17', 'Option18'], Price: 350.0 },
    { Id: 7, Title: 'Startup Package', Options: ['Option19', 'Option20', 'Option21'], Price: 150.0 },
    { Id: 8, Title: 'Business Package', Options: ['Option22', 'Option23'], Price: 250.0 },
    { Id: 9, Title: 'Pro Package', Options: ['Option24', 'Option25', 'Option26'], Price: 450.0 }
  ];

  @state()
  private _columns: WebModuleLitTableColumnDef[] = [
    {
      title: 'Item #',
      classes: 'colpx-64',
      fieldName: 'Id',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as Quote;
        return rowItem.Id.toString();
      }
    },
    {
      title: 'Title',
      classes: 'colpx-64',
      fieldName: 'Title',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as Quote;
        return rowItem.Title;
      }
    },
    {
      title: 'Price',
      classes: 'colpx-64',
      fieldName: 'Price',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as Quote;
        return rowItem.Price.toString();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      click: (_e, _table, _item, _fieldName) => {
        alert('price click');
        return true;
      },
      columnAggregate: () => {
        const total = this._quotes.reduce((sum, quote) => sum + quote.Price, 0);
        return html`${total}`;
      }
    }
  ];

  @state()
  private readonlyTable: boolean = true;

  render() {
    const keyEvent = (item: Quote) => {
      return item.Id;
    };

    const enableDragEvent = (item: Quote): boolean => {
      return !(item && (item.Id === 8 || item.Id === 9));
    };

    return html` <webmodule-lit-table
      id="quote-items-table"
      class="quote-items-table"
      .rowClass=${'tr'}
      .colClass=${'column'}
      .keyevent=${keyEvent}
      .checkEnabledDragEvent=${enableDragEvent}
      .tablestyle="nestedtable"
      .columns=${this._columns}
      .pageLength="100"
      .data=${this._quotes}
      .clickrows=${false}
      @webmodule-table-item-move=${this._handleMove}
      enableDrag=${this.readonlyTable ? 'true' : nothing}
    >
    </webmodule-lit-table>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  private async _handleMove(e: CustomEvent<WebModuleTableItemMove>) {
    this._quotes = this.moveArrayItem([...this._quotes], e.detail);
  }

  private moveArrayItem<T>(array: T[], moveData: WebModuleTableItemMove): T[] {
    const { sourceIndex, targetIndex } = moveData;

    // Ensure the indices are within the bounds of the array
    if (
      sourceIndex < 0 ||
      sourceIndex >= array.length ||
      targetIndex < 0 ||
      targetIndex >= array.length ||
      sourceIndex === targetIndex
    ) {
      throw new Error('Invalid indices provided');
    }

    // Remove the item from the original position
    const item = array.splice(sourceIndex, 1)[0];

    // Insert the item at the new position
    array.splice(targetIndex, 0, item);

    return array;
  }
}
