import { claimIdentity } from '../../../../webmodule-common/other/api/user-security';
import { DashboardFilterOption } from '../widget/dashboard-filter';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { sameText } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { userDataStore } from '../../common/current-user-data-store';
import { ViewUserLoginInformation } from '../../../api/supplier-api-interface-franchiseenetwork';

class DashboardFilterDataCache {
  private _franchisees: DashboardFilterOption[] = [];
  private _franchiseeBranches = new Map<string, DashboardFilterOption[]>();
  private _branchUsers = new Map<string, DashboardFilterOption[]>();

  public async prefetch() {
    await userDataStore.loadCoreDetails();
    const cacheData = userDataStore.allFranchiseeDetails;
    const deployments = cacheData.tenantList.deployments ?? [];
    this._franchisees = [];
    for (const deployment of deployments) {
      for (const tenantInfo of deployment.tenants ?? []) {
        const tenant = cacheData?.tenantList.tenants?.find(x => x.id === tenantInfo.tenantId);

        if (tenant) {
          const franchiseeDeploymentConfiguration = cacheData?.franchisees.find(
            x => x.franchiseeTenantId == tenantInfo.tenantId && x.dealerDeploymentId == deployment.dealerDeploymentId
          );

          if (franchiseeDeploymentConfiguration) {
            this._franchisees.push({
              id: franchiseeDeploymentConfiguration.id,
              name: tenant.companyName ?? ''
            });

            const franchiseeDeploymentBranches = cacheData?.branches.filter(
              x =>
                x.dealerDeploymentId == franchiseeDeploymentConfiguration?.dealerDeploymentId &&
                x.franchiseeTenantId == franchiseeDeploymentConfiguration?.franchiseeTenantId
            );

            if (franchiseeDeploymentBranches) {
              const tenantUserLoginInformation =
                deployment.userLoginInformation?.filter(x => x.tenantId === tenantInfo.tenantId) ?? [];

              // eslint-disable-next-line no-inner-declarations
              function isInvalidBranch(id: string): boolean {
                return franchiseeDeploymentBranches.find(c => c.branchId === id) === undefined;
              }

              const branches: DashboardFilterOption[] = [];
              franchiseeDeploymentBranches.forEach((deploymentBranch, index) => {
                branches.push({
                  id: deploymentBranch.branchId,
                  name: deploymentBranch.name
                });

                function isSameBranch(claims: { [key: string]: string } | null) {
                  const branchId = claims?.[claimIdentity.branchId] ?? '';
                  const isMainBranch = index === 0;
                  return (
                    sameText(branchId, deploymentBranch.branchId) ||
                    claims?.[claimIdentity.allBranches] === 'true' ||
                    (isInvalidBranch(branchId) && isMainBranch)
                  );
                }

                const loginUsers = tenantUserLoginInformation.filter(x => isSameBranch(x.claims));

                this._branchUsers.set(deploymentBranch.branchId, this.getBranchUserData(loginUsers));
              });
              this._franchiseeBranches.set(franchiseeDeploymentConfiguration.id, branches);
            }
          }
        }
      }
    }
  }

  public getFranchisees(): DashboardFilterOption[] {
    return this._franchisees;
  }

  public getBranchesByFranchiseeId(franchiseeId?: string): DashboardFilterOption[] | undefined {
    if (franchiseeId && franchiseeId != emptyGuid) {
      const branches = this._franchiseeBranches.get(franchiseeId);
      if (branches) {
        return branches;
      }
    }
    return undefined;
  }

  public getBranchUsers(branchId?: string): DashboardFilterOption[] | undefined {
    if (branchId && branchId != emptyGuid) {
      const users = this._branchUsers.get(branchId);
      if (users) {
        return users;
      }
    }
    return undefined;
  }

  private getBranchUserData(loginUsers: ViewUserLoginInformation[]): DashboardFilterOption[] {
    const branchUsers: DashboardFilterOption[] = [];
    for (const loginUser of loginUsers) {
      if (loginUser.active) {
        branchUsers.push({
          id: loginUser.systemUserId,
          name: loginUser.friendlyName ?? ''
        });
      }
    }
    return branchUsers;
  }
}

let _db: DashboardFilterDataCache | null = null;

export function flushDashboardFilterDataCache() {
  _db = null;
}

export function dashboardFilterDataCache(): DashboardFilterDataCache {
  if (!_db) {
    _db = new DashboardFilterDataCache();
  }
  return _db;
}
