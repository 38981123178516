import { IconChecked } from './icon-checked';
import { IconCopy } from './icon-copy';
import { IconDelete } from './icon-delete';
import { IconNotificationSignal } from './icon-notification-signal';
import { IconPlus } from './icon-plus';
import { IconRefresh } from './icon-refresh';
import { IconSave } from './icon-save';
import { IconStale } from './icon-stale';

export const iconRegistry = {
  checked: IconChecked,
  delete: IconDelete,
  plus: IconPlus,
  iconRefresh: IconRefresh,
  iconSave: IconSave,
  iconCopy: IconCopy,
  IconNotificationSignal: IconNotificationSignal,
  IconStale: IconStale
};
