import { EventNotify } from '../interop/webmodule-interop';

export function removeFromArray<T>(item: T, array: T[]): boolean {
  const index = array.indexOf(item);
  if (index >= 0) {
    array.splice(index, 1);
    return true;
  }
  return false;
}

export async function runEventNotify(events: EventNotify[]) {
  for (let i = 0; i <= events.length; i++) {
    const event = events[i];
    await event?.();
  }
}

export function toggleItemInArray<T>(array: T[], item: T, add: boolean) {
  if (add) {
    if (!array.includes(item)) {
      array.push(item);
    }
  } else {
    removeFromArray(item, array);
  }
}
