import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';

export abstract class ApiBase {
  apiPath: string;
  api: ApiCommunications;

  /**
   * Initializes a new instance of the API.
   * @param api Api communications module.
   * @param apiPath Path to the api endpoints.
   */
  constructor(api: ApiCommunications, apiPath: string) {
    this.api = api;
    this.apiPath = apiPath;
  }

  /**
   * Posts the given requests to the specified endpoint.
   * @param endpoint Endpoint the request will be sent to.
   * @param request The request object.
   * @param apiPath Optional api path if it lives at a different location.
   * @returns The result of the request.
   */
  async post<T>(endpoint: string, request: any, apiPath: string | undefined = undefined): NullPromise<T> {
    return await this.api.post<T>(`${apiPath ?? this.apiPath}${endpoint}`, request);
  }
}
