import { animateTo, getAnimation, setDefaultAnimation, stopAnimations } from '../../common/animation-common.js';
import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, query } from 'lit/decorators.js';
import { waitForEvent } from '../../events/events.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './alert.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

const toastStack = Object.assign(document.createElement('div'), { className: 'webmodule-toast-stack' });

setDefaultAnimation('alert.show', {
  keyframes: [
    { opacity: 0, scale: 0.8 },
    { opacity: 1, scale: 1 }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('alert.hide', {
  keyframes: [
    { opacity: 1, scale: 1 },
    { opacity: 0, scale: 0.8 }
  ],
  options: { duration: 250, easing: 'ease' }
});

/**
 * @summary Component to notify the user of some state/status
 *
 * @slot - The alert's main content.
 * @slot icon - An icon to show in the alert. Works best with `<webmodule-icon>`.
 *
 * @event webmodule-show - Emitted when the alert opens.
 * @event webmodule-after-show - Emitted after the alert opens and all animations are complete.
 * @event webmodule-hide - Emitted when the alert closes.
 * @event webmodule-after-hide - Emitted after the alert closes and all animations are complete.
 *
 * @csspart base - The component's base wrapper.
 * @csspart icon - The container that wraps the optional icon.
 * @csspart message - The container that wraps the alert's main content.
 * @csspart close-button - The close button, an `<webmodule-icon-button>`.
 * @csspart close-button__base - The close button's exported `base` part.
 *
 * @animation alert.show - The animation to use when showing the alert.
 * @animation alert.hide - The animation to use when hiding the alert.
 *
 * @tag webmodule-alert
 */
export default class WebmoduleAlert extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @query('[part~="base"]') base: HTMLElement;
  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Boolean, reflect: true }) closable = false;
  @property({ reflect: true }) variant: 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'primary';
  @property({ type: Number }) duration = Infinity;

  /** Draws a smaller alert. */
  @property({ type: Boolean, reflect: true })
  snack = false;
  private autoHideTimeout: number;
  private readonly hasSlotController = new HasSlotController(this, 'icon', 'suffix');

  firstUpdated() {
    this.base.hidden = !this.open;
  }

  @monitor('open', { delayMonitorUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      // Show
      this.emit('webmodule-show');

      if (this.duration < Infinity) {
        this.restartAutoHide();
      }

      await stopAnimations(this.base);
      this.base.hidden = false;
      const { keyframes, options } = getAnimation(this, 'alert.show');
      await animateTo(this.base, keyframes, options);

      this.emit('webmodule-after-show');
    } else {
      // Hide
      this.emit('webmodule-hide');

      clearTimeout(this.autoHideTimeout);

      await stopAnimations(this.base);
      const { keyframes, options } = getAnimation(this, 'alert.hide');
      await animateTo(this.base, keyframes, options);
      this.base.hidden = true;

      this.emit('webmodule-after-hide');
    }
  }

  @monitor('duration')
  handleDurationChange() {
    this.restartAutoHide();
  }

  async show() {
    if (this.open) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'webmodule-after-show');
  }

  async hide() {
    if (!this.open) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'webmodule-after-hide');
  }

  async toast() {
    return new Promise<void>(resolve => {
      if (toastStack.parentElement === null) {
        document.body.append(toastStack);
      }

      toastStack.appendChild(this);

      // Wait for the toast stack to render
      requestAnimationFrame(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- force a reflow for the initial transition
        this.clientWidth;
        this.show();
      });

      this.addEventListener(
        'webmodule-after-hide',
        () => {
          toastStack.removeChild(this);
          resolve();

          // Remove the toast stack from the DOM when there are no more alerts
          if (toastStack.querySelector('webmodule-alert') === null) {
            toastStack.remove();
          }
        },
        { once: true }
      );
    });
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          alert: true,
          'alert--open': this.open,
          'alert--closable': this.closable,
          'alert--has-icon': this.hasSlotController.checkFor('icon'),
          'alert--primary': this.variant === 'primary',
          'alert--success': this.variant === 'success',
          'alert--neutral': this.variant === 'neutral',
          'alert--warning': this.variant === 'warning',
          'alert--danger': this.variant === 'danger',
          'alert--snack': this.snack
        })}
        role="alert"
        aria-hidden=${this.open ? 'false' : 'true'}
        @mousemove=${this.handleMouseMove}
      >
        <div part="icon" class="alert__icon">
          <slot name="icon"></slot>
        </div>

        <div part="message" class="alert__message" aria-live="polite">
          <slot></slot>
        </div>

        ${this.closable
          ? html`
              <webmodule-icon-button
                part="close-button"
                exportparts="base:close-button__base"
                class="alert__close-button"
                name="x-lg"
                library="default"
                label="Close"
                @click=${this.handleCloseClick}
              ></webmodule-icon-button>
            `
          : ''}
      </div>
    `;
  }

  private restartAutoHide() {
    clearTimeout(this.autoHideTimeout);
    if (this.open && this.duration < Infinity) {
      this.autoHideTimeout = window.setTimeout(() => this.hide(), this.duration);
    }
  }

  private handleCloseClick() {
    this.hide();
  }

  private handleMouseMove() {
    this.restartAutoHide();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-alert': WebmoduleAlert;
  }
}
