import type { ReactiveController, ReactiveControllerHost } from 'lit';
import type WebModuleElement from './webmodule-element.js';

const connectedElements = new Set<HTMLElement>();
const documentElementObserver = new MutationObserver(update);
let documentLanguage = document.documentElement.lang || navigator.language;

documentElementObserver.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['lang']
});

export function update() {
  documentLanguage = document.documentElement.lang || navigator.language;

  [...connectedElements.keys()].forEach((el: WebModuleElement) => {
    if (typeof el.requestUpdate === 'function') {
      el.requestUpdate();
    }
  });
}

export class LocalizeController implements ReactiveController {
  host: ReactiveControllerHost & HTMLElement;

  constructor(host: ReactiveControllerHost & HTMLElement) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected() {
    connectedElements.add(this.host);
  }

  hostDisconnected() {
    connectedElements.delete(this.host);
  }

  lang() {
    return `${this.host.lang || documentLanguage}`.toLowerCase();
  }

  date(dateToFormat: Date | string, options?: Intl.DateTimeFormatOptions): string {
    dateToFormat = new Date(dateToFormat);
    return new Intl.DateTimeFormat(this.lang(), options).format(dateToFormat);
  }

  number(numberToFormat: number | string, options?: Intl.NumberFormatOptions): string {
    numberToFormat = Number(numberToFormat);
    return isNaN(numberToFormat) ? '' : new Intl.NumberFormat(this.lang(), options).format(numberToFormat);
  }

  list(stringList: string | string[], options?: Intl.ListFormatOptions): string {
    const values = Array.isArray(stringList) ? stringList : [stringList];
    return new Intl.ListFormat(this.lang(), options).format(values);
  }

  pluralRule(value: number, options?: Intl.PluralRulesOptions) {
    return new Intl.PluralRules(this.lang(), options).select(value);
  }

  relativeTime(value: number, unit: Intl.RelativeTimeFormatUnit, options?: Intl.RelativeTimeFormatOptions): string {
    return new Intl.RelativeTimeFormat(this.lang(), options).format(value, unit);
  }
}
