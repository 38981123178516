import { setDebugInformation } from '../../webmodule-common/other/debug';

// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __localdebug__: string;
// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __webmodulebuildnumber__: string;
const usedebugv6 = __localdebug__;
const localBuildNumber = __webmodulebuildnumber__;

globalThis.applyDebugInformation = applyDebugInformation;
export function applyDebugInformation() {
  setDebugInformation(localBuildNumber, usedebugv6);
}
