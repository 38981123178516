import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import buttonStyles from '../button/button.styles.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './radio-button.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Select a single option from a group. Renders as a button.
 *
 * @slot - The radio button's label.
 * @slot prefix - A presentational prefix icon or similar element.
 * @slot suffix - A presentational suffix icon or similar element.
 *
 * @event webmodule-blur - Emitted when the button loses focus.
 * @event webmodule-focus - Emitted when the button gains focus.
 *
 * @csspart base - The component's base wrapper.
 * @csspart button - The internal `<button>` element.
 * @csspart button--checked - The internal button element when the radio button is checked.
 * @csspart prefix - The container that wraps the prefix.
 * @csspart label - The container that wraps the radio button's label.
 * @csspart suffix - The container that wraps the suffix.
 *
 * @tag webmodule-radio-button
 */
export default class WebmoduleRadioButton extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, buttonStyles, styles];
  @query('.button') input: HTMLInputElement;
  @query('.hidden-input') hiddenInput: HTMLInputElement;
  /**
   * @internal The radio button's checked state. This is exposed as an "internal" attribute, so we can reflect it, making
   * it easier to style in button groups.
   */
  @property({ type: Boolean, reflect: true }) checked = false;
  /** The radio's value. When selected, the radio group will receive this value. */
  @property() value: string;
  /** Disables the radio button. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /**
   * The radio button's size. When used inside a radio group, the size will be determined by the radio group's size.
   */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Draws a pill-style radio button with rounded edges. */
  @property({ type: Boolean, reflect: true }) pill = false;
  @state() protected hasFocus = false;
  private readonly hasSlotController = new HasSlotController(this, '[default]', 'prefix', 'suffix');

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'presentation');
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  render() {
    return html`
      <div part="base" role="presentation">
        <button
          part="${`button${this.checked ? ' button--checked' : ''}`}"
          role="radio"
          aria-checked="${this.checked}"
          class=${classMap({
            button: true,
            'button--default': true,
            'button--small': this.size === 'small',
            'button--medium': this.size === 'medium',
            'button--large': this.size === 'large',
            'button--checked': this.checked,
            'button--disabled': this.disabled,
            'button--focused': this.hasFocus,
            'button--outline': true,
            'button--pill': this.pill,
            'button--has-label': this.hasSlotController.checkFor('[default]'),
            'button--has-prefix': this.hasSlotController.checkFor('prefix'),
            'button--has-suffix': this.hasSlotController.checkFor('suffix')
          })}
          aria-disabled=${this.disabled}
          type="button"
          value=${ifDefined(this.value)}
          tabindex="${this.checked ? '0' : '-1'}"
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          @click=${this.handleClick}
        >
          <slot name="prefix" part="prefix" class="button__prefix"></slot>
          <slot part="label" class="button__label"></slot>
          <slot name="suffix" part="suffix" class="button__suffix"></slot>
        </button>
      </div>
    `;
  }

  @monitor('disabled', { delayMonitorUntilFirstUpdate: true })
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleClick(e: MouseEvent) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    this.checked = true;
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-radio-button': WebmoduleRadioButton;
  }
}
