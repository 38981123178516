// eslint-disable-next-line import/named
import { createConfirmCancelButtons } from './modal-footer-buttons';
import { EventSnippet, Snippet } from '../../interop/webmodule-interop';
import { html, TemplateResult } from 'lit';
import { init } from '../app-global-init';
import { LitBaseModal } from './modal/modal-factory-lit';
import { tlang } from '../language/lang';

export async function showValidations(items: string[], title?: EventSnippet) {
  if (!title) title = () => tlang`Validation Report`;
  await new ValidationWithDiscardDialog(title, items).showModal();
}

export async function showValidationsWithDiscard(
  items: string[],
  title?: EventSnippet,
  buttons?: ValidationButtons
): Promise<boolean> {
  if (!title) title = () => tlang`Validation Report`;
  if (!buttons) buttons = validationButtons[ValidationButtonType.discardStay];
  const modal = new ValidationWithDiscardDialog(title, items, buttons);
  await modal.showModal();
  return modal.discard;
}

export async function showValidationsWithAbandon(
  items: string[],
  title?: EventSnippet,
  buttons?: ValidationButtons
): Promise<boolean> {
  if (!title) title = () => tlang`Validation Report`;
  if (!buttons) buttons = validationButtons[ValidationButtonType.stayAbandon];
  const modal = new ValidationWithDiscardDialog(title, items, buttons);
  await modal.showModal();
  return modal.discard;
}

export interface ValidationButtons {
  discard: string;
  stay: string;
}

export const validationButtons: ValidationButtons[] = [];

init(() => {
  validationButtons.push({
    discard: tlang`Discard & Close`,
    stay: tlang`Stay on page`
  });
  validationButtons.push({
    discard: tlang`Discard Changes`,
    stay: tlang`Stay on page`
  });
});

export enum ValidationButtonType {
  discardStay = 0,
  stayAbandon = 1
}

export class ValidationWithDiscardDialog extends LitBaseModal {
  eventTitle: EventSnippet;
  issues: string[];
  buttons?: ValidationButtons;
  public discard: boolean = false;

  constructor(title: EventSnippet, issues: string[], buttons?: ValidationButtons) {
    super();
    this.eventTitle = title;
    this.issues = issues;
    this.buttons = buttons;
  }

  protected get modalClasses(): string {
    return 'modal-dialog modal-dialog-scrollable';
  }

  protected get modalSize() {
    return 'modal-xl';
  }

  title(): Snippet {
    return this.eventTitle();
  }

  public bodyTemplate() {
    const warnings = this.issues.map(issue => html` <div class="alert alert-warning" role="alert">${issue}</div>`);
    return html`${warnings}`;
  }

  public footerTemplate(): TemplateResult | null {
    if (!this.buttons) {
      return html` <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
        >Close
      </webmodule-button>`;
    }

    const discardEvent = () => {
      this.discard = true;
      this.hideModal();
    };

    return createConfirmCancelButtons(this.buttons.discard, discardEvent, this.buttons.stay, () => this.hideModal());
  }

  protected ZIndex(): number | undefined {
    return 99993;
  }
}
