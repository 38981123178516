import { ChartSeriesXY } from '../data/chart-data-types';
import { html, TemplateResult } from 'lit';
import { ModalDialog } from '../../../../webmodule-common/other/ui/modal-base';
import { PromiseTemplate } from '../../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../../webmodule-common/other/language/lang';

const ApexCharts = (globalThis as any).ApexCharts;

export class TimelineWidgetModal extends ModalDialog {
  protected _title: string;
  protected seriesData: ChartSeriesXY[] = [];

  chart: any | null = null;

  constructor(title: string, seriesData: ChartSeriesXY[]) {
    super();

    this._title = title;
    this.seriesData = seriesData;
  }

  public getTitle() {
    return this._title;
  }

  override async postRender(): Promise<void> {
    const options = {
      chart: {
        type: 'area',
        height: 400,
        selection: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      series: this.seriesData,
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      }
    };

    this.chart = new ApexCharts(this.ui.querySelector('.timeline-chart'), options);
    this.chart.render();
  }

  async bodyTemplate(): PromiseTemplate {
    return html`<div class="timeline-chart"></div>`;
  }

  protected footerTemplate(): TemplateResult | null {
    return html`<button @click=${() => this.hideModal()} class="btn btn-secondary">${tlang`Close`}</button>`;
  }

  protected get modalSize() {
    return 'modal-xl';
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }
}
