import { customElement } from 'lit/decorators.js';
import { html, LitElement } from 'lit';

@customElement('icon-save')
export class IconSave extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    return html`<i class="fa-solid fa-cloud-arrow-up"></i>`;
  }
}
