import { animateTo, getAnimation, setDefaultAnimation, stopAnimations } from '../../common/animation-common.js';
import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { lockBodyScrolling, unlockBodyScrolling } from '../../common/scroll.js';
import { monitor } from '../../common/monitor.js';
import { property, query } from 'lit/decorators.js';
import { waitForEvent } from '../../events/events.js';
import componentStyles from '../../styles/component.styles.js';
import ModalHelper from '../../common/helpers/modal-helper.js';
import styles from './drawer.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

// Top
setDefaultAnimation('drawer.showtop', {
  keyframes: [
    { opacity: 0, translate: '0 -100%' },
    { opacity: 1, translate: '0 0' }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('drawer.hidetop', {
  keyframes: [
    { opacity: 1, translate: '0 0' },
    { opacity: 0, translate: '0 -100%' }
  ],
  options: { duration: 250, easing: 'ease' }
});

// End
setDefaultAnimation('drawer.showend', {
  keyframes: [
    { opacity: 0, translate: '100%' },
    { opacity: 1, translate: '0' }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('drawer.hideend', {
  keyframes: [
    { opacity: 1, translate: '0' },
    { opacity: 0, translate: '100%' }
  ],
  options: { duration: 250, easing: 'ease' }
});

// Bottom
setDefaultAnimation('drawer.showbottom', {
  keyframes: [
    { opacity: 0, translate: '0 100%' },
    { opacity: 1, translate: '0 0' }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('drawer.hidebottom', {
  keyframes: [
    { opacity: 1, translate: '0 0' },
    { opacity: 0, translate: '0 100%' }
  ],
  options: { duration: 250, easing: 'ease' }
});

// Start
setDefaultAnimation('drawer.showstart', {
  keyframes: [
    { opacity: 0, translate: '-100%' },
    { opacity: 1, translate: '0' }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('drawer.hidestart', {
  keyframes: [
    { opacity: 1, translate: '0' },
    { opacity: 0, translate: '-100%' }
  ],
  options: { duration: 250, easing: 'ease' }
});

// Deny close
setDefaultAnimation('drawer.denyClose', {
  keyframes: [{ scale: 1 }, { scale: 1.01 }, { scale: 1 }],
  options: { duration: 250 }
});

// Overlay
setDefaultAnimation('drawer.overlay.show', {
  keyframes: [{ opacity: 0 }, { opacity: 1 }],
  options: { duration: 250 }
});

setDefaultAnimation('drawer.overlay.hide', {
  keyframes: [{ opacity: 1 }, { opacity: 0 }],
  options: { duration: 250 }
});

/**
 * @summary Drawers slide in from a container.
 *
 * @slot - The drawer's main content.
 * @slot label - The drawer's label. Alternatively, you can use the `label` attribute.
 * @slot header-actions - Optional actions to add to the header. Works best with `<webmodule-icon-button>`.
 * @slot footer - The drawer's footer, usually one or more buttons representing various actions/options.
 *
 * @event webmodule-show - Emitted when the drawer opens.
 * @event webmodule-after-show - Emitted after the drawer opens and all animations are complete.
 * @event webmodule-hide - Emitted when the drawer closes.
 * @event webmodule-after-hide - Emitted after the drawer closes and all animations are complete.
 * @event webmodule-initial-focus - Emitted when the drawer opens and is ready to receive focus. Calling
 *   `event.preventDefault()` will prevent focusing and allow you to set it manually.
 * @event {{ source: 'close-button' | 'keyboard' | 'overlay' }} webmodule-request-close - Emitted when the user attempts to
 *   close the drawer by clicking the close button, clicking the overlay, or pressing escape. Calling
 *   `event.preventDefault()` will keep the drawer open.
 *
 * @csspart base - The component's base wrapper.
 * @csspart overlay - The overlay that covers the screen behind the drawer.
 * @csspart panel - The drawer's panel (where the drawer and its content are rendered).
 * @csspart header - The drawer's header. This element wraps the title and header actions.
 * @csspart header-actions - Optional actions to add to the header. Works best with `<webmodule-icon-button>`.
 * @csspart title - The drawer's title.
 * @csspart close-button - The close button, an `<webmodule-icon-button>`.
 * @csspart close-button__base - The close button's exported `base` part.
 * @csspart body - The drawer's body.
 * @csspart footer - The drawer's footer.
 *
 * @cssproperty --size - The preferred size of the drawer. This will be applied to the drawer's width or height
 *   depending on its `placement`. Note that the drawer will shrink to accommodate smaller screens.
 * @cssproperty --header-spacing - The amount of padding to use for the header.
 * @cssproperty --body-spacing - The amount of padding to use for the body.
 * @cssproperty --footer-spacing - The amount of padding to use for the footer.
 *
 * @animation drawer.showtop - The animation to use when showing a drawer with `top` placement.
 * @animation drawer.showend - The animation to use when showing a drawer with `end` placement.
 * @animation drawer.showbottom - The animation to use when showing a drawer with `bottom` placement.
 * @animation drawer.showstart - The animation to use when showing a drawer with `start` placement.
 * @animation drawer.hidetop - The animation to use when hiding a drawer with `top` placement.
 * @animation drawer.hideend - The animation to use when hiding a drawer with `end` placement.
 * @animation drawer.hidebottom - The animation to use when hiding a drawer with `bottom` placement.
 * @animation drawer.hidestart - The animation to use when hiding a drawer with `start` placement.
 * @animation drawer.denyClose - The animation to use when a request to close the drawer is denied.
 * @animation drawer.overlay.show - The animation to use when showing the drawer's overlay.
 * @animation drawer.overlay.hide - The animation to use when hiding the drawer's overlay.
 *
 * @property modalHelper - Exposes the internal modalHelper utility.
 *
 * @tag webmodule-drawer
 */
export default class WebmoduleDrawer extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  public modalHelper = new ModalHelper(this);
  @query('.drawer') drawer: HTMLElement;
  @query('.drawer__panel') panel: HTMLElement;
  @query('.drawer__overlay') overlay: HTMLElement;
  /**
   * Indicates whether the drawer is open. You can toggle this attribute to show and hide the drawer, or you can
   * use the `show()` and `hide()` methods and this attribute will reflect the drawer's open state.
   */
  @property({ type: Boolean, reflect: true }) open = false;
  /**
   * The drawer's label as displayed in the header. If you need to display HTML, use the `label` slot instead.
   */
  @property({ reflect: true }) label = '';
  /** The direction from which the drawer will open. */
  @property({ reflect: true }) placement: 'top' | 'end' | 'bottom' | 'start' = 'end';
  /**
   * By default, the drawer slides out of its containing block (usually the viewport). To make the drawer slide out of
   * its parent element, set this attribute and add `position: relative` to the parent.
   */
  @property({ type: Boolean, reflect: true }) contained = false;
  /**
   * Removes the header. This will also remove the default close button, so ensure you provide a way for users to dismiss the drawer.
   */
  @property({ attribute: 'no-header', type: Boolean, reflect: true }) noHeader = false;
  private readonly hasSlotController = new HasSlotController(this, 'footer');
  private originalTrigger: HTMLElement | null;
  private closeWatcher: CloseWatcher | null;

  firstUpdated() {
    this.drawer.hidden = !this.open;

    if (this.open) {
      this.addOpenListeners();

      if (!this.contained) {
        this.modalHelper.activate();
        lockBodyScrolling(this);
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    unlockBodyScrolling(this);
    this.closeWatcher?.destroy();
  }

  @monitor('open', { delayMonitorUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      // Show
      this.emit('webmodule-show');
      this.addOpenListeners();
      this.originalTrigger = document.activeElement as HTMLElement;

      // Lock body scrolling only if the drawer isn't contained
      if (!this.contained) {
        this.modalHelper.activate();
        lockBodyScrolling(this);
      }

      const autoFocusTarget = this.querySelector('[autofocus]');
      if (autoFocusTarget) {
        autoFocusTarget.removeAttribute('autofocus');
      }

      await Promise.all([stopAnimations(this.drawer), stopAnimations(this.overlay)]);
      this.drawer.hidden = false;

      // Set initial focus
      requestAnimationFrame(() => {
        const initialFocus = this.emit('webmodule-initial-focus', { cancelable: true });

        if (!initialFocus.defaultPrevented) {
          // Set focus to the autofocus target and restore the attribute
          if (autoFocusTarget) {
            (autoFocusTarget as HTMLInputElement).focus({ preventScroll: true });
          } else {
            this.panel.focus({ preventScroll: true });
          }
        }

        // Restore the autofocus attribute
        if (autoFocusTarget) {
          autoFocusTarget.setAttribute('autofocus', '');
        }
      });

      const panelAnimation = getAnimation(this, `drawer.show${this.placement}`);
      const overlayAnimation = getAnimation(this, 'drawer.overlay.show');
      await Promise.all([
        animateTo(this.panel, panelAnimation.keyframes, panelAnimation.options),
        animateTo(this.overlay, overlayAnimation.keyframes, overlayAnimation.options)
      ]);

      this.emit('webmodule-after-show');
    } else {
      // Hide
      this.emit('webmodule-hide');
      this.removeOpenListeners();

      if (!this.contained) {
        this.modalHelper.deactivate();
        unlockBodyScrolling(this);
      }

      await Promise.all([stopAnimations(this.drawer), stopAnimations(this.overlay)]);
      const panelAnimation = getAnimation(this, `drawer.hide${this.placement}`);
      const overlayAnimation = getAnimation(this, 'drawer.overlay.hide');

      await Promise.all([
        animateTo(this.overlay, overlayAnimation.keyframes, overlayAnimation.options).then(() => {
          this.overlay.hidden = true;
        }),
        animateTo(this.panel, panelAnimation.keyframes, panelAnimation.options).then(() => {
          this.panel.hidden = true;
        })
      ]);

      this.drawer.hidden = true;

      this.overlay.hidden = false;
      this.panel.hidden = false;

      // Restore focus to the original trigger
      const trigger = this.originalTrigger;
      if (typeof trigger?.focus === 'function') {
        setTimeout(() => trigger.focus());
      }

      this.emit('webmodule-after-hide');
    }
  }

  @monitor('contained', { delayMonitorUntilFirstUpdate: true })
  handleNoModalChange() {
    if (this.open && !this.contained) {
      this.modalHelper.activate();
      lockBodyScrolling(this);
    }

    if (this.open && this.contained) {
      this.modalHelper.deactivate();
      unlockBodyScrolling(this);
    }
  }

  async show() {
    if (this.open) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'webmodule-after-show');
  }

  async hide() {
    if (!this.open) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'webmodule-after-hide');
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          drawer: true,
          'drawer--open': this.open,
          'drawer--top': this.placement === 'top',
          'drawer--end': this.placement === 'end',
          'drawer--bottom': this.placement === 'bottom',
          'drawer--start': this.placement === 'start',
          'drawer--contained': this.contained,
          'drawer--fixed': !this.contained,
          'drawer--has-footer': this.hasSlotController.checkFor('footer')
        })}
      >
        <div part="overlay" class="drawer__overlay" @click=${() => this.requestClose('overlay')} tabindex="-1"></div>

        <div
          part="panel"
          class="drawer__panel"
          role="dialog"
          aria-modal="true"
          aria-hidden=${this.open ? 'false' : 'true'}
          aria-label=${ifDefined(this.noHeader ? this.label : undefined)}
          aria-labelledby=${ifDefined(!this.noHeader ? 'title' : undefined)}
          tabindex="0"
        >
          ${!this.noHeader
            ? html`
                <header part="header" class="drawer__header">
                  <h2 part="title" class="drawer__title" id="title">
                    <!-- If there's no label, use an invisible character to prevent the header from collapsing -->
                    <slot name="label"> ${this.label.length > 0 ? this.label : String.fromCharCode(65279)}</slot>
                  </h2>
                  <div part="header-actions" class="drawer__header-actions">
                    <slot name="header-actions"></slot>
                    <webmodule-icon-button
                      part="close-button"
                      exportparts="base:close-button__base"
                      class="drawer__close"
                      name="x-lg"
                      label="Close"
                      library="default"
                      @click=${() => this.requestClose('close-button')}
                    ></webmodule-icon-button>
                  </div>
                </header>
              `
            : ''}

          <slot part="body" class="drawer__body"></slot>

          <footer part="footer" class="drawer__footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    `;
  }

  private requestClose(source: 'close-button' | 'keyboard' | 'overlay') {
    const webmoduleRequestClose = this.emit('webmodule-request-close', {
      cancelable: true,
      detail: { source }
    });

    if (webmoduleRequestClose.defaultPrevented) {
      const animation = getAnimation(this, 'drawer.denyClose');
      animateTo(this.panel, animation.keyframes, animation.options);
      return;
    }

    this.hide();
  }

  private addOpenListeners() {
    if ('CloseWatcher' in window) {
      this.closeWatcher?.destroy();
      if (!this.contained) {
        this.closeWatcher = new CloseWatcher();
        this.closeWatcher.onclose = () => this.requestClose('keyboard');
      }
    } else {
      document.addEventListener('keydown', this.handleDocumentKeyDown);
    }
  }

  private removeOpenListeners() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
    this.closeWatcher?.destroy();
  }

  private handleDocumentKeyDown = (event: KeyboardEvent) => {
    // Contained drawers aren't modal and don't response to the escape key
    if (this.contained) {
      return;
    }

    if (event.key === 'Escape' && this.modalHelper.isActive() && this.open) {
      event.stopImmediatePropagation();
      this.requestClose('keyboard');
    }
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-drawer': WebmoduleDrawer;
  }
}
