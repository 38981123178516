import { css } from 'lit';

export default css`
  :host {
    display: block;
  }

  .menu-label {
    display: inline-block;
    font-family: var(--webmodule-font-sans);
    font-size: var(--webmodule-font-size-small);
    font-weight: var(--webmodule-font-weight-semibold);
    line-height: var(--webmodule-line-height-normal);
    letter-spacing: var(--webmodule-letter-spacing-normal);
    color: var(--webmodule-color-neutral-500);
    padding: var(--webmodule-spacing-2x-small) var(--webmodule-spacing-x-large);
    user-select: none;
    -webkit-user-select: none;
  }
`;
