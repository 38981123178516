import { compare } from '../../../../webmodule-common/other/clone';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../../api/api-injector';
import { ResultGetConvertedQuoteTotals } from '../../../api/supplier-api-interface-franchiseenetwork';

export class ConvertedQuoteTotalsCache {
  private _pageKey = this.pageKey('', '-1');
  private _pagePromise: Promise<ResultGetConvertedQuoteTotals | null> | null = null;

  pageKey(
    startDate: string,
    endDate: string,
    branchIds?: string[],
    userId?: string
  ): { startDate: string; endDate: string; branchIds?: string[]; userId?: string } {
    return { startDate, endDate, branchIds, userId };
  }

  async getData(startDate: string, endDate: string, branchIds?: string[], userId?: string) {
    const currentPage = this.pageKey(startDate, endDate, branchIds, userId);
    if (!compare(this._pageKey, currentPage)) {
      this._pagePromise = getApiFactory()
        .dataAggregation()
        .getConvertedQuoteTotals({
          startDate: startDate,
          endDate: endDate,
          branchIds: branchIds ?? [],
          userId: userId ?? emptyGuid
        });
    }
    return await this._pagePromise;
  }
}
