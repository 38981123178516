import { ApiBase } from './api-base';
import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';
import { DataAggregationApi } from './supplier-api-dataaggregation';
import {
  RequestGetConvertedQuoteTotals,
  RequestGetGeneratedPurchaseOrderRevenue,
  RequestGetGeneratedQuoteRevenue,
  RequestGetPurchaseOrderList,
  ResultGetConvertedQuoteTotals,
  ResultGetGeneratedPurchaseOrderRevenue,
  ResultGetGeneratedQuoteRevenue,
  ResultGetPurchaseOrderList
} from './supplier-api-interface-franchiseenetwork';

export class DataAggregationApiImplementation extends ApiBase implements DataAggregationApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    super(api, 'api/DataAggregation/');
    this.api = api;
  }

  async getPurchaseOrderList(request: RequestGetPurchaseOrderList): NullPromise<ResultGetPurchaseOrderList> {
    return await this.post<ResultGetPurchaseOrderList>('GetPurchaseOrderList', request);
  }

  async getConvertedQuoteTotals(request: RequestGetConvertedQuoteTotals): NullPromise<ResultGetConvertedQuoteTotals> {
    return await this.post<ResultGetConvertedQuoteTotals>('GetConvertedQuoteTotals', request);
  }

  async getGeneratedPurchaseOrderRevenue(
    request: RequestGetGeneratedPurchaseOrderRevenue
  ): NullPromise<ResultGetGeneratedPurchaseOrderRevenue> {
    return await this.post<ResultGetGeneratedPurchaseOrderRevenue>('GetGeneratedPurchaseOrderRevenue', request);
  }

  async getGeneratedQuoteRevenue(
    request: RequestGetGeneratedQuoteRevenue
  ): NullPromise<ResultGetGeneratedQuoteRevenue> {
    return await this.post<ResultGetGeneratedQuoteRevenue>('GetGeneratedQuoteRevenue', request);
  }
}
