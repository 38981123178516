import { showError } from './ui/show-error';
import { tlang } from './language/lang';

export class DevelopmentError extends Error {
  constructor(message?: string) {
    const newMsg = tlang`${'ref:development:error'} Unexpected Development Error
${message}
`;
    super(newMsg);
  }
}

export async function showDevelopmentError(message: string | Error) {
  await showError(message instanceof Error ? message : new DevelopmentError(message));
}
