import { claimIdentity, IUserSecurity, userSecurity } from './api/user-security';
import { emptyGuid } from './api/guid';
import { getCurrentUser } from './api/current-user';

/*
export const claimIdentity = {
    true: 'true',
    /// <summary>
    /// claim added for a token that accesses a franchisee dealer db and wants to use a specific branch
    /// </summary>
    branchId: 'franchisee-branchid',
    allBranches: 'franchisee-allbranches',
    branchAdmin: 'franchisee-branchadmin',

    /// <summary>
    /// claim identifies the user as being a login for a supplier nominated by the "supplierId" claim representing
    /// the "id" of a GlobalSupplier in the license server
    /// </summary>
    supplier: 'supplier',
    supplierId: 'supplierId',
    supplierGuid: 'supplierguid',
    frameConfigPowerUser: 'frameconfig-poweruser',
    /// <summary>
    /// represents that this token is created as a PAT on behalf of the tenant
    /// </summary>
    agent: 'agent-token',

    /// <summary>
    /// this claim identifies the user as having admin rights on the license server on behalf of this tenant
    /// they have the ability to manage license allocations and user creation and maintenance
    /// </summary>
    admin: 'admin',
    primarycontact: 'primarycontact',
};
*/
export interface IUserClaims {
  trueClaim: (name: string) => boolean;
  claim: (name: string) => string;
  hasClaim: (name: string) => boolean;
  get isBranchManager(): boolean;
  get isSingleBranchManager(): boolean;
  get isAllBranchManager(): boolean;
  get isBasicUser(): boolean;
  get isCynclyStaff(): boolean;
  get isAdmin(): boolean;
  get isAgent(): boolean;
  get branchId(): string;
  get userId(): string;
}

const claimName_AllBranch = claimIdentity.allBranches;
const claimName_BranchManager = claimIdentity.branchAdmin;

let _claims: IUserClaims | null = null;
export function currentUserClaims(): IUserClaims {
  if (!_claims) _claims = updateCurrentUserClaims();
  return _claims;
}

export function isCynclyStaff(): boolean {
  return currentUserClaims().isCynclyStaff;
}
export function isSupplierAgent(): boolean {
  return currentUserClaims().isAgent;
}
export function supplierHasFullPrivelages(): boolean {
  return true;
  //return; //this.currentUserClaims().hasClaim('dealer-features')
}
export function updateCurrentUserClaims(): IUserClaims {
  _claims = userClaims(getCurrentUser()?.id ?? emptyGuid, userSecurity());
  return _claims;
}

export function userClaims(userId: string, claims: IUserSecurity): IUserClaims {
  return new UserClaims(userId, claims);
}
class UserClaims implements IUserClaims {
  claims: IUserSecurity;
  _userId: string;

  constructor(userId: string, claims: IUserSecurity) {
    this._userId = userId;
    this.claims = claims;
  }
  get userId(): string {
    return this._userId;
  }
  claim(name: string) {
    return this.claims.claim(name);
  }
  hasClaim(name: string) {
    return this.claims.claimExists(name);
  }
  trueClaim(name: string): boolean {
    return this.claims.claimIsTrue(name);
  }
  get isBranchManager(): boolean {
    return this.trueClaim(claimName_BranchManager);
  }
  get isSingleBranchManager(): boolean {
    return !this.trueClaim(claimName_AllBranch) && this.isBranchManager;
  }
  get isAllBranchManager(): boolean {
    return this.trueClaim(claimName_AllBranch) && this.isBranchManager;
  }
  get isBasicUser(): boolean {
    return !this.trueClaim(claimName_BranchManager) && !this.trueClaim(claimIdentity.admin);
  }
  get isAdmin(): boolean {
    return this.trueClaim(claimIdentity.admin);
  }
  get isCynclyStaff(): boolean {
    return this.trueClaim('cynclystaff');
  }
  get isAgent(): boolean {
    return this.trueClaim(claimIdentity.agent);
  }
  get branchId(): string {
    return this.claims.claim(claimIdentity.branchId) ?? '';
  }
}
