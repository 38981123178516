import { ContainerManager } from '../../../webmodule-common/other/container-manager';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { newGuid } from '../../../webmodule-common/other/api/guid';
import { PurchaseOrderRecipient, PurchaseOrderRecipientLibrary } from '../../api/supplier-api-interface-supplier';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface PurchaseOrderRecipientManagerOptions {
  container: PurchaseOrderRecipientLibrary;
}

export class PurchaseOrderRecipientManager extends ContainerManager<PurchaseOrderRecipientLibrary> {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  constructor(options: PurchaseOrderRecipientManagerOptions) {
    super(options.container, () => tlang`%% %%`, tlang`%% %%`);
  }

  public get recipients(): PurchaseOrderRecipient[] {
    if (this.container.recipients) {
      return this.container.recipients;
    }
    throw new DevelopmentError(`purchase-order-manager.ts Attempting to use undefined recipients`);
  }

  public async needsRecipients(): Promise<boolean> {
    await this.settingsManager.needsSettings(true);
    this.container = this.settingsManager.updateInput.purchaseOrderRecipients ?? { recipients: [] };
    this.backup = this.clone(this.container);
    return true;
  }

  protected async internalSave(): Promise<boolean> {
    this.settingsManager.updateInput.purchaseOrderRecipients = this.container;
    if (await this.settingsManager.saveSettings()) {
      this.backup = this.clone(this.settingsManager.updateInput.purchaseOrderRecipients);
      return true;
    }
    return false;
  }

  public createRecipient(recipient: PurchaseOrderRecipient) {
    recipient.id = newGuid();
    this.container.recipients.push(recipient);
  }

  public editRecipient(recipient: PurchaseOrderRecipient) {
    const index = this.recipients.findIndex(r => r.id == recipient.id);
    if (index >= 0) {
      this.container.recipients[index] = recipient;
    }
  }

  public deleteRecipient(recipient: PurchaseOrderRecipient) {
    const index = this.recipients.findIndex(r => r.id == recipient.id);
    if (index >= 0) {
      this.container.recipients.splice(index, 1);
    }
  }
}
