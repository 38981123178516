import { getApiFactory } from '../api/api-injector';
import { RequestOnBoardTenant } from '../api/supplier-api-interface-franchiseenetwork';
import { userDataStore } from '../supplier/common/current-user-data-store';
import { emptyGuid } from '../../webmodule-common/other/api/guid';

export class OnboardFranchisee {
  initialUserName: string | null = '';
  companyName: string | null = '';
  reference: string | null = '';
  initialName: string | null = '';
  initialPhone: string | null = '';
  initialEmail: string | null = '';
  initialJobTitle: string | null = '';
  licenseAllocation = 0;
}

export interface createFranchiseeOptions {
  franchisee: OnboardFranchisee;
  deploymentId: number;
  licensePoolId: number;
}

export async function onboardFranchisee(options: createFranchiseeOptions): Promise<boolean> {
  // This is a test
  const franchisee = options.franchisee;
  const onboardRequest: RequestOnBoardTenant = {
    companyName: franchisee.companyName,
    deploymentId: options.deploymentId,
    businessPhone: franchisee.initialPhone,
    initialName: franchisee.initialName,
    initialPhone: franchisee.initialPhone,
    initialUserEmail: franchisee.initialEmail,
    initialUserName: franchisee.initialUserName,
    licenseAllocation: franchisee.licenseAllocation,
    licensePoolId: options.licensePoolId,
    reference: franchisee.reference,
    initialJobTitle: franchisee.initialJobTitle
  };
  const api = getApiFactory().franchisee();
  const result = await api.createFranchisee({
    requestOnBoardTenant: onboardRequest,
    defaultPricingRuleId: emptyGuid
  });
  if (result) {
    await userDataStore.reloadFranchiseeDetails();
    return true;
  }
  return false;
}
