// eslint-disable-next-line import/named
import { awaitableTemplate } from './template-processor';
import { createCancelButton, createConfirmCancelButtons } from './modal-footer-buttons';
import { customElement } from 'lit/decorators.js';
import { EventTemplate, EventVoid, EventVoidAsync, ModalPromise, Snippet } from '../../interop/webmodule-interop';
import { getInternalId } from './databinding/databinding';
import { hideModalDialog, showModalDialogEx } from './modal-helper';
import { html, TemplateResult } from 'lit';
import { ViewBase } from './view-base';

//
@customElement('wm-modaldialog')
export class ModalDialog extends ViewBase {
  protected _hiding = false;
  protected _modalSize = 'modal-lg';
  private _showing = false;
  private _modalPromise?: ModalPromise;
  protected _elementId = getInternalId();

  get elementId() {
    return `${this.name()}-${this._elementId}`;
  }

  async afterConstruction(): Promise<void> {
    //override to do something
  }

  public async showModal(): Promise<void> {
    await (
      await this.show()
    ).onClose;
  }

  public async hideModal(): Promise<void> {
    this._hiding = true;
    if (this._showing) {
      this._showing = false;
      await this.onHideModal();
      await hideModalDialog(this.ui, this._modalPromise?.onClose);
    }
  }
  public async show(): Promise<ModalPromise> {
    await this.onShowModal();
    document.body.appendChild(this);
    await this.updateComplete;
    this._showing = true;
    this._hiding = false;
    this._modalPromise = showModalDialogEx(this, this.ZIndex());
    return this._modalPromise;
  }
  borderType() {
    return 'basic';
  }

  /**
   *
   * @returns the name of this class, to be used to form a unique element id for building children in templates
   */
  public name() {
    return 'modal-dialog';
  }

  protected async onHideModal() {
    //override to do stuff
  }

  protected async onShowModal(): Promise<void> {
    //override to do stuff
  }

  protected get modalSize() {
    return this._modalSize;
  }

  protected ZIndex(): number | undefined {
    //Override as needed
    return undefined;
  }

  protected closeButtonText(): string {
    return '';
  }

  protected closeButtonCss(): string {
    return 'btn-close';
  }

  protected renderCloseButtonTemplate(): boolean {
    return false;
  }

  protected closeButtonTemplate(): TemplateResult {
    const eventClose = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.closeIfAllowed();
    };

    return html` <webmodule-button
      size="small"
      variant="default"
      class=${this.closeButtonCss()}
      @click=${eventClose}
      aria-label="Close"
    >
      ${this.closeButtonText()}
    </webmodule-button>`;
  }

  async canClose(): Promise<boolean> {
    return true;
  }

  protected async closeIfAllowed(): Promise<boolean> {
    if (await this.canClose()) {
      await this.hideModal();
      return true;
    }
    return false;
  }

  protected renderFooterTemplate(): boolean {
    return this.footerTemplate() != null;
  }

  protected get modalClasses(): string {
    return 'modal-dialog';
  }

  protected template(): TemplateResult {
    const injectBody = awaitableTemplate(this.bodyTemplate());
    const modalContentClasses = `modal-content shadow-lg border border-${this.borderType()}`;
    const modalClasses = `${this.modalClasses} ${this.modalSize}`;
    const closeButtonToBeRendered = !this.renderCloseButtonTemplate() ? html`` : this.closeButtonTemplate();
    const footerToBeRendered = !this.renderFooterTemplate()
      ? html``
      : html` <div class="modal-footer">${this.footerTemplate()}</div>`;

    return html` <div class="modal " data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class=${modalClasses}>
        <div class=${modalContentClasses}>
          <div class="modal-header">
            <h2 class="modal-title">${this.getTitle()}</h2>
            ${closeButtonToBeRendered}
          </div>
          <div class="modal-body"><div class="modal-body-content">${injectBody}</div></div>
          ${footerToBeRendered}
        </div>
      </div>
    </div>`;
  }

  protected footerTemplate(): TemplateResult | null {
    return null;
  }

  protected bodyTemplate(): EventTemplate {
    return html``;
  }

  protected getTitle(): Snippet {
    return 'Title';
  }

  protected createConfirmCancelButtons(
    confirmText: string | TemplateResult,
    confirmEvent: EventVoid | EventVoidAsync,
    cancelText: string | TemplateResult,
    cancelEvent?: EventVoid | EventVoidAsync
  ): TemplateResult {
    const defaultCancelEvent = async () => {
      await this.hideModal();
    };

    return createConfirmCancelButtons(confirmText, confirmEvent, cancelText, cancelEvent ?? defaultCancelEvent);
  }

  protected createCancelButton(cancelText?: string, cancelEvent?: EventVoid | EventVoidAsync): TemplateResult {
    const defaultCancelEvent = async () => {
      await this.hideModal();
    };

    return createCancelButton(cancelEvent ?? defaultCancelEvent, cancelText);
  }
}
