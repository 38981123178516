import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './tag.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @slot - The tag's content.
 *
 * @event webmodule-remove - Emitted when the remove button is clicked.
 *
 * @csspart base - The component's base wrapper.
 * @csspart content - The tag's content.
 * @csspart remove-button - The tag's remove button, an `<webmodule-icon-button>`.
 * @csspart remove-button__base - The remove button's exported `base` part.
 *
 * @tag webmodule-tag
 */
export default class WebmoduleTag extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @property({ reflect: true }) variant: 'primary' | 'success' | 'neutral' | 'warning' | 'danger' | 'text' = 'neutral';

  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  @property({ type: Boolean, reflect: true }) pill = false;

  @property({ type: Boolean }) removable = false;

  render() {
    return html`
      <span
        part="base"
        class=${classMap({
          tag: true,

          // Types
          'tag--primary': this.variant === 'primary',
          'tag--success': this.variant === 'success',
          'tag--neutral': this.variant === 'neutral',
          'tag--warning': this.variant === 'warning',
          'tag--danger': this.variant === 'danger',
          'tag--text': this.variant === 'text',

          // Sizes
          'tag--small': this.size === 'small',
          'tag--medium': this.size === 'medium',
          'tag--large': this.size === 'large',

          // Modifiers
          'tag--pill': this.pill,
          'tag--removable': this.removable
        })}
      >
        <slot part="content" class="tag__content"></slot>

        ${this.removable
          ? html`
              <webmodule-icon-button
                part="remove-button"
                exportparts="base:remove-button__base"
                name="x-lg"
                library="default"
                label="remove"
                class="tag__remove"
                @click=${this.handleRemoveClick}
                tabindex="-1"
              ></webmodule-icon-button>
            `
          : ''}
      </span>
    `;
  }

  private handleRemoveClick() {
    this.emit('webmodule-remove');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-tag': WebmoduleTag;
  }
}
