import { html, nothing } from 'lit';
import { LocalizeController } from '../../common/localize-controller.js';
import { property } from 'lit/decorators.js';
import WebModuleElement from '../../common/webmodule-element.js';

/**
 * @tag webmodule-format-date
 */
export default class WebmoduleFormatDate extends WebModuleElement {
  @property() lang: string;
  /**
   * The date/time to format. If not set, the current date and time will be used. When passing a string use the ISO 8601 format
   * to ensure timezones are handled correctly.
   */
  @property() date: Date | string = new Date();
  @property() weekday: 'narrow' | 'short' | 'long';
  @property() era: 'narrow' | 'short' | 'long';
  @property() year: 'numeric' | '2-digit';
  @property() month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  @property() day: 'numeric' | '2-digit';
  @property() hour: 'numeric' | '2-digit';
  @property() minute: 'numeric' | '2-digit';
  @property() second: 'numeric' | '2-digit';
  @property({ attribute: 'time-zone-name' }) timeZoneName: 'short' | 'long';
  @property({ attribute: 'time-zone' }) timeZone: string;
  @property({ attribute: 'hour-format' }) hourFormat: 'auto' | '12' | '24' = 'auto';
  private readonly localize = new LocalizeController(this);

  render() {
    const date = new Date(this.date);
    const hour12 = this.hourFormat === 'auto' ? undefined : this.hourFormat === '12';

    if (isNaN(date.getMilliseconds())) {
      return nothing;
    }

    return html`
      <time datetime=${date.toISOString()}>
        ${this.localize.date(date, {
          weekday: this.weekday,
          era: this.era,
          year: this.year,
          month: this.month,
          day: this.day,
          hour: this.hour,
          minute: this.minute,
          second: this.second,
          timeZoneName: this.timeZoneName,
          timeZone: this.timeZone,
          hour12: hour12
        })}
      </time>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-format-date': WebmoduleFormatDate;
  }
}
