function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

/**
 *will not run until it stops being called for the wait period.
 * @param func function to passthrough. should use a bound function with this already set
 * @param wait
 * @returns
 */
export function debounce(func: (...args: any[]) => void, wait: (() => number) | number) {
  let timeout: NodeJS.Timeout | undefined;
  let waitFunc;

  return (...args: any[]) => {
    if (isFunction(wait)) {
      waitFunc = wait as () => number;
    } else {
      waitFunc = function () {
        return wait as number;
      };
    }

    const later = function () {
      timeout = undefined;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}
/**
 * will run immediately and refuse more requests until a set time without new requests
 * @param func
 * @param wait
 * @returns
 */
export function runThenDebounce(func: any, wait: any) {
  let timeout: NodeJS.Timeout | undefined;
  let waitFunc;
  let blockExecution = false;
  return (...args: any[]) => {
    if (isFunction(wait)) {
      waitFunc = wait;
    } else {
      waitFunc = function () {
        return wait;
      };
    }

    const later = function () {
      timeout = undefined;
    };
    if (!blockExecution) func(...args);
    blockExecution = true;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}

/**
 * returns an event that can only run one at a time, and will ignore extra calls during a current exectution
 * will return immediately after running, or immediately on starting async call, but will support blocking normal or async calls.
 * @param func
 * @returns
 */
export function noParalellExecution(func: any) {
  let blockExecution = false;
  return (...args: any[]) => {
    const unblock = true;
    if (!blockExecution)
      try {
        blockExecution = true;
        const possiblePromise = func(...args);
        if (possiblePromise?.then)
          possiblePromise.then(() => {
            blockExecution = false;
          });
      } finally {
        if (unblock) blockExecution = false;
      }
  };
}
/*
    will only allow a single execute at a time, and can be awaited on
*/
export function noParalellExecutionAsync(func: any) {
  let block = false;
  return async (...args: any[]) => {
    if (!block)
      try {
        block = true;
        await func(...args);
      } finally {
        block = false;
      }
  };
}

export function sharedExecute<TResult>(event: (...args: any) => Promise<TResult>): () => Promise<TResult> {
  let promise: Promise<TResult> | undefined = undefined;
  const returnFunc = async (...args: any[]) => {
    if (!promise)
      promise = (async () => {
        try {
          return await event(...args);
        } finally {
          promise = undefined;
        }
      })();
    return await promise;
  };
  return returnFunc;
}
