import { css } from 'lit';

export default css`
  :host {
    display: var(--radio-display);
    //margin-right: var(--element-inline-end-margin);
  }

  :host(:focus-visible) {
    outline: 0px;
  }

  .radio {
    display: inline-flex;
    align-items: top;
    font-family: var(--webmodule-control-font-family);
    font-size: var(--webmodule-control-font-size-medium);
    font-weight: var(--webmodule-control-font-weight);
    color: var(--webmodule-control-label-color);
    vertical-align: middle;
    cursor: pointer;
  }

  .radio--small {
    --toggle-size: var(--webmodule-toggle-size-small);
    font-size: var(--webmodule-control-font-size-small);
    //padding: calc(var(--webmodule-spacing-x-small) + 2px) 0;
  }

  .radio--medium {
    --toggle-size: var(--webmodule-toggle-size-medium);
    font-size: var(--webmodule-control-font-size-medium);
    //padding: calc(var(--webmodule-spacing-small) - 1px) 0;
  }

  .radio--large {
    --toggle-size: var(--webmodule-toggle-size-large);
    font-size: var(--webmodule-control-font-size-large);
    //padding: calc(var(--webmodule-spacing-small) + 2px) 0;
  }

  .radio__checked-icon {
    display: inline-flex;
    width: var(--toggle-size);
    height: var(--toggle-size);
  }

  .radio__control {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--toggle-size);
    height: var(--toggle-size);
    border: solid var(--webmodule-control-border-width) var(--webmodule-control-border-color);
    border-radius: 50%;
    background-color: var(--webmodule-control-background-color);
    color: transparent;
    transition:
      var(--webmodule-animate-fast) border-color,
      var(--webmodule-animate-fast) background-color,
      var(--webmodule-animate-fast) color,
      var(--webmodule-animate-fast) box-shadow;
  }

  .radio__input {
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  /* Hover */
  .radio:not(.radio--checked):not(.radio--disabled) .radio__control:hover {
    border-color: var(--webmodule-control-border-color-hover);
    background-color: var(--webmodule-control-background-color-hover);
  }

  /* Checked */
  .radio--checked .radio__control {
    color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-primary-500);
    background-color: var(--webmodule-color-primary-500);
  }

  /* Checked + hover */
  .radio.radio--checked:not(.radio--disabled) .radio__control:hover {
    border-color: var(--webmodule-color-primary-400);
    background-color: var(--webmodule-color-primary-400);
  }

  /* Checked + focus */
  :host(:focus-visible) .radio__control {
    outline: var(--webmodule-focus-border);
    outline-offset: var(--webmodule-focus-border-offset);
  }

  /* Disabled */
  .radio--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .radio:not(.radio--checked) svg circle {
    opacity: 0;
  }

  .radio__label {
    display: inline-block;
    color: var(--webmodule-control-label-color);
    line-height: var(--toggle-size);
    margin-inline-start: 0.5em;
    user-select: none;
    -webkit-user-select: none;
  }
`;
