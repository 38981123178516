import '../icon/icon.js';
import { html } from 'lit';
import { query } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './breadcrumb.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';
import type WebmoduleBreadcrumbItem from '../breadcrumb-item/breadcrumb-item.js';

/**
 * @summary Breadcrumbs provide links so users can navigate a hierarchy.
 *
 * @dependency webmodule-icon
 *
 * @slot - The default slot for breadcrumb-item.
 * @slot separator - Rendered element to separate breadcrumb-item
 *
 * @csspart base - The component's base wrapper.
 *
 * @tag webmodule-breadcrumb
 */
export default class WebmoduleBreadcrumb extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @query('slot')
  defaultSlot: HTMLSlotElement;

  @query('slot[name="separator"]')
  separatorSlot: HTMLSlotElement;

  private getSeparator() {
    const separator = this.separatorSlot.assignedElements({ flatten: true })[0] as HTMLElement;

    const clone = separator.cloneNode(true) as HTMLElement;
    [clone, ...clone.querySelectorAll('[id]')].forEach(el => el.removeAttribute('id'));
    clone.setAttribute('data-default', '');
    clone.slot = 'separator';

    return clone;
  }

  private slotChangeHandler() {
    const items = [...this.defaultSlot.assignedElements({ flatten: true })].filter(
      item => item.tagName.toLowerCase() === 'webmodule-breadcrumb-item'
    ) as WebmoduleBreadcrumbItem[];

    items.forEach(item => {
      const separator = item.querySelector('[slot="separator"]');
      if (separator === null) {
        // No separator exists, add one
        item.append(this.getSeparator());
      } else if (separator.hasAttribute('data-default')) {
        // A default separator exists, replace it
        separator.replaceWith(this.getSeparator());
      } else {
        // The user provided a custom separator, leave it alone
      }
    });
  }

  render() {
    return html`
      <nav part="base" class="breadcrumb">
        <slot @slotchange=${this.slotChangeHandler}></slot>
      </nav>

      <slot name="separator" hidden>
        <webmodule-icon name="chevron-right"></webmodule-icon>
      </slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-breadcrumb': WebmoduleBreadcrumb;
  }
}
