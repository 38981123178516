import { customElement, property } from 'lit/decorators.js';
import { html, LitElement } from 'lit';

@customElement('icon-checked')
export class IconChecked extends LitElement {
  @property() checked = false;
  render() {
    const clickEvent = (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      this.checked = !this.checked;
    };
    return this.checked
      ? html`<i @click=${clickEvent} class="fa-solid fa-circle-check"></i>`
      : html`<i @click=${clickEvent} class="fa-regular fa-circle-check"></i>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
