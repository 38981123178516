import { createContext } from '@lit/context';
import type { IUserSettings } from './IUserSettings.js';

export class LocalSettings implements IUserSettings {
  private static localInstance: LocalSettings = new LocalSettings(true);
  private static sessionInstance: LocalSettings = new LocalSettings(false);
  private storage: Storage;
  private constructor(isLocal = true) {
    this.storage = isLocal ? localStorage : sessionStorage;
  }

  public static getInstance(isLocal = true): LocalSettings {
    return isLocal ? LocalSettings.localInstance : LocalSettings.sessionInstance;
  }

  public saveSetting<T>(key: string, value: T): void {
    try {
      const valueStr = JSON.stringify(value);
      this.storage.setItem(key, valueStr);
    } catch (error) {
      console.error(`Error saving setting: ${error}`);
    }
  }

  public getSetting<T>(key: string): T | null {
    try {
      const valueStr = this.storage.getItem(key);
      if (valueStr) {
        return JSON.parse(valueStr);
      }
      return null;
    } catch (error) {
      console.error(`Error retrieving setting: ${error}`);
      return null;
    }
  }

  public removeSetting(key: string): void {
    try {
      this.storage.removeItem(key);
      console.log(`Setting removed: ${key}`);
    } catch (error) {
      console.error(`Error removing setting: ${error}`);
    }
  }

  public clearAllSettings(): void {
    try {
      this.storage.clear();
      console.log('All settings cleared');
    } catch (error) {
      console.error(`Error clearing settings: ${error}`);
    }
  }
}

export const userLocalSettings = LocalSettings.getInstance();
export const userSessionSettings = LocalSettings.getInstance(false);

export const localSettingsContext = createContext<IUserSettings>('user-settings');
export const sessionSettingsContext = createContext<IUserSettings>('user-settings-session');
