import type { StringDictionary } from '../types/misc.js';

/**
 * This is a light independant expectation of a "FRAME" in dealer that it can use to process and report on information
 * This provides a decoupled layer between webmodule and v6config or any other future config
 */
export enum FrameValidationType {
  nothing = 0,
  note = 1,
  information = 2,
  warning = 3,
  critical = 4,
  outOfDate = 5
}

export interface FrameValidation {
  type: FrameValidationType;
  title: string;
  visible?: boolean;
  text?: string;
  location?: FramePoint;
  locationGlobal?: FramePoint;
}

export interface FramePoint {
  x: number;
  y: number;
}

export interface FrameViewBox {
  minx: number;
  miny: number;
  width: number;
  height: number;
}

export enum FrameAttributeEditorType {
  Text = 1,
  Dropdown = 2,
  Picker = 3,
  Length = 4,
  Currency = 5,
  Number = 6,
  Boolean = 7,
  Dimension = 8,
  Glazing = 9,
  Macro = 10,
  Quantity = 11,
  CombinationGlazing = 999,
  QuoteOptionProperty = 8000
}

export interface FrameAttribute {
  code: string;
  description: string;
  dataType: FrameAttributeEditorType;
  value: string;
  displayValue?: string;
  hideFromReport?: boolean;
  hideFromUI?: boolean;
}
export enum FrameAttributeGroupType {
  QuoteItem = 0,
  Frame = 1,
  Quote = 2,
  Validations = 3,
  Igu = 4,
  QuoteAttributeItem = 5
}
export interface FrameAttributeGroup {
  groupType: FrameAttributeGroupType;
  description: string;
  hideFromReport?: boolean;
  hideFromUI?: boolean;
  attributes: FrameAttribute[];
}

export interface FrameBuyInItem {
  resourceType: number;
  code: string;
  partCode: string;
  decription: string;
  quantity: number;
  extraDetails: StringDictionary | null;
}

export interface Frame {
  productLine?: string | null;
  description: string;
  boundary: FrameViewBox;
  lightName?: string;
  attributeGroups: FrameAttributeGroup[];
  nestedFrames: Frame[];
  validations?: FrameValidation[];
  buyIn?: FrameBuyInItem[];
}
