import { lang } from './lang.js';
import { technicalDictionary } from './technical-dictionary-data.js';

export interface TechnicalDictionaryDefinition {
  name: string;
  single: string;
  plural: string;
}

function addDict(dictEntry: TechnicalDictionaryDefinition) {
  technicalDictionary()[dictEntry.name] = {
    single: () => lang(dictEntry.single, `ref:dict:${dictEntry.name}:single`),
    plural: () => lang(dictEntry.plural, `ref:dict:${dictEntry.name}:plural`)
  };
  //force registration
  lang(`%%${dictEntry.name}%% !!${dictEntry.name}!!`, undefined, true);
}

export function registerTechnicalDictionary(items: TechnicalDictionaryDefinition[]) {
  const data: TechnicalDictionaryDefinition[] = items;
  data.forEach(x => addDict(x));
}

(function () {
  globalThis.registerTechnicalDictionary = registerTechnicalDictionary;
})();
