import { InputBoolean } from './input-boolean';

let registered = false;

function safeRegister(name: string, constructor: CustomElementConstructor) {
  try {
    customElements.define(name, constructor);
  } catch {
    console.log(`customElement "${name}" already registered`);
  }
}

function prefixName(name: string, prefix?: string): string {
  if (prefix) prefix += '-';
  else prefix = 'softtech-dui-';
  return `${prefix}${name}`;
}

declare global {
  interface HTMLElementTagNameMap {
    'softtech-dui-input-boolean': InputBoolean;
  }
}

export function registerComponentsLocal(prefix?: string) {
  if (!registered) {
    safeRegister(prefixName('input-boolean', prefix), InputBoolean);
    registered = true;
  }
  /* plop:component */
}
