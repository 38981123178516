import { customElement } from 'lit/decorators.js';
import { EventTemplate, LitModalScreen, LitModalScreenEvents, Snippet } from '../../interop/webmodule-interop';
import { ModalDialog } from './modal-base';
import { TemplateResult } from 'lit';
import { isNothing } from '../general/nothing';

export function litModalScreenFactoryImpl(event: LitModalScreenEvents): LitModalScreen {
  return new LitModalScreenImpl(event);
}

@customElement('wm-litmodalscreen')
class LitModalScreenImpl extends ModalDialog implements LitModalScreen {
  events: LitModalScreenEvents;
  constructor(events: LitModalScreenEvents) {
    super();
    this.events = events;
    this.events.owner = this;
  }
  get hiding(): boolean {
    return this._hiding;
  }
  protected ZIndex(): number | undefined {
    return this.events.zIndex;
  }
  private asString(val) {
    if (typeof val == 'function') return val();
    return val;
  }
  protected get modalSize() {
    let size = this.asString(this.events.modalSize);
    if (!size) size = super.modalSize;
    return size;
  }
  protected get modalClasses(): string {
    return this.events.modalClasses ?? super.modalClasses;
  }
  protected getTitle(): Snippet {
    return this.events.title();
  }
  protected async onShowModal(): Promise<void> {
    await this.events.onShowModal?.();
  }
  async canClose(): Promise<boolean> {
    return (await this.events.canClose?.()) ?? super.canClose();
  }
  bodyTemplate(): EventTemplate {
    return this.events.bodyTemplate();
  }
  protected renderFooterTemplate(): boolean {
    return this.events.isFooterVisible ?? !isNothing(this.footerTemplate());
  }
  protected footerTemplate(): TemplateResult | null {
    return this.events.footerTemplate?.() ?? null;
  }
}
