import { DevelopmentError } from '../development-error';
import { Router } from '@vaadin/router';

export interface ResolveURLEntry {
  type: string;
  resolver: (id: string) => string;
}
const _resolveList: ResolveURLEntry[] = [];

export function addURLResolvers(resolvers: ResolveURLEntry[]) {
  _resolveList.push(...resolvers);
}
export function resolveURL(type: string, id: string, pageFragment?: string | null): string {
  const entry = _resolveList.find(item => item.type === type);
  if (!entry) throw new DevelopmentError(`not resolver found for "${type}"`);
  return pageFragment ? `${entry.resolver(id)}#${pageFragment}` : entry.resolver(id);
}

export function goURL(type: string, id: string, pageFragment?: string | null) {
  const url = resolveURL(type, id, pageFragment);
  Router.go(url);
}
export function goStaticURL(url: string) {
  Router.go(url);
}
