// eslint-disable-next-line import/named
import { asMarkdownTemplate } from '../general/markdown';
import { createConfirmCancelButtons } from './modal-footer-buttons';
import { html, TemplateResult } from 'lit';
import { init } from '../app-global-init';
import { LitBaseModal } from './modal/modal-factory-lit';
import { Snippet } from '../../interop/webmodule-interop';
import { tlang } from '../language/lang';

export interface ConfirmButtons {
  ok: string;
  cancel: string;
}

export const confirmationButtons: ConfirmButtons[] = [];

export enum ConfirmationButtonType {
  yesNo = 0,
  okCancel = 1,
  continueCancel = 2,
  saveCancel = 3
}

export function buttonsYesNo() {
  return confirmationButtons[ConfirmationButtonType.yesNo];
}
export function buttonsOkCancel() {
  return confirmationButtons[ConfirmationButtonType.okCancel];
}
export function buttonsContinueCancel() {
  return confirmationButtons[ConfirmationButtonType.continueCancel];
}
export function buttonsSaveCancel() {
  return confirmationButtons[ConfirmationButtonType.saveCancel];
}

export async function AskConfirmation(
  message: string,
  confirmButtons?: ConfirmButtons,
  modalSize?: string,
  modalTitle?: string
): Promise<boolean> {
  if (!confirmButtons) confirmButtons = confirmationButtons[ConfirmationButtonType.continueCancel];
  const modal: ConfirmationDialog = new ConfirmationDialog(message, confirmButtons, modalSize, modalTitle);
  await modal.showModal();
  return modal.result;
}

// noinspection HtmlUnknownAttribute

export class ConfirmationDialog extends LitBaseModal {
  message: string;
  result = false;
  buttons: ConfirmButtons;
  _modalSize = 'modal-md';
  _modalTitle = 'Confirm';

  constructor(message: string, buttons: ConfirmButtons, modalSize?: string, modalTitle?: string) {
    super();
    this.message = message;
    this.buttons = buttons;

    if (modalSize) this._modalSize = modalSize;

    if (modalTitle) this._modalTitle = modalTitle;
  }

  footerTemplate(): TemplateResult {
    const okEvent = () => {
      this.result = true;
      this.hideModal();
    };
    const cancelEvent = () => {
      this.result = false;
      this.hideModal();
    };
    return createConfirmCancelButtons(this.buttons.ok, okEvent, this.buttons.cancel, cancelEvent);
  }

  bodyTemplate(): TemplateResult {
    return html` ${asMarkdownTemplate(this.message)} `;
  }

  title(): Snippet {
    return this._modalTitle;
  }
}

(function () {
  init(() => {
    confirmationButtons.push({
      ok: tlang`Yes`,
      cancel: tlang`No`
    });
    confirmationButtons.push({
      ok: tlang`Ok`,
      cancel: tlang`Cancel`
    });
    confirmationButtons.push({
      ok: tlang`Continue`,
      cancel: tlang`Cancel`
    });
    confirmationButtons.push({
      ok: tlang`Save & Close`,
      cancel: tlang`Cancel`
    });
  });
})();
