import { customElement } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import { tlang } from '../../language/lang';

export function notificationSignal(value: boolean) {
  GlobalNotificationManager.getInstance().setUpdating(value);
}
export function staleIcon(asTemplate = true) {
  return asTemplate
    ? html`<icon-stale .active=${true} class="me-2"></icon-stale>`
    : '<icon-stale active="true" class="me-2"></icon-stale>';
}
export class GlobalNotificationManager {
  private static _instance: { [key: string]: GlobalNotificationManager } = {};
  private listeners: LitElement[] = [];
  private _updatingCount: number = 0;
  public static getInstance(instanceName?: string): GlobalNotificationManager {
    if (GlobalNotificationManager._instance[instanceName ?? 'default'] === undefined)
      GlobalNotificationManager._instance[instanceName ?? 'default'] = new GlobalNotificationManager();
    return this._instance[instanceName ?? 'default'];
  }
  public register(listener: LitElement) {
    this.listeners = [...this.listeners.filter(x => x !== listener), listener];
  }
  public unRegister(listener: LitElement) {
    this.listeners = this.listeners.filter(x => x !== listener);
  }
  public setUpdating(value: boolean) {
    if (value) this._updatingCount++;
    else this._updatingCount--;
    this.listeners.forEach(x => x.requestUpdate());
  }
  private _online: boolean = false;
  public get online(): boolean {
    return this._online;
  }
  public set online(value: boolean) {
    this._online = value;
    this.listeners.forEach(x => x.requestUpdate());
  }
  public get updating(): boolean {
    return this._updatingCount > 0;
  }
}
@customElement('icon-notification-signal')
export class IconNotificationSignal extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected get updating(): boolean {
    return GlobalNotificationManager.getInstance().updating;
  }
  protected get online(): boolean {
    return GlobalNotificationManager.getInstance().online;
  }
  connectedCallback(): void {
    super.connectedCallback();
    GlobalNotificationManager.getInstance().register(this);
  }
  disconnectedCallback(): void {
    GlobalNotificationManager.getInstance().unRegister(this);
    super.disconnectedCallback();
  }
  private _offline = html`<i class="fa-solid fa-network-wired text-danger"></i>`;
  private _online = html`<i class="fa-solid fa-network-wired text-primary"></i>`;
  private _updating = html`<webmodule-animate play name="heartBeat"
    ><i class="fa-solid fa-arrows-rotate text-success"></i
  ></webmodule-animate>`;
  render() {
    const legend = html`<div class="flex-nowrap align-self-start align-items-start">
        <span class="colpx-20 flex-grow-0 me-2">${this._online}</span>
        <span class="flex-shrink-1">${tlang`System Online`}</span>
      </div>
      <div class="flex-nowrap align-self-start align-items-start">
        <span class="colpx-20 flex-grow-0 me-2">${this._offline}</span>
        <span class="flex-shrink-1">${tlang`System Offline. Attempting to reconnect`}</span>
      </div>
      <div class="flex-nowrap align-self-start align-items-start">
        <span class="colpx-20 flex-grow-0 me-2">${this._updating}</span>
        <span class="flex-shrink-1">${tlang`Receiving updates, page will refresh shortly`}</span>
      </div> `;

    let icon;
    if (!this.online) {
      icon = this._offline;
    }
    if (this.online && this.updating) {
      icon = this._updating;
    } else {
      icon = this._online;
    }

    return html` <webmodule-tooltip placement="left" class="table-header-tooltip" hoist>
      <div slot="content">${legend}</div>
      <span>${icon}</span>
    </webmodule-tooltip>`;
  }
}
