import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { SupportInboxItemStatus, SupportInboxItemType } from '../../api/supplier-api-interface-franchiseenetwork';
import { tlang } from '../../../webmodule-common/other/language/lang';

export function getSupportTypeDisplayValue(type: SupportInboxItemType): string {
  switch (type) {
    case SupportInboxItemType.All:
      return tlang`${'ref:SupportInboxItemType.All'}All`;
    case SupportInboxItemType.Basic:
      return tlang`${'ref:SupportInboxItemType.Basic'}Basic`;
    case SupportInboxItemType.QuoteReview:
      return tlang`${'ref:SupportInboxItemType.QuoteReview'}%%quote%% Review`;
    case SupportInboxItemType.EngineeredToOrder:
      return tlang`${'ref:SupportInboxItemType.EngineeredToOrder'}%%special-item-request%%`;
    case SupportInboxItemType.QuoteAssistanceRequest:
      return tlang`${'ref:SupportInboxItemType.QuoteAssistanceRequest'}%%support-request%%`;
    default:
      throw new DevelopmentError(`Unsupported Type ${type}`);
  }
}
export function getSupportTypeStatus(type: SupportInboxItemStatus): string {
  switch (type) {
    case SupportInboxItemStatus.Active:
      return tlang`${'ref:SupportInboxItemStatus.Active'}Active`;
    case SupportInboxItemStatus.Cancelled:
      return tlang`${'ref:SupportInboxItemStatus.Cancelled'}Cancelled`;
    case SupportInboxItemStatus.New:
      return tlang`${'ref:SupportInboxItemStatus.New'}New`;
    case SupportInboxItemStatus.Overdue:
      return tlang`${'ref:SupportInboxItemStatus.Overdue'}Overdue`;
    case SupportInboxItemStatus.All:
      return tlang`${'ref:SupportInboxItemStatus.All'}All`;
    case SupportInboxItemStatus.Resolved:
      return tlang`${'ref:SupportInboxItemStatus.Resolved'}Resolved`;
    case SupportInboxItemStatus.ReActivated:
      return tlang`${'ref:SupportInboxItemStatus.Reactivated'}Reactivated`;
    default:
      throw new DevelopmentError(`Unsupported Type ${type}`);
  }
}

export function getSupportTypeStatusClass(type: SupportInboxItemStatus): string {
  return `badge-inbox-${SupportInboxItemStatus[type].toLowerCase()}`;
}
