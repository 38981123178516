import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventSnippet, PromiseTemplate } from '../../../webmodule-common/interop/webmodule-interop';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, TemplateResult } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PricingRule, PricingRuleType } from '../../api/supplier-api-interface-supplier';
import { showValidationsWithDiscard } from '../../../webmodule-common/other/ui/modal-validationhandler';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface PricingRuleEditModalOptions {
  pricingRule: PricingRule;
  modalTitle: EventSnippet;
  saveButtonTitle: EventSnippet;
  cancelButtonTitle: EventSnippet;
  forceReadonly: boolean;
  pricingRuleTypes: PricingRuleType[];
  getRuleType: (id: string) => PricingRuleType | null;
}

export class PricingRuleEditModal extends ModalViewBase {
  protected readonly options: PricingRuleEditModalOptions;
  protected readonly dataBinding: DataBinding;
  protected readonly dataTracker: DataTracker;

  public ok = false;

  constructor(options: PricingRuleEditModalOptions) {
    super();

    this.options = options;
    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `pricing-rule-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.pricingRule),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('name', FieldType.string, true);
    addField('ruleTypeId', FieldType.string, true);
    addField('minValue', FieldType.float, false);
    addField('maxValue', FieldType.float, true);
  }

  public get readonly(): boolean {
    return this.options.forceReadonly;
  }

  public get pricingRule(): PricingRule {
    return this.options.pricingRule;
  }

  public async afterConstruction(): Promise<void> {
    await this.loadOrRefresh();
  }

  protected async loadOrRefresh() {
    await this.render();
  }

  /**
   * inherited
   * @returns
   */
  getTitle() {
    return this.options.modalTitle() ?? '';
  }
  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-md';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    this.prepareForSave();
    const errors = await this.getValidationErrors();
    if (errors.length != 0) {
      const discardChanges = await showValidationsWithDiscard(errors);
      if (discardChanges) {
        this.dataTracker.resetEditorValue();
        return true;
      }
      return false;
    } else if (isAutoSaving()) this.save();
    return true;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const ruleTypes: PricingRuleType[] = [];
    ruleTypes.push({ id: '', name: '', hasMinMax: false });
    ruleTypes.push(...this.options.pricingRuleTypes);

    const ruleTypeMap = (prt: PricingRuleType) => {
      return {
        text: tlang`${prt.name}`,
        value: prt.id,
        disabled: false
      };
    };

    const valueTemplate = this.options.getRuleType(this.pricingRule.ruleTypeId ?? '')?.hasMinMax
      ? html` ${forms.floatRequired('minValue', tlang`Min Value`, 0, 100)}
        ${forms.floatRequired('maxValue', tlang`Max Value`, 0, 100)}`
      : html` ${forms.textHidden('minValue')} ${forms.floatRequired('maxValue', tlang`Value`, 0, 100)}`;

    return html` <form class="form-one-col">
      <div class="row">
        <div class="form-column">
          <h2>${tlang`Create %%pricing-rule%%`}:</h2>
          ${forms.textRequired('name', tlang`Rule Name`, 100)}
          <bs-form-select
            data-id="${this.dataBinding.field('ruleTypeId')}"
            data-label=${tlang`Rule Type`}
            .value=${this.pricingRule.ruleTypeId}
            .options=${JSON.stringify(ruleTypes.map(ruleTypeMap))}
            @wm-select-changed=${_ => this.onSelectChangedEvent(_.detail)}
          >
          </bs-form-select>
          ${valueTemplate}
        </div>
      </div>
    </form>`;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      this.prepareForSave();
      const errors = await this.getValidationErrors();
      if (errors.length != 0) {
        const discardChanges = await showValidationsWithDiscard(errors);
        if (discardChanges) {
          this.dataTracker.resetEditorValue();
        }
      } else {
        if (await this.save()) await this.hideModal();
      }
    };
    return this.createConfirmCancelButtons(this.options.saveButtonTitle(), okEvent, this.options.cancelButtonTitle());
  }

  protected prepareForSave(): void {
    if (this.readonly) return;

    this.dataTracker.applyChangeToValue();
  }

  protected async save(): Promise<boolean> {
    const result = true;
    this.ok = result;
    return result;
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];

    const ruleName = this.dataBinding.getValue('name');
    const ruleTypeId = this.dataBinding.getValue('ruleTypeId');
    const minValue = this.dataBinding.getFloat('minValue') ?? 0;
    const maxValue = this.dataBinding.getFloat('maxValue') ?? 0;

    if (isEmptyOrSpace(ruleName)) errors.push(tlang`Please provide a name for the %%pricing-rule%%`);
    if (isEmptyOrSpace(ruleTypeId)) errors.push(tlang`Please select a %%pricing-rule%% type`);

    if (minValue < 0) errors.push(tlang`Minimum value cannot be less than 0`);
    if (maxValue < 0) errors.push(tlang`Maximum value cannot be less than 0`);

    if (minValue > 100) errors.push(tlang`Minimum value cannot be greater than 100`);
    if (maxValue > 100) errors.push(tlang`Maximum value cannot be greater than 100`);

    const ruleType = this.options.getRuleType(ruleTypeId);

    if (ruleType && ruleType.hasMinMax) {
      if (minValue >= maxValue)
        errors.push(tlang`Maximum value cannot be less that or equal to Minimum for %%min%%/%%max%% %%pricing-rule%%`);
    }

    return errors;
  }

  protected onSelectChangedEvent(value: string) {
    this.pricingRule.ruleTypeId = value;
    this.render();
  }
}
