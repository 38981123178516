//Provide a global abstraction from the implementation details of an api call

import { BlobApi } from './blob-api';
import { DataAggregationApi } from './supplier-api-dataaggregation';
import { FranchiseeNetworkApi } from './franchisee-network-api';
import { SupplierApi } from './supplier-api';
export * from '../../webmodule-common/other/api/api-injector';

export interface ApiFactory {
  supplier: () => SupplierApi;
  franchisee: () => FranchiseeNetworkApi;
  blob: () => BlobApi;
  dataAggregation: () => DataAggregationApi;
}

let _apiFactory: ApiFactory = {
  supplier: () => {
    throw new Error('please call setApiInjector - supplier api not registered');
  },
  franchisee: function (): FranchiseeNetworkApi {
    throw new Error('please call setApiInjector - dealer api not registered');
  },
  blob: function (): BlobApi {
    throw new Error('please call setApiInjector - blob api not registered');
  },
  dataAggregation: function (): DataAggregationApi {
    throw new Error('please call setApiInjector - DataAggregation api not registered');
  }
};

export function setApiFactory(factory: ApiFactory) {
  _apiFactory = factory;
}

export function getApiFactory() {
  return _apiFactory;
}
