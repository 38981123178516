import { html } from 'lit';
import componentStyles from '../../styles/component.styles.js';
import styles from './menu-label.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @slot - The menu label's content.
 *
 * @csspart base - The component's base wrapper.
 *
 * @tag webmodule-menu-label
 */
export default class WebmoduleMenuLabel extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  render() {
    return html` <slot part="base" class="menu-label"></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-menu-label': WebmoduleMenuLabel;
  }
}
