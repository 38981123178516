import { AutoSaveTag, getAutoSaveNotifier } from '../toast-away';
import { waitPatientlySaving } from './modal-saving';

export const SaveNotifier = {
  auto: 1,
  normal: 2
};
export async function getSaveIndicator(autoSaving?: boolean): Promise<AutoSaveTag> {
  if (autoSaving) return await getAutoSaveNotifier();
  else {
    const waitNotifier = (): AutoSaveTag => {
      const wait = waitPatientlySaving();
      const tag: AutoSaveTag = {
        saving: async () => {
          await wait.showModal();
        },
        completed: async (_completed: boolean) => {
          await wait.hideModal();
        }
      };
      return tag;
    };
    return waitNotifier();
  }
}
