export const setMetaTag = (attributeName: string, attributeValue: string, content: string) => {
  let element = document.head.querySelector(`meta[${attributeName}="${attributeValue}"]`);

  if (!element) {
    element = document.createElement('meta');
    element.setAttribute(attributeName, attributeValue);
    document.head.appendChild(element);
  }

  element.setAttribute('content', content);
};

export const removeMetaTag = (attributeName: string, attributeValue: string) => {
  const element = document.head.querySelector(`meta[${attributeName}="${attributeValue}"]`);

  if (element) {
    document.head.removeChild(element);
  }
};

export const setLinkTag = (rel: string, href: string) => {
  let element = document.head.querySelector(`link[rel="${rel}"]`);

  if (!element) {
    element = document.createElement('link');
    element.setAttribute('rel', rel);
    document.head.appendChild(element);
  }

  element.setAttribute('href', href);
};

export interface MetaOptions {
  title?: string;
  titleTemplate?: string | null;
  description?: string | null;
  url?: string;
}

export const updateMeta = (options: MetaOptions) => {
  const { title, titleTemplate, description, url } = options;

  if (title) {
    document.title = titleTemplate ? titleTemplate.replace('%s', title) : title;
  }

  if (description) {
    setMetaTag('name', 'description', description);
  } else if (description === null) {
    setMetaTag('name', 'description', '');
  }

  if (url) {
    setLinkTag('canonical', url);
  }
};
