import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, query } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './tab.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

let id = 0;

/**
 *
 * @slot - The tab's label.
 *
 * @event webmodule-remove - Emitted when the tab is closable and the close button is activated.
 *
 * @csspart base - The component's base wrapper.
 * @csspart close-button - The close button, an `<webmodule-icon-button>`.
 * @csspart close-button__base - The close button's exported `base` part.
 *
 * @tag webmodule-tab
 */
export default class WebmoduleTab extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @query('.tab') tab: HTMLElement;
  @property({ reflect: true }) panel = '';
  @property({ type: Boolean, reflect: true }) active = false;
  @property({ type: Boolean, reflect: true }) closable = false;
  @property({ type: Boolean, reflect: true }) disabled = false;
  tabIndex = -1;
  private readonly attrId = ++id;
  private readonly componentId = `webmodule-tab-${this.attrId}`;

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'tab');
  }

  @monitor('disabled')
  handleDisabledChange() {
    this.tabIndex = -1;
  }

  render() {
    this.id = this.id.length > 0 ? this.id : this.componentId;

    return html`
      <div
        part="base"
        class=${classMap({
          tab: true,
          'tab--active': this.active,
          'tab--closable': this.closable,
          'tab--disabled': this.disabled
        })}
      >
        <slot></slot>
        ${this.closable
          ? html`
              <webmodule-icon-button
                part="close-button"
                exportparts="base:close-button__base"
                name="x-lg"
                library="default"
                label="Close"
                class="tab__close-button"
                @click=${this.handleCloseClick}
                tabindex="-1"
              ></webmodule-icon-button>
            `
          : ''}
      </div>
    `;
  }

  private handleCloseClick(event: Event) {
    event.stopPropagation();
    this.emit('webmodule-remove');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-tab': WebmoduleTab;
  }
}
