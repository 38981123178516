import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement, state } from 'lit/decorators.js';
import { disposeOf } from '../../../webmodule-common/other/dispose';
import { html } from 'lit';
import { PageBase } from './helpers/page-base';
import { SettingsView } from '../../settings/view/settings-view';
import { SupplierSettingsViewChildFactoryImpl } from '../settings/view/supplier-settings-view-factory';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';

@customElement('app-settings')
export class AppSettings extends PageBase {
  @state()
  settingsView: SettingsView | null = null;

  constructor() {
    super();
  }

  render() {
    if (!this.loggedIn) return html``;
    return html` <div class="page-content">
      <div class="page-container">${this.settingsView?.ui}</div>
    </div>`;
  }

  meta() {
    return {
      title: tlang`%%supplier%%`,
      description: tlang`%%supplier%% settings page`
    };
  }

  async dispose() {
    await super.dispose();
    await disposeOf(this.settingsView);
    this.settingsView = null;
  }

  protected async canLeavePage(): Promise<boolean> {
    if (this.settingsView) return await this.settingsView.canClose();
    else return true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async supplierSettingsInformationFactory(): Promise<SettingsView> {
    return await constructAsync(new SettingsView(new SupplierSettingsViewChildFactoryImpl()));
  }

  protected async afterUserConnected() {
    //we need to flush the user data store any time we come to this page in case it is stale information
    await userDataStore.reLoadGlobalSupplier();
    this.settingsView = await this.supplierSettingsInformationFactory();
    await this.setActiveTabByHash();
  }

  private async setActiveTabByHash() {
    await this.settingsView?.setActiveTabByHash();
  }
}
