import {
  animateTo,
  getAnimation,
  parseDuration,
  setDefaultAnimation,
  stopAnimations
} from '../../common/animation-common.js';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, query } from 'lit/decorators.js';
import { waitForEvent } from '../../events/events.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './tooltip.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';
import type WebmodulePopup from '../popup/popup.js';

setDefaultAnimation('tooltip.show', {
  keyframes: [
    { opacity: 0, scale: 0.8 },
    { opacity: 1, scale: 1 }
  ],
  options: { duration: 150, easing: 'ease' }
});

setDefaultAnimation('tooltip.hide', {
  keyframes: [
    { opacity: 1, scale: 1 },
    { opacity: 0, scale: 0.8 }
  ],
  options: { duration: 150, easing: 'ease' }
});

/**
 * @slot - The tooltip's target element. Avoid slotting in more than one element, as subsequent ones will be ignored.
 * @slot content - The content to render in the tooltip. Alternatively, you can use the `content` attribute.
 *
 * @event webmodule-show - Emitted when the tooltip begins to show.
 * @event webmodule-after-show - Emitted after the tooltip has shown and all animations are complete.
 * @event webmodule-hide - Emitted when the tooltip begins to hide.
 * @event webmodule-after-hide - Emitted after the tooltip has hidden and all animations are complete.
 *
 * @csspart base - The component's base wrapper, an `<webmodule-popup>` element.
 * @csspart base__popup - The popup's exported `popup` part. Use this to target the tooltip's popup container.
 * @csspart base__arrow - The popup's exported `arrow` part. Use this to target the tooltip's arrow.
 * @csspart body - The tooltip's body where its content is rendered.
 *
 * @cssproperty --max-width - The maximum width of the tooltip before its content will wrap.
 * @cssproperty --hide-delay - The amount of time to wait before hiding the tooltip when hovering.
 * @cssproperty --show-delay - The amount of time to wait before showing the tooltip when hovering.
 *
 * @animation tooltip.show - The animation to use when showing the tooltip.
 * @animation tooltip.hide - The animation to use when hiding the tooltip.
 *
 * @tag webmodule-tooltip
 */
export default class WebmoduleTooltip extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @query('slot:not([name])') defaultSlot: HTMLSlotElement;
  @query('.tooltip__body') body: HTMLElement;
  @query('webmodule-popup') popup: WebmodulePopup;
  // Only set this if you do not use html content
  @property() content = '';
  @property() placement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end' = 'top';
  @property({ type: Boolean, reflect: true }) disabled = false;
  @property({ type: Boolean, reflect: true }) open = false;
  // trigger that shows the tooltip. Possible (multiple) options include `click`, `hover`, `focus`, and `manual`.
  @property() trigger = 'hover focus';
  //`overflow: auto|hidden|scroll`. https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed
  @property({ type: Boolean }) hoist = false;
  private hoverTimeout: number;
  private closeWatcher: CloseWatcher | null;
  // popup default values. Will we want to update this in html?
  private distance = 8;
  //Enable this option to prevent the tooltip from being clipped when the component is placed inside a container with
  private skidding = 0;

  constructor() {
    super();
    this.addEventListener('blur', this.handleBlur, true);
    this.addEventListener('focus', this.handleFocus, true);
    this.addEventListener('click', this.handleClick);
    this.addEventListener('mouseover', this.handleMouseOver);
    this.addEventListener('mouseout', this.handleMouseOut);
  }

  disconnectedCallback() {
    // Cleanup this event in case the tooltip is removed while open
    this.closeWatcher?.destroy();
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  }

  firstUpdated() {
    this.body.hidden = !this.open;

    if (this.open) {
      this.popup.active = true;
      this.popup.reposition();
    }
  }

  @monitor('open', { delayMonitorUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      if (this.disabled) {
        return;
      }

      // Show
      this.emit('webmodule-show');
      if ('CloseWatcher' in window) {
        this.closeWatcher?.destroy();
        this.closeWatcher = new CloseWatcher();
        this.closeWatcher.onclose = () => {
          this.hide();
        };
      } else {
        document.addEventListener('keydown', this.handleDocumentKeyDown);
      }

      // stop any active animation that might be running
      await stopAnimations(this.body);
      this.body.hidden = false;
      this.popup.active = true;
      const { keyframes, options } = getAnimation(this, 'tooltip.show');
      await animateTo(this.popup.popup, keyframes, options);
      this.popup.reposition();

      this.emit('webmodule-after-show');
    } else {
      // Hide
      this.emit('webmodule-hide');
      this.closeWatcher?.destroy();
      document.removeEventListener('keydown', this.handleDocumentKeyDown);

      await stopAnimations(this.body);
      const { keyframes, options } = getAnimation(this, 'tooltip.hide');
      await animateTo(this.popup.popup, keyframes, options);
      this.popup.active = false;
      this.body.hidden = true;

      this.emit('webmodule-after-hide');
    }
  }

  @monitor(['content', 'hoist', 'placement'])
  async handleOptionsChange() {
    if (this.hasUpdated) {
      await this.updateComplete;
      this.popup.reposition();
    }
  }

  @monitor('disabled')
  handleDisabledChange() {
    if (this.disabled && this.open) {
      // this.hide();
    }
  }

  // Shows the tooltip.
  async show() {
    if (this.open) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'webmodule-after-show');
  }

  // Hides the tooltip
  async hide() {
    if (!this.open) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'webmodule-after-hide');
  }

  render() {
    return html`
      <webmodule-popup
        part="base"
        exportparts="
            popup:base__popup,
            arrow:base__arrow
          "
        class=${classMap({
          tooltip: true,
          'tooltip--open': this.open
        })}
        placement=${this.placement}
        distance=${this.distance}
        skidding=${this.skidding}
        strategy=${this.hoist ? 'fixed' : 'absolute'}
        flip
        shift
        arrow
        hover-bridge
      >
        <slot slot="anchor"></slot>
        <div part="body" id="tooltip" class="tooltip__body">
          <slot name="content">${this.content}</slot>
        </div>
      </webmodule-popup>
    `;
  }

  private handleBlur = () => {
    if (this.containsTrigger('focus')) {
      this.hide();
    }
  };

  private handleClick = () => {
    if (this.containsTrigger('click')) {
      if (this.open) {
        this.hide();
      } else {
        this.show();
      }
    }
  };

  private handleFocus = () => {
    if (this.containsTrigger('focus')) {
      this.show();
    }
  };

  private handleDocumentKeyDown = (event: KeyboardEvent) => {
    // Pressing escape when a tooltip is open should dismiss it
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.hide();
    }
  };

  private handleMouseOver = () => {
    if (this.containsTrigger('hover')) {
      const delay = parseDuration(getComputedStyle(this).getPropertyValue('--show-delay'));
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = window.setTimeout(() => this.show(), delay);
    }
  };

  private handleMouseOut = () => {
    if (this.containsTrigger('hover')) {
      const delay = parseDuration(getComputedStyle(this).getPropertyValue('--hide-delay'));
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = window.setTimeout(() => this.hide(), delay);
    }
  };

  private containsTrigger(triggerType: string) {
    const triggers = this.trigger.split(' ');
    return triggers.includes(triggerType);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-tooltip': WebmoduleTooltip;
  }
}
