import { EventSnippet } from '../../interop/webmodule-interop';
import { TemplateResult } from 'lit';
import { tlang } from '../language/lang';
import { WaitPatiently } from './modal-spinner';

export function waitPatientlySaving(title?: EventSnippet, message?: EventSnippet, footerTemplate?: TemplateResult) {
  const modal = new WaitPatientlySaving();
  modal.eventTitle = title ?? (() => tlang`Saving`);
  modal.message = message ?? (() => tlang`please wait while we update the data`);
  modal.footer = footerTemplate;
  modal.delayedShow();
  return modal;
}
//a progress window default to run will doing a save to the server

export class WaitPatientlySaving extends WaitPatiently {
  private _footer?: TemplateResult | undefined;
  public get footer(): TemplateResult | undefined {
    return this._footer;
  }
  public set footer(value: TemplateResult | undefined) {
    this._footer = value;
    this.requestUpdate();
  }

  get borderType() {
    return 'basic';
  }

  public footerTemplate(): TemplateResult | null | undefined {
    if (!this.footer) return null;
    return this.footer;
  }
}

export interface ISaveNotifier {
  done: () => Promise<void>;
}

export function saveIndicator(title?: EventSnippet, message?: EventSnippet): ISaveNotifier {
  const saving = waitPatientlySaving(title, message);
  return {
    done: async () => await saving.hideModal()
  };
}
