import { clone, compare } from './clone';
import { EventBoolean, EventNotify, EventSnippet } from '../interop/webmodule-interop';
import { fireQuickSuccessToast } from './toast-away';
import { lang, tlang } from './language/lang';
import { runEventNotify } from './array-helper';
import { showDevelopmentError } from './development-error';

export class ContainerManager<TContainer> {
  public container: TContainer;
  public backup: TContainer;
  protected title: EventSnippet;
  afterSave: EventNotify[] = [];
  beforeSave: EventNotify[] = [];
  protected readonly dictionaryName: string;
  protected forceLocked?: EventBoolean;

  constructor(container: TContainer, title: EventSnippet, dictionaryName: string, forceLocked?: EventBoolean) {
    this.container = container;
    this.backup = clone(this.container);
    this.title = title;
    this.dictionaryName = dictionaryName;
    this.forceLocked = forceLocked;
  }

  public changed(): boolean {
    return !compare(this.container, this.backup);
  }

  public isReadonly(): boolean {
    return this.forceLocked?.() ?? false;
  }

  public async save(silently: boolean): Promise<boolean> {
    if (this.isReadonly()) {
      await showDevelopmentError(`Trying to save readonly ${lang(this.dictionaryName)}`);
      return false;
    }
    if (!this.changed()) return true;
    await this.doBeforeSave();
    if (await this.internalSave()) {
      if (!silently) fireQuickSuccessToast(tlang`${lang(this.dictionaryName)} Saved "${this.title()}"`);

      await this.doAfterSave();
      return true;
    }
    return false;
  }

  protected clone<T>(object: T): T {
    return clone(object);
  }

  /**
   * Override this to customize the save logic.
   * @returns True if the save operations have succeeded, false otherwise.
   */
  protected async internalSave(): Promise<boolean> {
    return false;
  }

  /**
   * execute all bound events after any save operation to allow for re-rendering and refreshing of state
   */
  private async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);

    this.backup = clone(this.container);
  }
  private async doBeforeSave(): Promise<void> {
    await runEventNotify(this.beforeSave);
  }
}
