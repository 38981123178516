import {
  defaultStagedThreshold,
  defaultTestInterval,
  type IEventCancellation,
  lapsedMilliseconds
} from './staged-event.js';
import { getApiToken, getCurrentUser } from '../api/current-user.js';
import { globalState } from '../ui/global-state.js';
import { debounce } from '../common/helpers/callbacks';

export type EventSourceHandler = (data: any, eventName: string) => void;
export interface IStagedExecutionOptions {
  event: () => void | Promise<void>;
  threshold?: number;
  title?: string;
  eventTriggered?: EventSourceHandler;
  eventFinally?: () => void;
  eventActioned?: EventSourceHandler;
  cancelToken?: IEventCancellation;
  testInterval?: number;
}

//staged execution should only be used in cases where recieving a message is
//enough info and the data is not important
export function stagedExecution(options: IStagedExecutionOptions): EventSourceHandler {
  //create a closure that will execute on a timed interval
  const intervalms = options.testInterval ?? defaultTestInterval;
  let timer: NodeJS.Timeout | undefined = undefined;
  let triggered: Date | undefined = undefined;
  let actioned: Date | undefined = undefined;
  let actionCount = 0;
  let eventActionQueue: { data: any; eventName: string }[] = [];
  let executing = false;
  let restart = false;

  const reset = () => {
    clearInterval(timer);
    timer = undefined;
    triggered = undefined;
    actionCount = 0;
    actioned = undefined;
  };
  const intervalEvent = async () => {
    if (options.cancelToken?.cancelled) {
      reset();
      options.cancelToken?.reset();
      options.eventFinally?.();
      return;
    }
    const ms = lapsedMilliseconds(triggered!);
    if (ms >= (options.threshold ?? defaultStagedThreshold)) {
      try {
        executing = true;
        await options.event();
        options.eventFinally?.();
      } finally {
        executing = false;
        reset();
        if (restart) {
          try {
            options.eventTriggered?.(eventActionQueue[0].data, eventActionQueue[0].eventName);
            eventActionQueue
              .filter((_x, index) => index > 0)
              .forEach(x => {
                options.eventActioned?.(x.data, x.eventName);
              });
          } finally {
            eventActionQueue = [];
          }

          startProcess();
        }
      }
      return;
    }
    timer = setTimeout(intervalEvent, intervalms);
  };
  return (data: any, eventName: string) => {
    console.log(`staged ${eventName} with ${data}`);
    if (!triggered) {
      options.eventTriggered?.(data, eventName);
      options.eventActioned?.(data, eventName);
      startProcess();
    } else {
      if (executing) restart = true;
      actionCount++;
      actioned = new Date();
      if (executing) eventActionQueue.push({ data, eventName });
      else options.eventActioned?.(data, eventName);
      console.log(
        `Triggered Event '${options.title ?? 'no name'}' actioned ${actionCount} since ${triggered.toLocaleTimeString()}, at ${actioned.toLocaleTimeString()}`
      );
    }
  };

  function startProcess() {
    executing = false;
    restart = false;
    options.cancelToken?.reset();
    triggered = new Date();
    actionCount++;
    actioned = triggered;

    timer = setTimeout(intervalEvent, intervalms);
    console.log(`Triggered Event '${options.title ?? 'no name'}' started ${triggered.toLocaleTimeString()}`);
  }
}
export interface WMEventSourceClientInitializer {
  /**
   * callback to indicate online offline status
   * @param value state of the instance
   * @returns
   */
  onlineCallback: (value: boolean) => void;
  afterOnlineEvent: (() => Promise<void>) | (() => void);
  url: string;
}

(function () {
  globalState().wmEventSourceClients = globalState().wmEventSourceClients ?? {};
})();

const _all_ = '__all__';
export class WMEventSourceClient {
  private _initializer: WMEventSourceClientInitializer;
  private static _instances(): { [key: string]: WMEventSourceClient } {
    return globalState().wmEventSourceClients as { [key: string]: WMEventSourceClient };
  }
  userId: string = '00000000-0000-0000-0000-000000000000';
  public static getInstance(
    instanceName?: string,
    factoryOptions?: WMEventSourceClientInitializer
  ): WMEventSourceClient {
    if (!WMEventSourceClient._instances()[instanceName ?? 'default']) {
      if (!factoryOptions) throw new Error(`WMEventSourceClient.getInstance requires factoryOptions on first call`);
      WMEventSourceClient._instances()[instanceName ?? 'default'] = new WMEventSourceClient(factoryOptions);
    }
    return WMEventSourceClient._instances()[instanceName ?? 'default'];
  }
  private _source?: EventSource;
  private _events: { [key: string]: EventSourceHandler[] } = {};
  constructor(factoryOptions: WMEventSourceClientInitializer) {
    this._initializer = factoryOptions;
    this.addEventListener('ping', () => this._ping());
  }

  reconnectFrequencySeconds = 1;

  private _reconnect = debounce(
    () => {
      this.connectUser();
      // Double every attempt to avoid overwhelming server
      this.reconnectFrequencySeconds *= 2;
      // Max out at ~1 minute as a compromise between user experience and server load
      if (this.reconnectFrequencySeconds >= 64) {
        this.reconnectFrequencySeconds = 64;
      }
    },
    () => this.reconnectFrequencySeconds * 1000
  );

  public static pushEventToDefault(eventName: string, data: any) {
    WMEventSourceClient.getInstance().pushEvent(eventName, data);
  }
  public pushEvent(eventName: string, data: any) {
    this._events[eventName]?.forEach(event => {
      event(data, eventName);
    });
    this._events[_all_]?.forEach(event => {
      event(data, eventName);
    });
  }
  connectUser() {
    this.disconnect();

    const user = getCurrentUser();
    if (!user) throw new Error('User not connected');
    const _token = getApiToken();
    const token = encodeURIComponent(_token);
    const sourceUrl = `${this._initializer.url}/api/system/sse?&token=${token}`;
    this._source = new EventSource(sourceUrl);
    this._source.onopen = () => {
      this._initializer.onlineCallback(true);
      this.reconnectFrequencySeconds;
      this._initializer.afterOnlineEvent?.();
    };
    this._source.onmessage = event => {
      const data = JSON.parse(event.data);

      const eventName = data.eventName;
      this._events[eventName]?.forEach(event1 => {
        event1(data, eventName);
      });
      this._events[_all_]?.forEach(event1 => {
        event1(data, eventName);
      });
    };
    this._source.onerror = _err => {
      console.log(_err);
      this._initializer.onlineCallback(false);
      this._source?.close();
      this._source = undefined;
      this._reconnect();
    };
  }

  private _ping = () => {
    console.log('SourceEvent Server Ping');
  };
  disconnect() {
    this._source?.close();
    this._source = undefined;
  }
  addEventListener(eventNames: string | string[], event: EventSourceHandler) {
    const events = Array.isArray(eventNames) ? eventNames : [eventNames];

    events.forEach(_eventName => {
      const eventName = _eventName === '' ? _all_ : _eventName;

      let eventArray: EventSourceHandler[] = this._events[eventName];
      if (!eventArray) {
        eventArray = [];
        this._events[eventName] = eventArray;
      }
      if (!eventArray.find(x => x === event)) eventArray.push(event);
    });
  }
  removeEventListener(eventNames: string | string[], event: EventSourceHandler) {
    const events = Array.isArray(eventNames) ? eventNames : [eventNames];
    events.forEach(_eventName => {
      const eventName = _eventName === '' ? _all_ : _eventName;
      const eventArray: EventSourceHandler[] = this._events[eventName];
      this._events[eventName] = eventArray?.filter(binder => !(binder === event));
    });
  }
}
