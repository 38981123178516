import { EventSnippet, ServiceResponseInvalid, ShowErrorEvent } from '../../interop/webmodule-interop';
import { tlang } from '../language/lang';

let _handler: ShowErrorEvent | null = null;

export function setErrorDialogEventHandler(handler: ShowErrorEvent) {
  _handler = handler;
}
/**
 *
 * @param item abstracted show error removed from implementation details
 * @param title
 * @returns
 */
export async function showError(item: ServiceResponseInvalid | Error | string | null, title?: EventSnippet) {
  if (item === null) return;

  if (typeof item === 'string') item = new Error(item);

  if (!title) title = () => tlang`Exception Error`;

  await _handler?.(item, title);
}
