import { autoSaveNotifier } from './context/autoSaveNotifier';
import { disableUI, enableUI } from './ui-lock';
import { EventNotify } from '../interop/types/misc';
import { getInternalId } from './ui/databinding/databinding';
import { html, render, TemplateResult } from 'lit';
import { ServiceResponseInvalid, ServiceResponseType } from '../interop/interfaces/service_response';
import { Toast } from 'bootstrap';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

const readableDelayTime = 5000;

export enum ToastType {
  Error,
  Warning,
  Success,
  Information = 3
}

export interface toastOptions {
  container?: HTMLElement;
  title: string;
  message: string | TemplateResult;
  autoHide?: boolean;
  type: ToastType;
  delay?: number;
}

export function fireQuickSuccessToast(message: string | TemplateResult, delay = 1500) {
  fireToast({
    message: message,
    title: 'Success',
    autoHide: true,
    delay: delay,
    type: ToastType.Success
  });
}

export function fireQuickWarningToast(message: string | TemplateResult, delay = readableDelayTime) {
  fireToast({
    message: message,
    title: 'Information',
    autoHide: true,
    delay: delay,
    type: ToastType.Warning
  });
}

export function fireQuickInformationToast(message: string | TemplateResult, delay = readableDelayTime) {
  fireToast({
    message: message,
    title: 'Information',
    autoHide: true,
    delay: delay,
    type: ToastType.Information
  });
}

export function fireQuickErrorToast(message: string, delay = readableDelayTime) {
  fireToast({
    message: message,
    title: 'Error',
    autoHide: true,
    delay: delay,
    type: ToastType.Error
  });
}

export interface AutoSaveTag {
  saving: EventNotify;
  completed: (success: boolean) => Promise<void>;
}

export async function getAutoSaveNotifier(exitAsSaved?: boolean): Promise<AutoSaveTag> {
  return new Promise(resolve => {
    const result = {
      saving: () => {
        disableUI();
        autoSaveNotifier.triggerAutoSaveBusy();
      },
      completed: async (completed: boolean) => {
        try {
          enableUI();
        } catch {
          //
        }

        if (completed) autoSaveNotifier.triggerAutoSaveSuccess();
        else autoSaveNotifier.triggerAutoSaveError();
      }
    };

    if (exitAsSaved) result.completed(true);
    else result.saving();

    resolve(result);
  });
}

//We can add helper functions to for each type that will set defaults, and we do not have to specify all options.
function fireToast(options: toastOptions) {
  options.container = options.container
    ? options.container
    : ((document.querySelector('.toast-container') as HTMLElement) ?? document.body);
  options.autoHide = options.autoHide ? options.autoHide : false;
  options.delay = options.delay ? options.delay : 1000;

  const toastId = `toast_${getInternalId()}`;
  const theToast: HTMLDivElement = document.createElement('div');

  let classType: string;

  switch (options.type) {
    case ToastType.Error:
      classType = 'bg-danger text-white';
      break;
    case ToastType.Warning:
      classType = 'bg-warning text-black';
      break;
    case ToastType.Success:
      classType = 'bg-success text-white';
      break;
    case ToastType.Information:
      classType = 'bg-info text-white';
      break;
    default:
      classType = 'bg-primary text-white';
      break;
  }
  const msgData = typeof options.message === 'string' ? unsafeHTML(options.message) : options.message;
  //TODO: do we want to allow unsave html in the message?
  render(
    html` <div
      id=${toastId}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      class="toast align-items-center  ${classType} border-0"
      data-bs-delay="${options.delay}"
      data-bs-autohide="${options.autoHide}"
    >
      <div class="toast-header">
        <strong class="me-auto">${options.title}</strong>
        <webmodule-icon-button size="small" name="x-lg" library="default" data-bs-dismiss="toast" aria-label="Close">
          Close
        </webmodule-icon-button>
      </div>
      <div class="toast-body">${msgData}</div>
    </div>`,
    theToast
  );

  const toast = theToast.querySelector('#' + toastId) as HTMLDivElement;
  toast.addEventListener('hidden.bs.toast', () => {
    theToast.remove();
  });
  options.container.appendChild(theToast);
  Toast.getOrCreateInstance(toast).show();
}

export function fireToastError(response: ServiceResponseInvalid | Error | string, container?: HTMLElement) {
  let title = 'Error';
  if (typeof response === 'string') response = new Error(response);

  let message = '';
  if (response instanceof Error) {
    message = `${response.name} ${response.message} ${response.stack}`;
  } else {
    if (response.responseType == ServiceResponseType.UnAuthenticated) {
      title = 'UnAuthenticated';
      message = 'Please contact admin if you are having trouble';
    } else if (response.responseType == ServiceResponseType.UnAuthorized) {
      title = 'UnAuthorized';
      message = 'you do not have the permission for this process';
    } else message = `${response.responseError?.message ?? 'Unknown Error'}`;
  }

  fireToast({
    title: title,
    container: container,
    type: ToastType.Error,
    autoHide: false,
    message: message
  });
}
