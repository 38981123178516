export enum MessageType {
  Information = 0,
  Warning = 1,
  Error = 2
}

export interface Message {
  messageType: MessageType;
  message: string;
}
