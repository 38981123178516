//copyright Kyley Harris (2021) - all rights to reuse and change allowed

import { technicalDictionary } from './technical-dictionary-data.js';

let language = 'eng';
const supportedLang = ['eng', 'us'];
let event: ((language: string) => void) | null = null;
export function getLang() {
  return language;
}
export function isLang(_language: string) {
  return language === _language;
}
export function onLanguageChange(e: (language: string) => void) {
  event = e;
}
export function setLang(name: string) {
  if (supportedLang.filter(x => x === name).length > 0) {
    language = name;
    if (event) event(name);
  } else throw new Error(`${name} is not a supported language yet`);
}

export interface TranslatorMap {
  input?: string;
  output?: string;
}
export interface LanguageFile {
  language: string;
  translations: { [key: string]: TranslatorMap } | null;
}

function langFile(): LanguageFile {
  if (!globalThis.langFile) globalThis.langFile = {};

  return globalThis.langFile as LanguageFile;
}

export function registerLangFile(_langFile: LanguageFile) {
  globalThis.langFile = _langFile;
}

(function () {
  globalThis.registerLangFile = registerLangFile;
})();

export function lang(text: string, namedResource?: string, doNotAddMissing?: boolean) {
  const languageTransform = langFile().translations ?? {};
  //if (language === "eng") return text;
  const finalText = (): string => {
    const newTextObj: TranslatorMap = languageTransform[namedResource ?? text];
    if (!newTextObj) {
      if (!doNotAddMissing) {
        languageTransform[namedResource ?? text] = {};
        if (namedResource) languageTransform[namedResource ?? text].input = text;
      }
      return text;
    } else {
      return newTextObj.output ?? newTextObj.input ?? text;
    }
  };

  let txt = finalText();

  const matches = txt.matchAll(/%%([\w|-]+)%%/g);
  for (const match of matches) {
    const sKey = match[1].toLowerCase();
    const matchFunction = technicalDictionary()[sKey];
    if (!matchFunction) {
      alert(`${match[1]} is not a dictionary function`);
    } else txt = txt.replace(match[0], matchFunction.single());
  }

  const matchesPlural = txt.matchAll(/!!([\w|-]+)!!/g);
  for (const match of matchesPlural) {
    const matchFunction = technicalDictionary()[match[1].toLowerCase()];
    if (!matchFunction) {
      alert(`${match[1]} is not a dictionary function`);
    } else txt = txt.replace(match[0], matchFunction.plural());
  }
  return txt;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tlang(template: TemplateStringsArray, ...args: any[]) {
  let namedResource: string | undefined = undefined;
  if (args.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const arg1 = args[0];
    if (typeof arg1 === 'string') {
      if (arg1.startsWith('ref:')) {
        namedResource = arg1.toLowerCase();
        args.splice(0, 1);
      }
    }
  }
  let result = '';
  let i = 0;
  for (let loop = namedResource ? 1 : 0; loop < template.length; loop++) {
    result += `${template[loop]}` + (i < args.length ? `{${i++}}` : '');
  }
  let transformedResult = lang(result, namedResource);
  i = 0;
  args.forEach((value, index) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    transformedResult = transformedResult.replace(`{${index}}`, value);
  });

  return transformedResult;
}
