// eslint-disable-next-line import/named
import type { ResultTenantLogin, UserPublicInfo } from '../../api/current-user.js';
import { getCurrentUser, setApiToken, setCurrentUser } from '../../api/current-user.js';
import type { TemplateResult } from 'lit';
import { html } from 'lit';
import { jsonRequest } from '../../api/api-request.js';
import { LitBaseModal } from '../modal/modal-factory-lit.js';
import { multiCastPromise } from '../../general/multicast-promise.js';
import { tlang } from '../../language/lang.js';
import type { ApiCommunications } from '../../../interop/interfaces/api-communications';
import type { AuthenticationOptions } from './authenticate-modal.js';

//there can be cases where multiple asynchronous parts are all trying to run at the same time, and
//if we are not authenticated yet, would try to run many modals at the same time
//this promise is used to make all inputs wait on the same promise instance
type NullPromise<T> = Promise<T | null>;
export async function run2FARegisterV1(options: AuthenticationOptions) {
  await new Enable2FADialogLit(options).showModal();
}

let _authenticationPromise: Promise<ResultTenantLogin | null> | null = null;

export async function tryAuthenticateV1(options: AuthenticationOptions): NullPromise<ResultTenantLogin> {
  return multiCastPromise(
    _authenticationPromise,
    p => {
      _authenticationPromise = p;
    },
    async () => {
      const modal: AuthenticateModalLit = new AuthenticateModalLit(options);
      await modal.showModal();
      return modal.result;
    }
  );
}

const signInMode = {
  signIn: 1,
  updatePassword: 2,
  get2FA: 3,
  resetPassword: 4
};

export class AuthenticateModalLit extends LitBaseModal {
  result: ResultTenantLogin | null = null;
  password: string;
  api: ApiCommunications;
  error: string | null;
  loading = false;
  user: UserPublicInfo | null;
  userName: string;
  lockUser: boolean;
  signInMode: number = signInMode.signIn;
  passwordConfirm = '';
  passwordUpdateToken: string;
  needs2FA = false;
  code2FA = '';
  message: string | null;
  options: AuthenticationOptions;

  constructor(options: AuthenticationOptions) {
    super();
    this.error = null;
    this.message = null;
    this.user = options.user;
    this.api = options.api;
    this.password = '';
    this.options = options;

    if (options.user) this.userName = options.user.userName ?? '';
    else this.userName = '';

    this.lockUser = options.user !== null;
  }

  //if the login happens inline as part of an expired token, make sure it comes to the front and
  //other inline progress dialogs do not over lap
  protected ZIndex(): number | undefined {
    //Override as needed
    return 888888;
  }
  protected get modalSize(): string {
    return 'modal-fullscreen-login login-page';
  }

  async cancel() {
    this.result = null;
    await this.hideModal();
    await setCurrentUser(null);
    setApiToken(null);
  }

  inputValue(id: string): string {
    const input = this.ui.querySelector(id);
    if (input) return (input as HTMLInputElement).value;
    return '';
  }
  async resetPassword(): Promise<void> {
    this.error = null;
    this.message = null;

    this.loading = true;
    this.requestUpdate();
    try {
      const inputUserName = this.ui.querySelector('#username');
      if (inputUserName) this.userName = (inputUserName as HTMLInputElement).value ?? '';

      const input = {
        userName: this.userName
      };

      await jsonRequest<ResultTenantLogin>('api/User/GetResetTenantPassword', input, this.options.apiHost);

      this.message = tlang`Your password has been reset. Please check your email`;
    } finally {
      this.signInMode = signInMode.signIn;
      this.loading = false;
      this.requestUpdate();
    }
  }

  async signIn(): Promise<void> {
    this.error = null;
    this.message = null;

    if (this.signInMode === signInMode.get2FA) this.code2FA = this.inputValue('#code2FA');
    else {
      this.code2FA = '';
      this.userName = this.inputValue('#username');
      this.password = this.inputValue('#password');
    }

    this.loading = true;
    this.requestUpdate();
    try {
      if (this.signInMode === signInMode.signIn || this.signInMode === signInMode.get2FA) {
        const input = {
          userName: this.userName,
          password: this.password,
          dealerDeploymentId: this.options.deploymentId,
          code2FA: this.code2FA
        };
        const result = await jsonRequest<ResultTenantLogin>('api/Login/Tenant', input, this.options.licenseServerHost);
        if (result.status === 200 && result.value) {
          if (result.value.authenticationToken === '') {
            if (result.value.passwordChangeRequired) {
              this.passwordUpdateToken = result.value.passwordChangeRequired.token;
              this.password = '';
              this.signInMode = signInMode.updatePassword;
            } else if (result.value.requires2FA) {
              this.signInMode = signInMode.get2FA;
            }
          } else {
            this.result = result.value;
            await this.hideModal();
          }
        } else {
          this.error = `Login unsuccessful: ${result.statusText}`;
        }
      } else {
        this.passwordConfirm = this.inputValue('#password-confirm');
        if (this.password !== this.passwordConfirm) {
          this.error = tlang`Password does not match Confirmation`;
          this.requestUpdate();
        }
        const input = {
          userName: this.userName,
          password: this.password,
          dealerDeploymentId: this.options.deploymentId,
          token: this.passwordUpdateToken
        };
        //password update
        const result = await jsonRequest<ResultTenantLogin>(
          'api/Login/TenantChangePassword',
          input,
          this.options.licenseServerHost
        );
        if (result.status === 200 && result.value) {
          this.result = result.value;
          await this.hideModal();
        } else {
          this.error = `Password Update unsuccessful: ${result.statusText}`;
          this.signInMode = signInMode.signIn;
          this.requestUpdate();
        }
      }
    } finally {
      this.loading = false;
      this.requestUpdate();
    }
  }

  bodyTemplate(): TemplateResult {
    //any errors coming back in regard to failed authentication are displayed below the password in this injected location.
    const error =
      this.error !== null ? html` <div class="alert alert-danger" role="alert">${this.error}</div>` : html``;

    const info =
      this.message !== null ? html` <div class="alert alert-info" role="alert">${this.message}</div>` : html``;

    const keydownEvent = async (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        await this.signIn();
      }
    };
    const signInEvent = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.signIn();
    };

    const resetPasswordEvent = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.resetPassword();
    };

    const backToSignInEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      this.signInMode = signInMode.signIn;
      this.requestUpdate();
    };

    const forgotPasswordEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      this.signInMode = signInMode.resetPassword;
      this.requestUpdate();
    };

    const signOutEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      this.cancel();
    };
    const logOutTemplate = () => {
      return this.lockUser
        ? html`<button @click=${signOutEvent} type="button" class="btn btn-secondary">Sign-Out</button>`
        : html``;
    };

    const confirmation =
      this.signInMode !== signInMode.updatePassword
        ? html``
        : html` <div class="form-item text-field">
            <label for="pwd" class="form-label">Confirm Password</label>
            <input
              type="password"
              @keydown=${keydownEvent}
              class="form-control"
              id="password-confirm"
              ?readonly=${this.loading}
            />
          </div>`;
    let title = 'Log in';
    switch (this.signInMode) {
      case signInMode.signIn:
        title = tlang`Log In`;
        break;
      case signInMode.get2FA:
        title = tlang`Enter 2FA Credential`;
        break;
      case signInMode.updatePassword:
        title = tlang`Update Password`;
        break;
      case signInMode.resetPassword:
        title = tlang`Reset Password`;
        break;
    }

    const passwordLabel =
      this.signInMode === signInMode.signIn || this.signInMode === signInMode.get2FA
        ? tlang`Password`
        : tlang`Enter New Password`;
    const userReadonly =
      this.loading ||
      this.lockUser ||
      this.signInMode === signInMode.updatePassword ||
      this.signInMode === signInMode.get2FA;
    const userNameTemplate = html`
      <div class="form-item text-field">
        <label for="userName" class="form-label">${tlang`%%user%% Name`}</label>
        <input type="text" class="form-control" id="username" .value=${this.userName} ?readonly=${userReadonly} />
      </div>
    `;
    const loadingTemplate = this.loading ? html`<i class="fa-regular fa-arrows-rotate fa-spin"></i>` : html``;
    const mainTemplate =
      this.signInMode === signInMode.get2FA
        ? html`
            ${userNameTemplate}
            <div class="form-item text-field mb-3">
              <label for="code2FA" class="form-label">${tlang`Enter 2FA Code`}</label>
              <input
                type="text"
                @keydown=${keydownEvent}
                class="form-control"
                id="code2FA"
                ?readonly=${this.loading}
                ?disabled=${this.loading}
              />
            </div>
            <div class="form-group">
              <button @click=${signInEvent} ?disabled=${this.loading} type="button" class="btn btn-primary">
                ${loadingTemplate} Sign-in
              </button>
              ${logOutTemplate()}
            </div>
            <div class="form-links">
              <button id="btnLogout" type="submit" ?disabled=${this.loading} class="btn btn-link">
                Forgot Password
              </button>
            </div>
          `
        : this.signInMode === signInMode.resetPassword
          ? html`
              ${userNameTemplate}
              <div class="form-item form-actions">
                <button @click="${resetPasswordEvent}" ?disabled=${this.loading} type="button" class="btn btn-primary">
                  Reset Password
                </button>
                <button @click="${backToSignInEvent}" ?disabled=${this.loading} type="button" class="btn btn-link">
                  Back
                </button>
              </div>
            `
          : html`
              ${userNameTemplate}
              <div class="form-item text-field mb-3">
                <label for="pwd" class="form-label">${passwordLabel}</label>
                <input
                  type="password"
                  @keydown=${keydownEvent}
                  class="form-control"
                  id="password"
                  ?readonly=${this.loading}
                  ?disabled=${this.loading}
                  .value=${this.password}
                />
              </div>
              ${confirmation}
              <div class="form-item form-actions">
                <button @click=${signInEvent} type="button" ?disabled=${this.loading} class="btn btn-primary">
                  Sign-in
                </button>
                <button @click=${forgotPasswordEvent} type="button" ?disabled=${this.loading} class="btn btn-link">
                  Forgot my password
                </button>
                ${logOutTemplate()}
              </div>
            `;

    //very simple dialog. might be worth wrapping in a form control
    return html`
      <div class="login-page-image">
        <img src="${this.options.branding.loginImageUrl}" alt="Login page" />
      </div>
      <div class="page-content">
        <div class="dealer-logo">
          <!--Paste dealer logo here-->
        </div>
        <div class="login-page-content">
          <h2>${title}</h2>
          <div id="account">${mainTemplate} ${info}${error}</div>
        </div>
      </div>
    `;
  }
}

class Enable2FADialogLit extends LitBaseModal {
  needsPassword = true;
  error = '';
  token = '';
  pwd = '';
  needsVerification = true;
  verificationCode = '';
  options: AuthenticationOptions;
  constructor(options: AuthenticationOptions) {
    super();
    this.options = options;
  }

  inputValue(id: string): string {
    const input = this.ui.querySelector(id);
    if (input) return (input as HTMLInputElement).value;
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  bodyTemplate(): TemplateResult {
    const sendInfo = async (code2FA?: string) => {
      const user = getCurrentUser();
      const input = {
        userName: user?.userName,
        password: this.pwd,
        dealerDeploymentId: this.options.deploymentId,
        code2FA: code2FA
      };

      const path = !code2FA ? 'Tenant2FARegistration' : 'Tenant';
      return await jsonRequest<ResultTenantLogin>(`api/Login/${path}`, input, this.options.licenseServerHost);
    };

    const verifyEvent = async () => {
      this.error = '';
      this.verificationCode = this.inputValue('#verification-code');
      const result = await sendInfo(this.verificationCode);
      if (result.status === 200 && result.value) {
        if (result.value.authenticationToken !== '') {
          this.needsPassword = false;
          this.needsVerification = false;
          this.requestUpdate();
        } else {
          if (result.value.passwordChangeRequired)
            this.error = tlang`Please signout and login again, as a password change is required`;
          else if (result.value.requires2FA) this.error = tlang`This account is already using 2FA`;
          this.requestUpdate();
        }
      } else {
        this.error = `Code invalid, please try again`;
        this.requestUpdate();
      }
    };
    const verifyPasswordEvent = async () => {
      this.error = '';
      this.pwd = this.inputValue('#password');
      const result = await sendInfo();
      if (result.status === 200 && result.value) {
        if (result.value.authenticationToken !== '') {
          this.needsPassword = false;
          this.token = result.value.token2FA ?? '';
          this.requestUpdate();
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
            (this.ui.querySelector('#verification-code') as HTMLInputElement)?.focus();
          }, 100);
        } else {
          if (result.value.passwordChangeRequired)
            this.error = tlang`Please signout and login again, as a password change is required`;
          else if (result.value.requires2FA) this.error = tlang`This account is already using 2FA`;
          this.requestUpdate();
        }
      } else {
        this.error = `Login unsuccessful: ${result.statusText}`;
        this.requestUpdate();
      }
    };
    const closeEvent = () => {
      this.hideModal();
    };

    const errTemplate = () => {
      if (this.error !== '') return html` <div class="alert alert-danger" role="alert">${this.error}</div>`;
      else return html``;
    };
    if (this.needsPassword) {
      return html`
        <div class="two-fa-block two-fa-password-confirmation">
          <div class="two-fa-header">${tlang`Please confirm password to continue`}</div>
          <div class="two-fa-inputs">
            <bs-form-input data-id="password" type="password" data-label=${tlang`Password`}></bs-form-input>
          </div>
          <div class="two-fa-buttons">
            <button class="btn btn-secondary" @click=${closeEvent}>${tlang`Close`}</button>
            <button class="btn btn-primary" @click=${verifyPasswordEvent}>${tlang`Verify`}</button>
          </div>
          ${errTemplate()}
        </div>
      `;
    } else if (this.needsVerification) {
      return html`
        <div class="two-fa-block two-fa-password-auth-app-code">
          <div class="two-fa-header">${tlang`Scan code into authentication app`}</div>
          <div class="two-fa-code">
            <webmodule-qr-code
              value="${`otpauth://totp/Softtech%20Dealer?secret=${this.token}&issuer=Softech`}"
              label="Webmodule website"
            ></webmodule-qr-code>
          </div>
          <div class="two-fa-inputs">
            <bs-form-input data-id="verification-code" type="text" data-label="${tlang`Enter Code From App`}">
            </bs-form-input>
          </div>
          <div class="two-fa-buttons">
            <button class="btn btn-secondary" @click="${closeEvent}">${tlang`Close`}</button>
            <button class="btn btn-primary" @click="${verifyEvent}">${tlang`Verify`}</button>
          </div>
          ${errTemplate()}
        </div>
      `;
    } else {
      return html`
        <div class="two-fa-block two-fa-enabled">
          <div class="two-fa-header">${tlang`2FA is now enabled`}</div>
          <div class="two-fa-alert">${errTemplate()}</div>
          <div class="two-fa-buttons">
            <button class="btn btn-primary" @click=${closeEvent}>${tlang`Close`}</button>
          </div>
        </div>
      `;
    }
  }
  title(): string {
    return tlang`Enable 2FA`;
  }
}
