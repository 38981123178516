import { css } from 'lit';

export default css`
  .form-control .form-control__label {
    display: none;
  }

  /* Label */
  .form-control--has-label .form-control__label {
    display: inline-block;
    color: var(--webmodule-control-label-color);
    margin-bottom: var(--webmodule-spacing-3x-small);
  }

  .form-control--has-label.form-control--small .form-control__label {
    font-size: var(--webmodule-control-label-font-size-small);
  }

  .form-control--has-label.form-control--medium .form-control__label {
    font-size: var(--webmodule-control-label-font-size-medium);
  }

  .form-control--has-label.form-control--large .form-control__label {
    font-size: var(--webmodule-control-label-font-size-large);
  }

  :host([required]) .form-control--has-label .form-control__label::after {
    content: var(--webmodule-control-required-content);
    margin-inline-start: var(--webmodule-control-required-content-offset);
    color: var(--webmodule-control-required-content-color);
  }
`;
