import type { ReactiveElement } from 'lit';

interface MonitorOptions {
  delayMonitorUntilFirstUpdate?: boolean;
}

interface Observer {
  method: (...args: unknown[]) => void;
  keys: PropertyKey[];
  options: MonitorOptions;
}

export const observersForClass = new WeakMap<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Function,
  Observer[]
>();

export function monitor<T extends ReactiveElement>(properties: keyof T | (keyof T)[], monitorOptions?: MonitorOptions) {
  const options: Required<MonitorOptions> = {
    delayMonitorUntilFirstUpdate: false,
    ...monitorOptions
  };

  const monitoredProperties = Array.isArray(properties) ? properties : [properties];

  return function (
    target: T,
    _propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: unknown[]) => void>
  ) {
    let observers = observersForClass.get(target.constructor);
    if (observers === undefined) {
      observersForClass.set(target.constructor, (observers = []));
    }
    observers.push({ keys: monitoredProperties, method: descriptor.value!, options });
  };
}
