import { LocalizeController } from '../../common/localize-controller.js';
import { property } from 'lit/decorators.js';
import WebModuleElement from '../../common/webmodule-element.js';

/**
 * @summary Component summary
 *
 * @tag webmodule-format-list
 */
export default class WebmoduleFormatList extends WebModuleElement {
  @property() lang: string;
  @property({
    converter: {
      fromAttribute: (value: string) => value.split(' ')
    }
  })
  value: string | string[] = '';

  @property() type: 'conjunction' | 'disjunction' | 'unit' = 'conjunction';

  @property({ attribute: 'grouping-style' }) groupingStyle: 'long' | 'short' | 'narrow' = 'long';
  private readonly localizeController = new LocalizeController(this);

  render() {
    return this.localizeController.list(this.value, {
      style: this.groupingStyle,
      type: this.type
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-format-list': WebmoduleFormatList;
  }
}
