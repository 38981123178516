import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './placeholder.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary A placeholder component to visually show where content will be rendered
 *
 * @csspart base - The component's base wrapper.
 * @csspart display - The display element
 *
 * @cssproperty --border-radius - The border radius of the display element.
 * @cssproperty --color - The color of the display element.
 * @cssproperty --shimmer-color - The shimmer color of the display element.
 *
 * @tag webmodule-placeholder
 */
export default class WebmodulePlaceholder extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  /** Flag indicating if the shimmer animation should be applied. */
  @property({ type: Boolean, reflect: true })
  shimmer = false;

  render() {
    return html`
      <div
        part="base"
        class="${classMap({
          placeholder: true,
          'placeholder--shimmer': this.shimmer
        })}"
      >
        <div part="display" class="placeholder--display"></div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-placeholder': WebmodulePlaceholder;
  }
}
