import { DateTime } from 'luxon';
import { getApiFactory } from '../../api/api-injector';
import { longAgo, minutesSince } from '../../../webmodule-common/other/datetime-converter';
import { TenantLoginPublicInfo } from '../../api/supplier-api-interface-user';

let _users: TenantLoginPublicInfo[] = [];
let lastUpdate: DateTime = longAgo();
export async function refreshAllUserCache() {
  if (minutesSince(lastUpdate) > 10) {
    _users = (await getApiFactory().supplier().getAllUsers()) ?? [];
    lastUpdate = DateTime.now();
  }
  return _users;
}

export function allUserCache() {
  return _users;
}
