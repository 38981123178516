import type { ApiProvider, NullPromise, ShowErrorEvent, StringDictionary } from '../types/misc.js';
import type { Frame, FrameAttributeGroup, FrameBuyInItem } from './frame-data.js';
import type { LitModalScreenBuilder } from './modal-factory-lit.js';
import type { ModalScreenBuilder } from './modal-factory.js';
import type { NFRCItemValues, NFRCOptions } from './nfrc-data.js';
import type { QCQuoteValues } from './quote-calculations.js';
import type { QuoteContract } from './quote-contract.js';
import type {
  QuoteDefaultsDataProvider,
  QuoteFrameBuyInItem,
  QuoteFrameItemPrice,
  ResultProcessQuoteFrameItem
} from './quote-interface.js';
import type { QuoteShippingInformation } from './shipping-information.js';
import type { TemplateResult } from 'lit';

export interface V6QuoteDefaultsEditor {
  applyQuoteDefaultsToItems: () => Promise<void>;
  resetQuoteDefaults: () => Promise<void>;
  prepareForSave: () => Promise<boolean>;
  ui: HTMLElement;
  dataProvider?: QuoteDefaultsDataProvider;
  releaseResources: () => Promise<void>;
}

export interface QuoteIGUDataProvider {
  /**
   * external provider would pass in the supplier id. eg Quote.SupplierId
   */
  supplierId: string;
  supplierReferenceOverrideKey?: string;

  /**
   * Should this viewer be readonly.. ie quote state, locked viewer etc.
   */
  readonly: boolean;
  /**
   * provide in the data that is the QuoteIGUs to map to the defaults editor for selecting in fills
   */
  quoteIGUs: unknown; // V6QuoteIGU[]
}

export interface V6QuoteIGUEditor {
  addIGU: () => Promise<boolean>;
  prepareForSave: () => Promise<boolean>;
  readonly ui: HTMLElement;
  dataProvider?: QuoteIGUDataProvider;
  readonly busy: boolean;
  releaseResources: () => Promise<void>;
}

export interface QuoteFrameProcessingDataProvider {
  /**
   * external provider would pass in the supplier id. eg Quote.SupplierId
   */
  supplierId: string;
  supplierReferenceOverrideKey: string;

  /**
   *
   * provide in the data that is the QuoteIGUs to map to the defaults editor for selecting in fills
   */
  quoteItem: unknown; // V6QuoteItem
  quoteIGUs: unknown; // V6QuoteIGU[]
  quantity: number;
  quoteDefaults: unknown; // V6AttributeGroup[]

  progress?: (caption: string) => Promise<void>;
}

export const v6FrameUserMode = {
  powerUser: 'POWERUSER',
  standardUser: 'STANDARDUSER'
};

export interface QuoteItemProvider {
  id: string;
  quantity: number;
  /**
   * provide in the data that is the QuoteIGUs to map to the defaults editor for selecting in fills
   */
  quoteItem: unknown; // V6QuoteItemExternalStorage
  thumbnailURL: string;
}

export interface QuoteFrameDataProvider extends QuoteItemProvider {
  /**
   * external provider would pass in the supplier id. eg Quote.SupplierId
   */
  supplierId: string;
  supplierReferenceOverrideKey: string;
  /**
   * Should this viewer be readonly.. ie quote state, locked viewer etc.
   */
  readonly: boolean;
  quoteIGUs: unknown; // V6QuoteIGU[]
  propertiesTemplate: () => TemplateResult;
  quoteDefaults: unknown; // V6AttributeGroup[]
  changeNotifier?: () => void;
  framePicker?: V6PickerFamilyButton;
}

export interface QuoteFreehandDataProvider {
  /**
   * external provider would pass in the supplier id. eg Quote.SupplierId
   */
  supplierId: string;
  quoteItemCommonId: string | null;
  supplierReferenceOverrideKey: string;
  quantity: number;
  singleUnitGrossSellPrice: number;
  itemSubType: number | null;
}

export interface V6QuoteFrameEditorData {
  price: QuoteFrameItemPrice;
  buyInItems: QuoteFrameBuyInItem[];
  quoteItem: unknown;
  //This is the mode required to edit this frame
  //either power or standard, based on what is edited during the session.
  requiredUserMode: string;
}

export interface V6QuoteFrameEditor {
  readonly ui: HTMLElement;
  readonly busy: boolean;
  thumbnail: string;
  frameDescription: string;
  releaseResources: () => Promise<void>;
  commit: () => NullPromise<V6QuoteFrameEditorData>;
}
export interface V6QuoteFrameBomViewer {
  readonly ui: HTMLElement;
  releaseResources: () => Promise<void>;
}

export interface V6PickerFamilyButton {
  caption: string;
  family: string;
  brandingPublicUrl: string;
  brandingCaption: string;
}

export interface V6QuoteItemEditorServices {
  getQuoteDefaultsEditorService: (dataProvider: QuoteDefaultsDataProvider) => V6QuoteDefaultsEditor;
  getQuoteIGUEditorService: (dataProvider: QuoteIGUDataProvider) => V6QuoteIGUEditor;
  getQuoteFrameEditorService: (dataProvider: QuoteFrameDataProvider) => NullPromise<V6QuoteFrameEditor>;
  getQuoteItemFreehandPrice: (dataProvider: QuoteFreehandDataProvider) => NullPromise<QuoteFrameItemPrice>;
  getFramePickerButtons: (supplierId: string) => NullPromise<V6PickerFamilyButton[]>;
  getBomViewer: (dataProvider: QuoteItemProvider) => NullPromise<V6QuoteFrameBomViewer>;
}

export type EventAsync<TResult> = () => Promise<TResult>;
export type EventAsyncExecutor<TResult> = (event: EventAsync<TResult>) => NullPromise<TResult>;

export interface V6ConfigEventHandlers {
  setLangHandler: (
    aEvent: (template: TemplateStringsArray, ...args: unknown[]) => string,
    langEvent: (text: string, namedResource?: string, doNotAddMissing?: boolean) => string
  ) => void;
  setErrorDialogEventHandler: (handler: ShowErrorEvent) => void;
  setApiInjector: (apiInjector: ApiProvider) => void;
  setModalScreenFactory: (aEvent: ModalScreenBuilder, aEvent1: LitModalScreenBuilder) => void;
  setUILockAndExecuteEvent: (event: EventAsyncExecutor<unknown>) => void;
  setUserMode: (userMode: string) => void;
}

export interface V6FrameSize {
  width?: number;
  displayWidth?: string;
  height?: number;
  displayHeight?: string;
  displayHeightFirst: boolean;
}

export interface V6QuoteItemSupplierInfo {
  supplierId: string;
  quoteItemVersion: string;
  isVersionCurrent: boolean;
}
export interface V6QuoteBrief {
  quoteCommonId: string;
  fullQuoteNumber: string;
  shippingLeadTime: number;
  deliveryDate: string;
  closedDate: string;
  status: string;
  notesFromSupplier: string;
}
export interface V6DataUtilities {
  getNFRCData: (frameData: object, requiredPublishedCodes: string[]) => NFRCItemValues[];
  getNFRCOptions: (supplierId: string) => Promise<NFRCOptions>;
  getFrameDimensions: (quoteItem: object /* V6QuoteItem */) => V6FrameSize;
  getCustomFrameDimensions: (quoteItem: object /* V6QuoteItem */) => string | undefined;
  getFrameData: (quoteItem: object /* V6QuoteItem */) => Frame;
  getQuoteDefaults: (quoteDefaults: object /* V6AttributeGroup[] */) => FrameAttributeGroup[];
  getQuoteItemSupplier: (quoteItem: object /* V6QuoteItem */) => V6QuoteItemSupplierInfo;
  getV6SupplierQuoteInfo: (supplierId: string, quoteCommonId: string) => NullPromise<V6QuoteBrief>;
  getFrameBuyInItems: (quoteItem: object /* V6QuoteItem */) => FrameBuyInItem[];
}
export enum V6OfflineCause {
  Connection = 0,
  Upgrading = 1,
  VersionMismatch = 2,
  InvalidV6ClientCode = 3
}
export interface SupplierInfo {
  id: string;
  description: string;
  online: boolean;
  offlineCause?: V6OfflineCause;
  version: string;
  isMetric: boolean;
  instanceId: string;
  configVersion: string;
  options: StringDictionary | null;
}

export interface RequestGetSSI {
  quote: QuoteContract;
  shippingInformation: QuoteShippingInformation;
  supplierReferenceOverrideKey: string;
}

export interface V6ServerSideSpecialItem {
  name: string;
  title: string;
  description: string;
  quantity: number;
  comment: string | null;
  quoteItemContentType: number;
  unitPrice: number;
  sourceData: QuoteFrameItemPrice | null;
  clientDocumentation?: string | null; //markdown content to be attached for viewing on quote
  dealerDocumentation?: string | null; //markdown content to be attached for viewing on quote
}

//all notes are "static" content which will not change dependant on situation
export enum SupplierNoteType {
  //fetch the warranty via v6config api call
  clientWarranty = 0,
  //fetch markdown note to be displayed on quote for dealer consumption
  dealerNoteMarkDown = 1,
  //fetch markdown note to be displayed on quote for client consumption
  clientNoteMarkDown = 2,
  //fetch a pdf document for client consumption
  clientDocument = 3,
  //fetch a pdf document for dealer consumption
  dealerDocument = 4,
  dealerWarranty = 5
}
export interface SupplierNote {
  noteType: SupplierNoteType;
  code: string;
}
export interface ResultGetSSI {
  items: V6ServerSideSpecialItem[];
  quoteCalculations: QCQuoteValues | null;
  quoteDocumentation: SupplierNote[] | null;
}

export interface V6Configurator {
  setSupplierReferenceOverrideKey: (supplierReferenceOverrideKey: string) => void;
  suppliers: (isVersionCritical?: boolean) => Promise<SupplierInfo[] | null>;
  isSupplierOnline: (supplierId: string) => Promise<boolean>;
  performSupplierHealthCheckLazy: () => Promise<void>;
  performSupplierHealthCheck: () => Promise<void>;
  getSupplier: (supplierId: string) => SupplierInfo;
  /**
   *
   * @param dataProvider
   * @param updatePrice if left undefined value is true
   * @returns
   */
  processV6QuoteItem: (
    dataProvider: QuoteFrameProcessingDataProvider,
    updatePrice?: boolean
  ) => Promise<ResultProcessQuoteFrameItem | null>;
  getQuoteSSI: (input: RequestGetSSI) => Promise<ResultGetSSI>;
}

export interface V6ConfigEntryPoint {
  events: V6ConfigEventHandlers;
  config: V6Configurator;
  util: V6DataUtilities;
  editors: V6QuoteItemEditorServices;
}
