export function isNothing(...item: any[]): boolean {
  return item.every(i => i === undefined || i === null);
}

export function isSomething(...item: any[]): boolean {
  return item.some(i => i !== undefined && i !== null);
}

export function anyIsNothing(...item: any[]): boolean {
  return item.some(i => i === undefined || i === null);
}

export function allIsSomething(...item: any[]): boolean {
  return item.every(i => i !== undefined && i !== null);
}
