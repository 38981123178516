// eslint-disable-next-line import/named
import { asMarkdownTemplate } from '../general/markdown';
import { html, TemplateResult } from 'lit';
import { LitBaseModal } from './modal/modal-factory-lit';
import { tlang } from '../language/lang';

export interface OptionButton {
  caption: string;
  value: string;
  btnClass?: 'default' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' | 'text';
}

export function delayedInformation(info: string) {
  setTimeout(() => {
    information(info);
  }, 1000);
}

export async function information(message: string, title?: string) {
  await askOption(message, [{ caption: tlang`Ok`, value: '' }], title ?? tlang`Information`);
}

export async function askOption(message: string, buttons: OptionButton[], title?: string): Promise<string> {
  const modal: OptionDialog = new OptionDialog(message, buttons, title);
  await modal.showModal();
  return modal.result;
}

// noinspection HtmlUnknownAttribute

class OptionDialog extends LitBaseModal {
  message: string;
  result = '';
  buttons: OptionButton[];
  eventTitle: string;

  constructor(message: string, buttons: OptionButton[], title?: string) {
    super();
    this.eventTitle = title ?? tlang`Confirm`;
    this.message = message;
    this.buttons = buttons;
  }

  title(): string {
    return this.eventTitle;
  }

  footerTemplate(): TemplateResult {
    return html` ${this.buttons.map(b => this.buttonTemplate(b))} `;
  }
  buttonTemplate(b: OptionButton): TemplateResult {
    const event = () => {
      this.result = b.value;
      this.hideModal();
    };
    const btnClass = b.btnClass ?? 'primary';
    return html` <webmodule-button size="small" variant="${btnClass}" @click=${event}>${b.caption} </webmodule-button>`;
  }

  bodyTemplate() {
    return html` ${asMarkdownTemplate(this.message)} `;
  }

  protected get modalSize() {
    return 'modal-md';
  }
}
