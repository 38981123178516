import { showValidations } from './modal-validationhandler';
import { ErrorValidation } from '../save-workflow';

export async function checkValidations(errors: string[]): Promise<boolean> {
  if (errors.length != 0) {
    await showValidations(errors);
    return false;
  }
  return true;
}

export function throwValidations(errors: string[]) {
  if (errors.length !== 0) {
    throw new ErrorValidation(errors);
  }
}

/**
 *
 * @param value value to compare. if null, true is always returned
 * @param min
 * @param max
 */
export function inRange(value: number | null, min: number, max: number): boolean {
  if (value === null) return true;
  return min <= value && value <= max;
}
