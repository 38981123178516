import { animations } from './animations.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, queryAsync } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './animate.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @event webmodule-cancel - Emitted when the animation is canceled.
 * @event webmodule-finish - Emitted when the animation finishes.
 * @event webmodule-start - Emitted when the animation starts or restarts.
 *
 * @slot - The element to animate. Avoid slotting in more than one element, as subsequent ones will be ignored. To
 *  animate multiple elements, either wrap them in a single container or use multiple `<webmodule-animation>` elements.
 *
 *  @tag webmodule-animate
 */
export default class WebmoduleAnimate extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  private animation?: Animation;
  private hasStarted = false;

  @queryAsync('slot')
  defaultSlot: Promise<HTMLSlotElement>;

  @property()
  name = 'none';

  @property({ type: Boolean, reflect: true })
  play = false;

  @property({ type: Number })
  delay = 0;

  @property()
  direction: PlaybackDirection = 'normal';

  @property({ type: Number })
  duration = 1000;

  @property()
  easing = 'linear';

  @property({ attribute: 'end-delay', type: Number })
  endDelay = 0;

  @property()
  fill: FillMode = 'auto';

  @property({ type: Number })
  iterations = Infinity;

  @property({ attribute: 'iteration-start', type: Number })
  iterationStart = 0;

  @property({ attribute: false })
  keyframes?: Keyframe[];

  @property({ attribute: 'playback-rate', type: Number })
  playbackRate = 1;

  get currentTime(): CSSNumberish {
    return this.animation?.currentTime ?? 0;
  }

  set currentTime(time: number) {
    if (this.animation) {
      this.animation.currentTime = time;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.createAnimation();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.destroyAnimation();
  }

  private handleAnimationFinish = () => {
    this.play = false;
    this.hasStarted = false;
    this.emit('webmodule-animation-stop');
  };

  private handleAnimationCancel = () => {
    this.play = false;
    this.hasStarted = false;
    this.emit('webmodule-animation-cancel');
  };

  private handleSlotChange() {
    this.destroyAnimation();
    this.createAnimation();
  }

  private async createAnimation() {
    // @ts-expect-error lookup easing function or assign custom function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const easing: string = animations.easings[this.easing] ?? this.easing;
    const keyframes = this.keyframes ?? (animations as unknown as Partial<Record<string, Keyframe[]>>)[this.name];
    const slot = await this.defaultSlot;

    //always using the first element. Do not add more than 1 element to the slot.
    //If you want to animate a group of elements, add it to a wrapper element or give each their own animate component
    const element = slot.assignedElements()[0] as HTMLElement | undefined;

    if (!element || !keyframes) {
      return false;
    }

    this.destroyAnimation();
    this.animation = element.animate(keyframes, {
      delay: this.delay,
      direction: this.direction,
      duration: this.duration,
      easing,
      endDelay: this.endDelay,
      fill: this.fill,
      iterationStart: this.iterationStart,
      iterations: this.iterations
    });
    this.animation.playbackRate = this.playbackRate;
    this.animation.addEventListener('cancel', this.handleAnimationCancel);
    this.animation.addEventListener('finish', this.handleAnimationFinish);

    if (this.play) {
      this.hasStarted = true;
      this.emit('webmodule-animation-start');
    } else {
      this.animation.pause();
    }

    return true;
  }

  private destroyAnimation() {
    if (this.animation) {
      this.animation.cancel();
      this.animation.removeEventListener('cancel', this.handleAnimationCancel);
      this.animation.removeEventListener('finish', this.handleAnimationFinish);
      this.hasStarted = false;
    }
  }

  @monitor(['name', 'delay', 'direction', 'duration', 'easing', 'endDelay', 'fill', 'iterations', 'keyframes'])
  handleAnimationChange() {
    if (!this.hasUpdated) {
      return;
    }

    this.createAnimation();
  }

  @monitor('play')
  handlePlayChange() {
    if (this.animation) {
      if (this.play && !this.hasStarted) {
        this.hasStarted = true;
        this.emit('webmodule-animation-start');
      }

      if (this.play) {
        this.animation.play();
      } else {
        this.animation.pause();
      }

      return true;
    }
    return false;
  }

  @monitor('playbackRate')
  handlePlaybackRateChange() {
    if (this.animation) {
      this.animation.playbackRate = this.playbackRate;
    }
  }

  cancel() {
    this.animation?.cancel();
  }

  finish() {
    this.animation?.finish();
  }

  render() {
    return html`<slot @slotchange=${this.handleSlotChange}></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-animate': WebmoduleAnimate;
  }
}
