import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { DataTracker } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { isNothing, isSomething } from '../../../webmodule-common/other/general/nothing';
import { errorList } from '../../../webmodule-common/other/error-list';
import { fileIsImageFile } from '../../../webmodule-common/other/ui/file-helper-functions';
import { tlangMarkdown } from '../../../webmodule-common/other/general/markdown';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getAbsoluteVirtualFilePath } from '../../api/blob-helper';

@autoElement()
export class SettingsLoginImageBrandingView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;

  private loginImageName = 'supplier-login-image';
  private loginImage?: File;
  private datatracker: DataTracker;

  constructor() {
    super();
    this.pageFragment = 'loginImage';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });

    this.datatracker = new DataTracker(this.dataBinding);
    this.datatracker.addImageLink(
      this.loginImageName,
      () => getAbsoluteVirtualFilePath(this.settings?.loginImageVirtualPath),
      value => {
        this.loginImage = value;
      }
    );
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  getValidationErrors(): string[] {
    const errors = errorList();

    errors.assert(isNothing(this.loginImage) || fileIsImageFile(this.loginImage), tlang`Please provide a valid image`);
    // throw image upload error only when a file has been provided, but it isn't a valid image

    return errors.errors;
  }

  async prepareForSave(): Promise<void> {
    //nothing to do;
  }

  public internalDataChanged(): boolean {
    return isSomething(this.loginImage);
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    this.requestUpdate();
  }

  protected revertChanges(): Promise<boolean> {
    this.loginImage = undefined;
    this.settingsManager.reset();
    return Promise.resolve(true);
  }

  protected getCaption(): Snippet {
    return tlang`Login Image`;
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a image from the given element and get its uploaded file path
    const newloginImageVirtualPath = await this.dataBinding.createVirtualFile(this.loginImage);

    // use the new path of the image if it was uploaded, or the existing image path
    this.settingsManager.updateInput.base64LoginImage = newloginImageVirtualPath?.content ?? null;
    this.settingsManager.updateInput.loginImageFileName = newloginImageVirtualPath
      ? `loginImage${newloginImageVirtualPath.extension}`
      : null;

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Login Image saved`);
      this.datatracker.removeFilesFromEditors();
      return true;
    }

    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const form = new FormInputAssistant(this.datatracker, false, true);

    const brandingMsg = tlangMarkdown`${'ref:SettingsLoginImageBrandingView:uploadMsg:1'}
Please upload your custom login screen image<br>

The minimum permitted size is **1920(W) x 1080(H)** pixels.<br>
The image must be in **jpg** or **png** format.<br>
Please note that unoptimized images may increase the page loading time.<br>
If the aspect ratio of your image is different, it will be scaled down in proportion to fit the template constraints.
`;
    return html` <div>
      <form id="LoginImageForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-12 form-column">
            <h4 class="section-header branding-image-header">${tlang`Login Image`}</h4>
            <div class="branding-image-description">
              ${tlang`This image appears on the login and password reset pages`}
            </div>
            ${form.imagePicker(this.loginImageName, tlang`Login Screen Image`)}
          </div>
        </div>
        <h2>${tlang`Specifications`}:</h2>
        <p>${brandingMsg}</p>
      </form>
    </div>`;
  }
}
