import { classMap } from 'lit/directives/class-map.js';
import { defaultValue } from '../../common/default-value.js';
import { FormController } from '../../common/form-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './toggle.styles.js';
import WebModuleElement, { type WebModuleForm } from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Toggles switches between binary state (on/off).
 *
 * @slot - The toggle's label.
 *
 * @event webmodule-blur - Emitted when the control loses focus.
 * @event webmodule-change - Emitted when the control's checked state changes.
 * @event webmodule-input - Emitted when the control receives input.
 * @event webmodule-focus - Emitted when the control gains focus.
 * @event webmodule-invalid - Emitted when the form control has been checked for validity and its constraints aren't satisfied.
 *
 * @csspart base - The component's base wrapper.
 * @csspart control - The control that houses the toggle's thumb.
 * @csspart thumb - The toggle's thumb.
 * @csspart label - The toggle's label.
 *
 * @cssproperty --width - The width of the toggle.
 * @cssproperty --height - The height of the toggle.
 * @cssproperty --thumb-size - The size of the thumb.
 *
 * @tag webmodule-toggle
 */
export default class WebmoduleToggle extends WebModuleElement implements WebModuleForm {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  @query('input[type="checkbox"]') input: HTMLInputElement;
  @property() title = '';
  /** The name of the toggle, submitted as a name/value pair with form data. */
  @property() name = '';
  /** The current value of the toggle, submitted as a name/value pair with form data. */
  @property() value: string;
  /** The toggle's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the toggle. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /** Draws the toggle in a checked state. */
  @property({ type: Boolean, reflect: true }) checked = false;
  /** The default value of the form control. Primarily used for resetting the form control. */
  @defaultValue('checked') defaultChecked = false;
  /** Makes the toggle a required field. */
  @property({ type: Boolean, reflect: true }) required = false;
  /**
   * By default, form controls are associated with the nearest containing `<form>` element. This attribute allows you
   * to place the form control outside a form and associate it with the form that has this `id`. The form must be in
   * the same document or shadow root for this to work.
   */
  @property({ reflect: true }) form = '';
  private readonly formControlController = new FormController(this, {
    value: (control: WebmoduleToggle) => (control.checked ? control.value || 'on' : undefined),
    defaultValue: (control: WebmoduleToggle) => control.defaultChecked,
    setValue: (control: WebmoduleToggle, checked: boolean) => (control.checked = checked)
  });
  @state() private hasFocus = false;

  /** Gets the validity state object */
  get validity() {
    return this.input.validity;
  }

  /** Gets the validation message */
  get validationMessage() {
    return this.input.validationMessage;
  }

  firstUpdated() {
    this.formControlController.updateValidity();
  }

  @monitor('checked', { delayMonitorUntilFirstUpdate: true })
  handleCheckedChange() {
    this.input.checked = this.checked;
    this.formControlController.updateValidity();
  }

  @monitor('disabled', { delayMonitorUntilFirstUpdate: true })
  handleDisabledChange() {
    // Disabled form controls are always valid
    this.formControlController.setValidity(true);
  }

  click() {
    this.input.click();
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  /** Checks for validity but does not show a validation message. Returns `true` when valid and `false` when invalid. */
  checkValidity() {
    return this.input.checkValidity();
  }

  /** Gets the associated form, if one exists. */
  getForm(): HTMLFormElement | null {
    return this.formControlController.getForm();
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    return this.input.reportValidity();
  }

  /** Sets a custom validation message. Pass an empty string to restore validity. */
  setCustomValidity(message: string) {
    this.input.setCustomValidity(message);
    this.formControlController.updateValidity();
  }

  render() {
    return html`
      <div
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large'
        })}
      >
        <label
          part="base"
          class=${classMap({
            toggle: true,
            'toggle--checked': this.checked,
            'toggle--disabled': this.disabled,
            'toggle--focused': this.hasFocus,
            'toggle--small': this.size === 'small',
            'toggle--medium': this.size === 'medium',
            'toggle--large': this.size === 'large'
          })}
        >
          <input
            class="toggle__input"
            type="checkbox"
            title=${this.title}
            name=${this.name}
            value=${ifDefined(this.value)}
            ?checked=${live(this.checked)}
            ?disabled=${this.disabled}
            ?required=${this.required}
            role="switch"
            aria-checked=${this.checked ? 'true' : 'false'}
            aria-describedby="help-text"
            @click=${this.handleClick}
            @input=${this.handleInput}
            @blur=${this.handleBlur}
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @invalid=${this.handleInvalid}
          />

          <span part="control" class="toggle__control">
            <span part="thumb" class="toggle__thumb"></span>
          </span>

          <div part="label" class="toggle__label">
            <slot></slot>
          </div>
        </label>
      </div>
    `;
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleInput() {
    this.emit('webmodule-input');
  }

  private handleClick() {
    this.checked = !this.checked;
    this.emit('webmodule-change');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.checked = false;
      this.emit('webmodule-change');
      this.emit('webmodule-input');
    }

    if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.checked = true;
      this.emit('webmodule-change');
      this.emit('webmodule-input');
    }
  }

  private handleInvalid(event: Event) {
    this.formControlController.setValidity(false);
    this.formControlController.emitInvalidEvent(event);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-toggle': WebmoduleToggle;
  }
}
