export function waitForEvent(el: HTMLElement, eventName: string) {
  return new Promise<void>(resolve => {
    function done(event: Event) {
      if (event.target === el) {
        el.removeEventListener(eventName, done);
        resolve();
      }
    }

    el.addEventListener(eventName, done);
  });
}

export type { WebmoduleLoadEvent } from './webmodule-load.js';
export type { WebmoduleErrorEvent } from './webmodule-error.js';
export type { WebmoduleRepositionEvent } from './webmodule-reposition.js';
export type { WebmoduleAnimationStartEvent } from './webmodule-animation-start.js';
export type { WebmoduleAnimationStopEvent } from './webmodule-animation-stop.js';
export type { WebmoduleAnimationCancelEvent } from './webmodule-animation-cancel.js';
export type { WebmoduleShowEvent } from './webmodule-show.js';
export type { WebmoduleAfterShowEvent } from './webmodule-after-show.js';
export type { WebmoduleHideEvent } from './webmodule-hide.js';
export type { WebmoduleAfterHideEvent } from './webmodule-after-hide.js';
export type { WebmoduleFocusEvent } from './webmodule-focus.js';
export type { WebmoduleChangeEvent } from './webmodule-change.js';
export type { WebmoduleInputEvent } from './webmodule-input.js';
export type { WebmoduleBlurEvent } from './webmodule-blur.js';
export type { WebmoduleClearEvent } from './webmodule-clear.js';
export type { WebmoduleRemoveEvent } from './webmodule-remove.js';
export type { WebmoduleSelectEvent } from './webmodule-select.js';
export type { WebmoduleCollapseEvent } from './webmodule-collapse.js';
export type { WebmoduleAfterCollapseEvent } from './webmodule-after-collapse.js';
export type { WebmoduleExpandEvent } from './webmodule-expand.js';
export type { WebmoduleAfterExpandEvent } from './webmodule-after-expand.js';
export type { WebmoduleLazyLoadEvent } from './webmodule-lazy-load.js';
export type { WebmoduleLazyChangeEvent } from './webmodule-lazy-change.js';
export type { WebmoduleSelectionChangeEvent } from './webmodule-selection-change.js';
export type { WebmoduleRequestCloseEvent } from './webmodule-request-close.js';
export type { WebmoduleTabHideEvent } from './webmodule-tab-hide.js';
export type { WebmoduleTabShowEvent } from './webmodule-tab-show.js';
export type { WebmoduleInitialFocusEvent } from './webmodule-initial-focus.js';
/* plop:event */
