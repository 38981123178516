import { css } from 'lit';

export default css`
  :host {
    --header-spacing: var(--webmodule-spacing-large);
    --body-spacing: var(--webmodule-spacing-large);
    --footer-spacing: var(--webmodule-spacing-large);
    display: contents;
  }

  .modal--small {
    --width: 93vw;
    --max-width: 31rem;
  }

  .modal--medium {
    --width: 93vw;
    --max-width: 66rem;
  }

  .modal--large {
    --width: 93vw;
    --max-width: 100%;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--webmodule-z-modal);
  }

  .modal__panel {
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: var(--width);
    max-width: calc(var(--max-width) - var(--webmodule-spacing-2x-large));
    max-height: calc(100% - var(--webmodule-spacing-2x-large));
    background-color: var(--webmodule-panel-background-color);
    border-radius: var(--webmodule-border-radius-x-large);
    box-shadow: var(--webmodule-shadow-x-large);
  }

  .modal__panel:focus {
    outline: none;
  }

  @media screen and (max-width: 420px) {
    .modal__panel {
      max-height: 80vh;
    }
  }

  .modal--open .modal__panel {
    display: flex;
    opacity: 1;
  }

  .modal__header {
    flex: 0 0 auto;
    display: flex;
  }

  .modal__title {
    flex: 1 1 auto;
    font: inherit;
    font-size: var(--webmodule-font-size-large);
    line-height: var(--webmodule-line-height-dense);
    padding: var(--header-spacing);
    margin: 0;
  }

  .modal__header-actions {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: var(--webmodule-spacing-2x-small);
    padding: 0 var(--header-spacing);
  }

  .modal__header-actions webmodule-icon-button,
  .modal__header-actions ::slotted(webmodule-icon-button) {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: var(--webmodule-font-size-medium);
  }

  .modal__body {
    flex: 1 1 auto;
    display: block;
    padding: var(--body-spacing);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal__footer {
    flex: 0 0 auto;
    text-align: right;
    padding: var(--footer-spacing);
  }

  .modal__footer ::slotted(webmodule-button:not(:first-of-type)) {
    margin-inline-start: var(--webmodule-spacing-x-small);
  }

  .modal:not(.modal--has-footer) .modal__footer {
    display: none;
  }

  .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--webmodule-overlay-background-color);
  }

  @media (forced-colors: active) {
    .modal__panel {
      border: solid 1px var(--webmodule-color-neutral-0);
    }
  }
`;
