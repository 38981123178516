import { ContainerManager } from '../../../webmodule-common/other/container-manager';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { getApiFactory } from '../../api/api-injector';
import { NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { removeFromArray } from '../../../webmodule-common/other/array-helper';
import { SupplierApi } from '../../api/supplier-api';
import { Territory, TerritoryFranchiseeBranchLink } from '../../api/supplier-api-interface-supplier';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface TerritoryContainer {
  territories: Territory[];
  territoryFranchiseBranches: TerritoryFranchiseeBranchLink[];
}

export interface TerritoryContainerManagerOptions {
  container: TerritoryContainer;
}

export class TerritoryContainerManager extends ContainerManager<TerritoryContainer> {
  private supplierApi: SupplierApi = getApiFactory().supplier();

  constructor(options: TerritoryContainerManagerOptions) {
    super(options.container, () => tlang`%% %%`, tlang`%% %%`);
  }

  public get territories(): Territory[] {
    if (this.container.territories) {
      return this.container.territories;
    }
    throw new DevelopmentError(`territory-manager.ts Attempting to use undefined territories`);
  }

  public async needsTerritories(): Promise<boolean> {
    if (this.container.territories.length == 0) {
      const result = await this.supplierApi.getSupplierTerritories({});
      if (result) {
        this.resetTerritoryContainer(result.territories, result.territoryFranchiseBranches);
      }
    } else {
      return false;
    }
    return true;
  }

  public async createTerritory(territoryName: string): NullPromise<Territory> {
    const result = await this.supplierApi.createSupplierTerritory({
      territoryName: territoryName
    });
    if (result) {
      const territory = result.territory;
      this.addTerritory(territory);
      return territory;
    }
    return null;
  }

  protected addTerritory(territory: Territory) {
    this.container.territories.push(territory);
    this.backup.territories.push(this.clone(territory));
  }

  public async updateAndSaveTerritory(territory: Territory): NullPromise<Territory> {
    const result = await this.supplierApi.updateSupplierTerritories({
      territories: [territory]
    });
    if (result && result.territories.length > 0) {
      const territoryResult = result.territories[0];
      const index = this.container.territories.findIndex(t => t.id == territoryResult.id);
      if (index > -1) {
        this.container.territories[index] = territoryResult;
        this.backup.territories = this.clone(this.container.territories);
        return territory;
      }
    }
    return null;
  }

  public async deleteTerritory(territory: Territory): NullPromise<boolean> {
    const result = await this.supplierApi.deleteSupplierTerritory({
      id: territory.id
    });
    if (result) {
      removeFromArray(territory, this.container.territories);
      this.backup.territories = this.clone(this.container.territories);
      return true;
    }
    return false;
  }

  public async linkFranchiseeBranchesToTerritory(
    territoryId: string,
    branchIds: string[]
  ): NullPromise<TerritoryFranchiseeBranchLink[]> {
    const result = await this.supplierApi.linkFranchiseeBranchToTerritory({
      territoryId: territoryId,
      branchIds: branchIds
    });
    if (result) {
      this.container.territoryFranchiseBranches.push(...result.territoryFranchiseeBranchLinks);
      this.backup.territoryFranchiseBranches = this.clone(this.container.territoryFranchiseBranches);
      return result.territoryFranchiseeBranchLinks;
    }
    return null;
  }

  public async unlinkFranchiseeBranchesFromTerritory(links: TerritoryFranchiseeBranchLink[]): NullPromise<boolean> {
    const result = await this.supplierApi.unlinkFranchiseeBranchFromTerritory({
      territoryFranchiseeBranchLinks: links
    });
    if (result) {
      for (const link of links) {
        const index = this.container.territoryFranchiseBranches.findIndex(lnk => lnk.id == link.id);
        if (index > -1) {
          this.container.territoryFranchiseBranches.splice(index, 1);
        }
        this.backup.territoryFranchiseBranches = this.clone(this.container.territoryFranchiseBranches);
      }
      return true;
    }
    return false;
  }

  public getLinkByBranchAndTerritory(branchId: string, territoryId: string): TerritoryFranchiseeBranchLink | null {
    const index = this.container.territoryFranchiseBranches.findIndex(
      lnk => lnk.branchId == branchId && lnk.territoryId == territoryId
    );
    if (index > -1) {
      return this.container.territoryFranchiseBranches[index];
    }
    return null;
  }

  public getBranchLinksByTerritory(territoryId: string): TerritoryFranchiseeBranchLink[] {
    const result = this.container.territoryFranchiseBranches.filter(b => b.territoryId == territoryId);
    return result;
  }

  protected async internalSave(): Promise<boolean> {
    try {
      const result = await this.supplierApi.updateSupplierTerritories({
        territories: this.territories
      });
      if (result) {
        this.resetTerritories(result.territories);
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      throw new DevelopmentError(tlang`territory-manager.ts Unable to save territories`);
    }
  }

  protected resetTerritories(territories: Territory[]) {
    this.container.territories = territories;
    this.backup.territories = this.clone(territories);
  }

  protected resetTerritoryContainer(
    territories: Territory[],
    territoryFranchiseBranches: TerritoryFranchiseeBranchLink[]
  ) {
    this.container.territories = territories;
    this.container.territoryFranchiseBranches = territoryFranchiseBranches;
    this.backup = this.clone(this.container);
  }
}

export function getTerritoryManager(): TerritoryContainerManager {
  return new TerritoryContainerManager({
    container: {
      territories: [],
      territoryFranchiseBranches: []
    }
  });
}
