import { ServiceResponseInvalid } from '../../interop/webmodule-interop';

export const strInvalidBuildNumber = 'INVALID BUILD NUMBER';

let _error: ServiceResponseInvalid | null = null;
export function getLastApiError(): ServiceResponseInvalid | null {
  return _error;
}
export function setLastApiError(error: ServiceResponseInvalid | null) {
  _error = error;
}
