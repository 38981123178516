//acts as a datacache unless reset
export class MultiPromise<TResult> {
  private promise?: Promise<TResult>;
  public event: () => Promise<TResult>;
  autoReset: boolean;
  constructor(event: () => Promise<TResult>, autoReset = true) {
    this.event = event;
    this.autoReset = autoReset;
  }
  public async run(force = false): Promise<TResult> {
    //anything waiting for an existing promise is still waiting and will get the old value
    if (force) this.promise = undefined;

    if (!this.promise) {
      this.promise = (async () => {
        try {
          return await this.event();
        } finally {
          if (this.autoReset) this.promise = undefined;
        }
      })();
    }
    return await this.promise;
  }
  public reset() {
    this.promise = undefined;
  }
}

export class NullMultiPromise<TResult> extends MultiPromise<TResult | null> {}

export async function multiCastPromise<TResult>(
  promise: Promise<TResult> | null,
  assignment: (p: Promise<TResult> | null) => void,
  execution: () => Promise<TResult>
): Promise<TResult> {
  if (promise) return await promise;
  const newPromise = new Promise<TResult>((resolve, _reject) => {
    execution()
      .then(resolve)
      .finally(() => assignment(null))
      .catch(e => _reject(e));
  });

  assignment(newPromise);
  return await newPromise;
}
