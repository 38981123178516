import { html } from 'lit';
import componentStyles from '../../styles/component.styles.js';
import styles from './spinner.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @csspart base - The component's base wrapper.
 *
 * @cssproperty --track-width - The width of the track.
 * @cssproperty --track-color - The color of the track.
 * @cssproperty --indicator-color - The color of the spinner's indicator.
 * @cssproperty --speed - The time it takes for the spinner to complete one animation cycle.
 *
 * @tag webmodule-spinner
 */
export default class WebmoduleSpinner extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  render() {
    return html`
      <svg part="base" class="spinner">
        <circle class="spinner__track"></circle>
        <circle class="spinner__indicator"></circle>
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-spinner': WebmoduleSpinner;
  }
}
