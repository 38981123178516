import { localeCurrencyMap } from '../../common/helpers/localization-helpers.js';
import { LocalizeController } from '../../common/localize-controller.js';
import { property } from 'lit/decorators.js';
import WebModuleElement from '../../common/webmodule-element.js';

/**
 * @tag webmodule-format-number
 */
export default class WebmoduleFormatNumber extends WebModuleElement {
  @property() lang: string;
  @property({ type: Number }) value = 0;
  @property() type: 'currency' | 'decimal' | 'percent' = 'decimal';
  @property({ attribute: 'no-grouping', type: Boolean }) noGrouping = false;
  /** The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code to use when formatting. */
  @property() currency?: string;
  @property({ attribute: 'currency-display' }) currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name' =
    'narrowSymbol';
  @property({ attribute: 'minimum-integer-digits', type: Number }) minimumIntegerDigits: number;
  @property({ attribute: 'minimum-fraction-digits', type: Number }) minimumFractionDigits: number;
  @property({ attribute: 'maximum-fraction-digits', type: Number }) maximumFractionDigits: number;
  @property({ attribute: 'minimum-significant-digits', type: Number }) minimumSignificantDigits: number;
  @property({ attribute: 'maximum-significant-digits', type: Number }) maximumSignificantDigits: number;
  private readonly localizeController = new LocalizeController(this);

  render() {
    if (isNaN(this.value)) {
      return '';
    }

    return this.localizeController.number(this.value, {
      style: this.type,
      currency: localeCurrencyMap[this.localizeController.lang()] || this.currency || 'USD',
      currencyDisplay: this.currencyDisplay,
      useGrouping: !this.noGrouping,
      minimumIntegerDigits: this.minimumIntegerDigits,
      minimumFractionDigits: this.minimumFractionDigits,
      maximumFractionDigits: this.maximumFractionDigits,
      minimumSignificantDigits: this.minimumSignificantDigits,
      maximumSignificantDigits: this.maximumSignificantDigits
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-format-number': WebmoduleFormatNumber;
  }
}
