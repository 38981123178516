import { information } from './ui/modal-option';
import { goStaticURL } from './ui/resource-resolver';
import { getCurrentUser, UserPublicInfo } from './api/current-user';
import { tlang } from './language/lang';

export function saveCurrentUserIntoSession(info?: UserPublicInfo) {
  const user = info ?? getCurrentUser();
  sessionStorage.setItem('wm-session-user', JSON.stringify(user));
}
export function clearCurrentUserFromSession() {
  sessionStorage.removeItem('wm-session-user');
}

export function getCurrentUserForSession(): UserPublicInfo | null {
  const strUser = sessionStorage.getItem('wm-session-user');
  return strUser ? (JSON.parse(strUser) as UserPublicInfo) : null;
}

let _reloadEvent = async () => {
  //
};

export function setUserReloadEvent(event: () => Promise<void>) {
  _reloadEvent = event;
}
async function reload() {
  //if this flag exists then we have forced a logoff as part of a PAT login process, so we do not want to redirect or do anything
  if (sessionStorage.getItem('dealer-pat-login')) return;
  await _reloadEvent();
  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  goStaticURL('/login');
}

function compareUsers(a: UserPublicInfo | null, b: UserPublicInfo | null) {
  if ((!a && b) || (a && !b)) return false;
  if (!a && !b) return true;
  if (a && b) {
    if (a.id !== b.id) return false;
    if (a.tenantId !== b.tenantId) return false;
    if (a.userName !== b.userName) return false;
    return true;
  }
  return false;
}

/**
 * this function protects a session against being in an invalid state based on a user logging in as a different user at the same
 * time from another tab.
 * @returns
 * */
export async function verifySession(): Promise<boolean> {
  const user = getCurrentUser(true);
  let sessionUser = getCurrentUserForSession();
  const sessionMismatch = !compareUsers(sessionUser, user);

  if (!user && sessionUser) {
    clearCurrentUserFromSession();
    await information(tlang`${'ref:user-logged-off'}
            This session must be reloaded because you have logged off in another session.       
        `);
    await reload();
    return false;
  } else if (!sessionUser && user) {
    saveCurrentUserIntoSession();
    sessionUser = getCurrentUserForSession();
    return true;
  } else if (sessionMismatch) {
    await information(tlang`${'ref:user-switched'}
        This session must be reloaded because you have switched to a different user in another Tab.
        `);
    clearCurrentUserFromSession();
    await reload();
    return false;
  }

  return true;
}
