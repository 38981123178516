import { userDataStore } from '../../common/current-user-data-store';
import { PageBaseInternal } from '../../../../webmodule-common/other/ui/pages/page-base';

export class PageBase extends PageBaseInternal {
  protected defaultTitleTemplate = `%s | Supplier's Module`;

  async loadUserCoreDetails(): Promise<void> {
    await userDataStore.loadCoreDetails();
  }
}
