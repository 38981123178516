import { claimIdentity } from '../../../webmodule-common/other/api/user-security';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement, state } from 'lit/decorators.js';
import { DataEntryPageControlView } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { editBranch } from './franchisee-branch-editor';
import { EditFranchiseeModal } from './edit-franchisee-modal';
import { FranchiseeBranchDeploymentView } from '../data/franchisee-branch-deployment-view';
import { FranchiseeNetworkApi } from '../../api/franchisee-network-api';
import { getApiFactory } from '../../api/api-injector';
import { html, render } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import {
  MenuIconOption,
  PageButtonLocation,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { OnboardFranchiseeModal } from './onboard-franchisee-modal';
import { refreshButton } from '../../../webmodule-common/other/ui/command-action';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../supplier/common/current-user-data-store';
import { ViewDeploymentTenants } from '../../api/supplier-api-interface-franchiseenetwork';

@customElement('wm-franchiseeview')
export class FranchiseeView extends DataEntryPageControlView {
  protected franchiseeApi: FranchiseeNetworkApi = getApiFactory().franchisee();
  public async afterConstruction(): Promise<void> {
    this.pages = await this.getPagesForPageControl();

    await super.afterConstruction();
    this.pageControl.hideSingleTab = true;
    this.pageControl.pageButtonLocation = PageButtonLocation.right;
  }
  @state()
  pages?: PageManager[];
  protected async getPagesForPageControl(): Promise<PageManager[]> {
    await userDataStore.loadCoreDetails();
    const tentantList = userDataStore.allFranchiseeDetails.tenantList;
    console.log('Tenant Lists');
    console.log(tentantList);

    const listViews: PageManager[] = [];
    const deployments = tentantList.deployments ?? [];
    if (tentantList) {
      for (let i = 0; i < deployments.length; i++) {
        listViews.push(this.createPageManager(i));
      }
    }
    return listViews;
  }

  getViewByIndex(index: number): ViewDeploymentTenants {
    const tentantList = userDataStore.allFranchiseeDetails.tenantList;
    console.log('Tenant Lists');
    console.log(tentantList);
    const deployments = tentantList.deployments ?? [];
    return deployments[index];
  }

  createPageManager(index: number): PageManager {
    const eventOpenTenant = async (_e: CustomEvent<{ item: FranchiseeBranchDeploymentView }>) => {
      await this.openFranchiseeDeploymentConfiguration(_e.detail.item);
    };
    const eventOpenBranch = async (e: CustomEvent<{ item: FranchiseeBranchDeploymentView }>) => {
      if (await editBranch(e.detail.item)) await this.reloadData();
    };
    const eventLoginTenant = (e: CustomEvent<{ item: FranchiseeBranchDeploymentView }>) => {
      lockUIandExecute(async () => {
        await this.loginAsFranchisee(e.detail.item);
      });
      //
    };

    const buttonMenu = () => {
      const eventOnboardTenant = async () => {
        await this.onboardTenant(this.getViewByIndex(index));
      };

      return html`
        <button @click=${eventOnboardTenant} class="btn btn-primary" type="button">
          ${tlang`Create %%franchisee%%`}</button
        >${refreshButton(async () => await this.reloadData())}
      `;
    };

    const ui = document.createElement('div') as HTMLDivElement;
    const innerui = document.createElement('div') as HTMLDivElement;
    ui.append(innerui);
    return {
      caption: () => tlang`${this.getViewByIndex(index).name}`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => true,
      hasDelete: () => false,
      onEnter: async () => {
        //innerui.remove();
        //innerui = document.createElement('div') as HTMLDivElement;
        //ui.append(innerui);
        render(
          html`<webmodule-franchisee-network
            .viewDeploymentTenants=${this.getViewByIndex(index)}
            @wm-tenant-open=${eventOpenTenant}
            @wm-tenant-login=${eventLoginTenant}
            @wm-tenant-open-branch=${eventOpenBranch}
          ></webmodule-franchisee-network>`,
          innerui
        );
      },
      content: () => {
        return ui;
      },
      buttonMenu: () => {
        return buttonMenu();
      },
      dispose: async () => ui.remove(),
      data: this
    };
  }
  async reloadData(): Promise<void> {
    await userDataStore.reloadFranchiseeDetails();
    await this.pageControl.runOnEnterEvent();
    this.requestUpdate();
  }
  createPageControl() {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.pages ?? [];
    };
    const menu: MenuIconOption[] = [];
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: menu,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  async prepareForSave(): Promise<void> {
    //DOES NOTHING HANDLED BY CHILDREN
  }

  public internalDataChanged(): boolean {
    return false;
  }

  private async onboardTenant(deployment: ViewDeploymentTenants): Promise<void> {
    if (deployment.licensePools == null || deployment.licensePools.length == 0) {
      await information(
        tlang`Cannot Onboard %%franchisee%%`,
        tlang`There are no available license pool, please contact support`
      );
      return;
    }

    if ((deployment.licensePools?.[0].availableAllocations ?? 0) === 0) {
      await information(
        tlang`Cannot Onboard %%franchisee%%`,
        tlang`There are no available licenses to add a new %%franchisee%%`
      );
      return;
    }
    const title =
      (this.pages?.length ?? 1) > 1
        ? tlang`Onboard %%franchisee%% to ${deployment.name}`
        : tlang`Onboard %%franchisee%%`;
    const franchiseeModal = await constructAsync(
      new OnboardFranchiseeModal({
        deployment: deployment,
        modalTitle: () => title,
        saveButtonTitle: () => tlang`Onboard %%franchisee%%`,
        cancelButtonTitle: () => tlang`Cancel`
      })
    );
    await franchiseeModal.showModal();
    if (franchiseeModal.ok) {
      const customEvent = new CustomEvent('franchisee-data-changed', {
        bubbles: true,
        composed: true,
        detail: {}
      });
      this.ui.dispatchEvent(customEvent);
      await this.reloadData();
    }
  }

  protected async openFranchiseeDeploymentConfiguration(view: FranchiseeBranchDeploymentView) {
    const title = view.inUse ? tlang`Edit %%franchisee%%` : tlang`Onboard %%franchisee%%`;
    const saveLabel = view.inUse ? tlang`Save Changes` : tlang`Resubmit`;
    const franchiseeModal = new EditFranchiseeModal({
      view: view,
      modalTitle: () => title,
      saveButtonTitle: () => saveLabel,
      cancelButtonTitle: () => tlang`Cancel`
    });
    await lockUIandExecute(async () => {
      await constructAsync(franchiseeModal);
    });
    if (franchiseeModal) {
      await (franchiseeModal as EditFranchiseeModal).showModal();
      if (franchiseeModal.saved) await this.reloadData();
    }
  }

  protected async loginAsFranchisee(item: FranchiseeBranchDeploymentView) {
    const claims = {};
    //this sets the branch they will navigate to
    claims[claimIdentity.branchId] = item.branchId;
    //indicates supplier level privelages
    claims[claimIdentity.agent] = 'true';
    claims[claimIdentity.frameConfigPowerUser] = 'true';
    //allow suppliers to administer dealer staff.
    claims[claimIdentity.admin] = 'true';

    const result = await this.franchiseeApi.pat({
      preferredLifeOfTokenHours: 8,
      tenantId: item.tenantId,
      dealerDeploymentId: item.deploymentId,
      claims: claims
    });

    if (result) {
      window.open(`${item.deploymentDomain}?PAT=${result.pat}`, 'noopener=true');
      //alert(`PAT (${result.pat}) for ${tenantDeploymentInfo.uiDomain}`);
    }
  }
}
