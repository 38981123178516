import { customElement, property } from 'lit/decorators.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { html, TemplateResult } from 'lit';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { LitElementBase } from '../../../../webmodule-common/other/litelement-base';
import { tlang } from '../../../../webmodule-common/other/language/lang';

export interface DateRange {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

export interface DashboardFilterOptions {
  dateRange: DateRange | undefined;
  branchIds: string[] | undefined;
  userId: string | undefined;
  forceEmptyDisplay: boolean;
}

export interface DashboardFilterOption {
  id: string;
  name: string;
}

@customElement('wm-dashboard-filter')
export class DashboardFilter extends LitElementBase {
  @property({ type: Array }) public dates?: DateRange[];
  @property({ type: Array }) public territories?: DashboardFilterOption[];
  @property({ type: Array }) public franchisees?: DashboardFilterOption[];
  @property({ type: Array }) public branches?: DashboardFilterOption[];
  @property({ type: Array }) public users?: DashboardFilterOption[];

  @property({ type: Object }) public selectedDate?: DateRange;
  @property() public selectedTerritoryId?: string;
  @property() public selectedFranchiseeId?: string;
  @property() public selectedBranchId?: string;
  @property() public selectedUserId?: string;

  protected dateChangeEvent(e: Event) {
    const dateRangeId = (e.currentTarget as HTMLSelectElement).value;
    const dateRange = this.dates?.find(dr => dr.id == dateRangeId);
    this.dispatchCustom('date-changed', {
      date: dateRange
    });
  }

  protected territoryChangeEvent(e: Event) {
    const territoryId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('territory-changed', {
      territoryId: territoryId
    });
  }

  protected franchiseeChangeEvent(e: Event) {
    const franchiseeId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('franchisee-changed', {
      franchiseeId: franchiseeId
    });
  }

  protected branchChangeEvent(e: Event) {
    const branchId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('branch-changed', {
      branchId: branchId
    });
  }

  protected userChangeEvent(e: Event) {
    const userId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('user-changed', {
      userId: userId
    });
  }

  protected sendResetFilterEvent(_e: Event) {
    this.dispatchCustom('reset-filters', {});
  }

  protected render(): TemplateResult {
    const dateRangeTemplate = (dateRangeId: string) =>
      this.dates?.map(b => {
        return html`<option ?selected=${b.id === dateRangeId} value=${b.id}>${b.label}</option>`;
      });

    const isTerritorySelected = !isEmptyOrSpace(this.selectedTerritoryId);
    const isFranchiseeSelected = !isEmptyOrSpace(this.selectedFranchiseeId);
    const territoryTemplate = (territoryId: string) =>
      this.territories?.map(t => {
        return html`<option ?selected=${t.id === territoryId} value=${t.id}>${t.name}</option>`;
      });
    const defaultTerritoryOption = () => {
      if (this.territories && this.territories.length > 1) {
        return html`<option value=${emptyGuid} ?selected=${this.selectedTerritoryId === emptyGuid}>
          ${tlang`All !!territory!!`}
        </option>`;
      }
      if (!this.territories || this.territories.length == 0) {
        return html`<option value=${emptyGuid} ?selected=${this.selectedTerritoryId === emptyGuid}>
          ${tlang`No !!territory!! available`}
        </option>`;
      }
      return html``;
    };
    const isTerritoryDisabled = isFranchiseeSelected || !this.territories || this.territories.length == 0;

    const franchiseeTemplate = (franchiseeId: string) =>
      this.franchisees?.map(f => {
        return html`<option ?selected=${f.id === franchiseeId} value=${f.id}>${f.name}</option>`;
      });
    const allFranchiseesOption =
      this.franchisees && this.franchisees.length > 1
        ? html`<option value=${emptyGuid} ?selected=${this.selectedFranchiseeId === emptyGuid}>
            ${tlang`All !!franchisee!!`}
          </option>`
        : html``;
    const isFranchiseeDisabled = isTerritorySelected;

    const branchTemplate = (branchId: string) =>
      this.branches?.map(b => {
        return html`<option ?selected=${b.id === branchId} value=${b.id}>${b.name}</option>`;
      });
    const allBranchesOption = html`<option value=${emptyGuid} ?selected=${this.selectedBranchId === emptyGuid}>
      ${tlang`All !!branch!!`}
    </option>`;
    const isBranchDisabled = isEmptyOrSpace(this.selectedFranchiseeId) || isFranchiseeDisabled;

    const userTemplate = (userId: string) =>
      this.users?.map(u => {
        return html`<option ?selected=${u.id === userId} value=${u.id}>${u.name}</option>`;
      });
    const isUserDisabled = isEmptyOrSpace(this.selectedBranchId) || isBranchDisabled;

    return html`
      <div class="page-dashboard-filter">
        <label>Filter Data</label>
        <select @change=${this.dateChangeEvent} id="selectedDateId" title="selectedDateTitle" class="form-select">
          ${dateRangeTemplate(this.selectedDate?.id ?? emptyGuid)}
        </select>
        <select
          @change=${this.territoryChangeEvent}
          id="selectedTerritoryId"
          ?disabled=${isTerritoryDisabled}
          class="form-select"
        >
          ${defaultTerritoryOption()} ${territoryTemplate(this.selectedTerritoryId ?? emptyGuid)}
        </select>
        <select
          @change=${this.franchiseeChangeEvent}
          id="selectedFranchiseeId"
          ?disabled=${isFranchiseeDisabled}
          class="form-select"
        >
          ${allFranchiseesOption} ${franchiseeTemplate(this.selectedFranchiseeId ?? emptyGuid)}
        </select>
        <select
          @change=${this.branchChangeEvent}
          id="selectedBranchId"
          ?disabled=${isBranchDisabled}
          class="form-select"
        >
          ${allBranchesOption} ${branchTemplate(this.selectedBranchId ?? emptyGuid)}
        </select>
        <select @change=${this.userChangeEvent} id="selectedUserId" ?disabled=${isUserDisabled} class="form-select">
          <option value=${emptyGuid}>${tlang`All Employees`}</option>
          ${userTemplate(this.selectedUserId ?? emptyGuid)}
        </select>
        <button class="btn-primary filter-reset" @click=${this.sendResetFilterEvent}>
          <icon-refresh></icon-refresh>
        </button>
      </div>
    `;
  }
}
