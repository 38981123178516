import { globalState } from '../global-state.js';
import type { TemplateResult } from 'lit';
import { html } from 'lit';
import type {
  ModalPromise,
  ModalScreen,
  ModalScreenBuilder,
  ModalScreenEvents
} from '../../../interop/webmodule-interop.js';

export function setModalScreenFactory(event: ModalScreenBuilder) {
  globalState().modalScreenBuilderEvent = event;
}
export function modalScreenFactory(event: ModalScreenEvents): ModalScreen {
  const _event = globalState().modalScreenBuilderEvent as ModalScreenBuilder;
  if (_event) return _event(event);
  throw new Error('setModalScreenFactory not called');
}

export class BaseModal implements ModalScreenEvents {
  canClose(): Promise<boolean> {
    return Promise.resolve(true);
  }
  footerTemplate(): TemplateResult | null | undefined {
    return undefined;
  }
  get isFooterVisible(): boolean {
    return false;
  }
  modal = modalScreenFactory(this);

  async showModal() {
    await this.modal.showModal();
  }
  async hideModal() {
    await this.modal.hideModal();
  }
  show(): Promise<ModalPromise> {
    return this.modal.show();
  }
  title(): Promise<string | TemplateResult> {
    return Promise.resolve('Dialog');
  }
  bodyTemplate(): Promise<TemplateResult> {
    return Promise.resolve(html``);
  }

  async render() {
    await this.modal.render();
  }

  get ui(): HTMLDivElement {
    return this.modal.ui;
  }
}
