import './quote-support-inbox-view';
import {
  commandActionButton,
  delayedEvent,
  RefreshCommandAction
} from '../../../webmodule-common/other/ui/command-action';
import { customElement, query, state } from 'lit/decorators.js';
import { html, TemplateResult } from 'lit';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import {
  SupportInboxItemStatus,
  SupportInboxItemType,
  SupportInboxTicketOwnerFilter,
  ViewSupportInboxItemEx
} from '../../api/supplier-api-interface-franchiseenetwork';
import { TimedTrigger } from '../../../webmodule-common/other/timed-trigger';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { WebModuleQuoteSupportInboxView } from './quote-support-inbox-view';

export interface QuoteSupportInboxViewOptions {
  tabName: string;
  caption: Snippet;
  typeFilter: SupportInboxItemType;
  statusFilter: SupportInboxItemStatus;
  eventLogin: (item: ViewSupportInboxItemEx) => Promise<void>;
  showResolvedColumns?: boolean;
  showLastModified?: boolean;
  showAssignedTo?: boolean;
}

@customElement('wm-quotesupportinboxviewtab')
export class QuoteSupportInboxViewTab extends PageControlTabWithIndependantSaving {
  options: QuoteSupportInboxViewOptions;
  timedTrigger?: TimedTrigger;
  @query('.inbox-view')
  public inboxView: WebModuleQuoteSupportInboxView;
  private refreshAction = new RefreshCommandAction(delayedEvent(() => this.refreshTableData(), 2000));

  constructor(options: QuoteSupportInboxViewOptions) {
    super();
    this.options = options;
  }

  @state()
  private _ticketOwner: SupportInboxTicketOwnerFilter = SupportInboxTicketOwnerFilter.All;

  public get ticketOwner(): SupportInboxTicketOwnerFilter {
    return this._ticketOwner;
  }

  public set ticketOwner(value: SupportInboxTicketOwnerFilter) {
    if (this._ticketOwner !== value) {
      this._ticketOwner = value;
    }
  }

  @state()
  private _filter = '';

  public get filter() {
    return this._filter;
  }

  public set filter(value) {
    if (this._filter !== value) {
      this._filter = value;
    }
  }

  public async afterConstruction(): Promise<void> {
    super.afterConstruction();
    const triggerEvent = e => {
      const searchTerm = (e.target as HTMLInputElement).value;

      this.filter = searchTerm;
    };
    this.timedTrigger = new TimedTrigger(1500, triggerEvent);
  }

  public filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger?.getResetEvent();
    const triggerEvent = this.timedTrigger?.getTriggerEarlyEvent();

    return html`
      <label for="filterText">${tlang`Filter:`}</label>
      <input
        type="text"
        id="filterText"
        placeholder="Text Search"
        @oninput=${resetEvent}
        @blur=${triggerEvent}
        @keyup=${resetEvent}
      />
    `;
  }

  public async allowPageSwitch(): Promise<boolean> {
    return true;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  async onEnter(): Promise<void> {
    await this.refreshTableData();
  }

  buttonMenu() {
    return html` ${commandActionButton(this.refreshAction)}`;
  }

  protected getCaption(): Snippet {
    return this.options.caption;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const checkTemplate = (label: string, value: SupportInboxTicketOwnerFilter) => {
      const eventClick = e => {
        e.stopImmediatePropagation();
        this.ticketOwner = value;
      };
      if (value === this.ticketOwner)
        return html`<span @click=${eventClick} class="ticket-owner-check">
          <i class="fa fa-solid fa-circle-dot"></i>
          ${label}
        </span>`;
      else
        return html`<span @click=${eventClick} class="ticket-owner-check">
          <i class="fa fa-regular fa-circle"></i>
          ${label}
        </span>`;
    };
    const eventQuoteReview = async (e: CustomEvent<{ item: ViewSupportInboxItemEx }>) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      this.options.eventLogin(e.detail.item);
    };
    return html` <div class="support-inbox-header">
        <div class="filter-left">
          ${checkTemplate(tlang`All Tickets`, SupportInboxTicketOwnerFilter.All)}
          ${checkTemplate(tlang`My Tickets`, SupportInboxTicketOwnerFilter.Mine)}
          ${checkTemplate(tlang`Unassigned Tickets`, SupportInboxTicketOwnerFilter.Unassigned)}
        </div>
        <div class="filter-right">
          <div class="filter-text">${this.filterTemplate()}</div>
        </div>
      </div>

      <wm-webmodulequotesupportinboxview
        class="inbox-view"
        .ticketOwnerFilter=${this.ticketOwner}
        .typeFilter=${this.options.typeFilter}
        .statusFilter=${this.options.statusFilter}
        .filter=${this.filter}
        .showAssignedTo=${this.options.showAssignedTo}
        .showLastModified=${this.options.showLastModified}
        .showResolvedColumns=${this.options.showResolvedColumns}
        @wm-supportinbox-quotereview=${eventQuoteReview}
      ></wm-webmodulequotesupportinboxview>`;
  }

  private async refreshTableData() {
    if (this.inboxView) this.inboxView.refreshData();
  }
}
