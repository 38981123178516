import { ModalDialogOld } from './modal-base-original';
import { ModalScreen, ModalScreenEvents, PromiseSnippet, PromiseTemplate } from '../../interop/webmodule-interop';
import { TemplateResult } from 'lit';

export type ModalScreenBuilder = (event: ModalScreenEvents) => ModalScreen;
export function modalScreenFactoryImpl(event: ModalScreenEvents): ModalScreen {
  return new ModalScreenImpl(event);
}

class ModalScreenImpl extends ModalDialogOld implements ModalScreen {
  events: ModalScreenEvents;
  constructor(events: ModalScreenEvents) {
    super();
    this.events = events;
  }
  get hiding(): boolean {
    return this._hiding;
  }
  protected ZIndex(): number | undefined {
    return this.events.zIndex;
  }
  private asString(val) {
    if (typeof val == 'function') return val();
    return val;
  }
  protected get modalSize() {
    let size = this.asString(this.events.modalSize);
    if (!size) size = super.modalSize;
    return size;
  }
  protected get modalClasses(): string {
    return this.events.modalClasses ?? super.modalClasses;
  }
  protected async getTitle(): PromiseSnippet {
    return await this.events.title();
  }
  protected async onShowModal(): Promise<void> {
    await this.events.onShowModal?.();
  }
  async canClose(): Promise<boolean> {
    return (await this.events.canClose?.()) ?? super.canClose();
  }
  async bodyTemplate(): PromiseTemplate {
    return await this.events.bodyTemplate();
  }
  protected renderFooterTemplate(): boolean {
    return this.events.isFooterVisible;
  }
  protected footerTemplate(): TemplateResult | null {
    return this.events.footerTemplate?.() ?? null;
  }
}
