import { marked } from 'marked';
import { tlang } from '../language/lang.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export function asMarkdown(data: string | string[] | undefined | null, trimBlanks = true): string {
  if (!data) return '';

  if (trimBlanks) {
    const newdata = typeof data === 'string' ? data.split('\n') : data;
    data = newdata.map(line => line.trimStart()).join('\n');
  } else {
    data = typeof data === 'string' ? data : data.join('\n');
  }
  return marked.parse(data, { async: false });
}

export function asMarkdownTemplate(data: string | string[] | undefined | null, trimBlanks = true) {
  return unsafeHTML(asMarkdown(data, trimBlanks));
}

export function stringsAsMarkdownList(data: string[] | null | undefined) {
  return (data ?? []).map(x => `+ ${x}`).join('\n');
}

export function tlangMarkdown(template: TemplateStringsArray, ...args: any[]) {
  const data = tlang(template, ...args);
  return asMarkdownTemplate(data);
}
