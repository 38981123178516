import { html, TemplateResult } from 'lit';
import { asMarkdownTemplate } from './general/markdown';

export interface IErrorList {
  errors: string[];
  assert: (value: boolean, message: string) => void;
  hasErrors: boolean;
  asMarkdown: () => TemplateResult;
}

class ErrorList implements IErrorList {
  errors: string[] = [];

  constructor(errors: string[] = []) {
    this.errors = errors;
  }

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  asMarkdown() {
    return html`${asMarkdownTemplate(this.errors, false)}`;
  }

  public assert(value: boolean, message: string): void {
    if (!value) {
      this.errors.push(message);
    }
  }
}

export function errorList(existingErrors?: string[]): IErrorList {
  return new ErrorList(existingErrors);
}
