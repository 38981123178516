import { animateTo, getAnimation, setDefaultAnimation, stopAnimations } from '../../common/animation-common.js';
import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { lockBodyScrolling, unlockBodyScrolling } from '../../common/scroll.js';
import { monitor } from '../../common/monitor.js';
import { property, query } from 'lit/decorators.js';
import { waitForEvent } from '../../events/events.js';
import componentStyles from '../../styles/component.styles.js';
import ModalHelper from '../../common/helpers/modal-helper.js';
import styles from './modal.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import WebmoduleIconButton from '../icon-button/icon-button.js';
import type { CSSResultGroup } from 'lit';

setDefaultAnimation('modal.show', {
  keyframes: [
    { opacity: 0, scale: 0.8 },
    { opacity: 1, scale: 1 }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('modal.hide', {
  keyframes: [
    { opacity: 1, scale: 1 },
    { opacity: 0, scale: 0.8 }
  ],
  options: { duration: 250, easing: 'ease' }
});

setDefaultAnimation('modal.denyClose', {
  keyframes: [{ scale: 1 }, { scale: 1.02 }, { scale: 1 }],
  options: { duration: 250 }
});

setDefaultAnimation('modal.overlay.show', {
  keyframes: [{ opacity: 0 }, { opacity: 1 }],
  options: { duration: 250 }
});

setDefaultAnimation('modal.overlay.hide', {
  keyframes: [{ opacity: 1 }, { opacity: 0 }],
  options: { duration: 250 }
});

/**
 * @summary Modals are rendered above the page and require user interaction.
 *
 * @dependency webmodule-icon-button
 *
 * @slot - The modal's main content.
 * @slot label - The modal's label. Alternatively, you can use the `label` attribute.
 * @slot header-actions - Optional actions to add to the header. Works best with `<webmodule-icon-button>`.
 * @slot footer - The modal's footer, usually one or more buttons representing various options.
 *
 * @event webmodule-show - Emitted when the modal opens.
 * @event webmodule-after-show - Emitted after the modal opens and all animations are complete.
 * @event webmodule-hide - Emitted when the modal closes.
 * @event webmodule-after-hide - Emitted after the modal closes and all animations are complete.
 * @event webmodule-initial-focus - Emitted when the modal opens and is ready to receive focus. Calling
 *   `event.preventDefault()` will prevent focusing and allow you to set it manually.
 * @event {{ source: 'close-button' | 'keyboard' | 'overlay' }} webmodule-request-close - Emitted when the user attempts to
 *   close the modal by clicking the close button, clicking the overlay, or pressing escape. Calling
 *   `event.preventDefault()` will keep the modal open.
 *
 * @csspart base - The component's base wrapper.
 * @csspart overlay - The overlay that covers the screen behind the modal.
 * @csspart panel - The modal's panel (where the modal and its content are rendered).
 * @csspart header - The modal's header. This element wraps the title and header actions.
 * @csspart header-actions - Optional actions to add to the header. Works best with `<webmodule-icon-button>`.
 * @csspart title - The modal's title.
 * @csspart close-button - The close button, an `<webmodule-icon-button>`.
 * @csspart close-button__base - The close button's exported `base` part.
 * @csspart body - The modal's body.
 * @csspart footer - The modal's footer.
 *
 * @cssproperty --width - The preferred width of the modal. Note that the modal will shrink to accommodate smaller screens.
 * @cssproperty --header-spacing - The amount of padding to use for the header.
 * @cssproperty --body-spacing - The amount of padding to use for the body.
 * @cssproperty --footer-spacing - The amount of padding to use for the footer.
 *
 * @animation modal.show - The animation to use when showing the modal.
 * @animation modal.hide - The animation to use when hiding the modal.
 * @animation modal.denyClose - The animation to use when a request to close the modal is denied.
 * @animation modal.overlay.show - The animation to use when showing the modal's overlay.
 * @animation modal.overlay.hide - The animation to use when hiding the modal's overlay.
 *
 * @property modalHelper - Exposes the internal modal utility.
 *
 * @tag webmodule-modal
 */
export default class WebmoduleModal extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  static dependencies = {
    'webmodule-icon-button': WebmoduleIconButton
  };
  public modalHelper = new ModalHelper(this);
  @query('.modal') modal: HTMLElement;
  @query('.modal__panel') panel: HTMLElement;
  @query('.modal__overlay') overlay: HTMLElement;
  /**
   * Indicates whether the modal is open. You can toggle this attribute to show and hide the modal, or you can
   * use the `show()` and `hide()` methods and this attribute will reflect the modal's open state.
   */
  @property({ type: Boolean, reflect: true }) open = false;
  /**
   * The modal's label as displayed in the header. If you need to display HTML, use the `label` slot instead.
   */
  @property({ reflect: true }) label = '';
  /**
   * Disables the header. This will also remove the default close button, so please ensure you provide a way for users
   * to dismiss the modal.
   */
  @property({ attribute: 'no-header', type: Boolean, reflect: true }) noHeader = false;
  /**
   * The modal's size to render. Defaults to medium.
   */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  private readonly hasSlotController = new HasSlotController(this, 'footer');
  private originalTrigger: HTMLElement | null;
  private closeWatcher: CloseWatcher | null;

  firstUpdated() {
    this.modal.hidden = !this.open;

    if (this.open) {
      this.addOpenListeners();
      this.modalHelper.activate();
      lockBodyScrolling(this);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.modalHelper.deactivate();
    unlockBodyScrolling(this);
    this.closeWatcher?.destroy();
  }

  @monitor('open', { delayMonitorUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      // Show
      this.emit('webmodule-show');
      this.addOpenListeners();
      this.originalTrigger = document.activeElement as HTMLElement;
      this.modalHelper.activate();

      lockBodyScrolling(this);

      const autoFocusTarget = this.querySelector('[autofocus]');
      if (autoFocusTarget) {
        autoFocusTarget.removeAttribute('autofocus');
      }

      await Promise.all([stopAnimations(this.modal), stopAnimations(this.overlay)]);
      this.modal.hidden = false;

      // Set initial focus
      requestAnimationFrame(() => {
        const initialFocus = this.emit('webmodule-initial-focus', { cancelable: true });

        if (!initialFocus.defaultPrevented) {
          // Set focus to the autofocus target and restore the attribute
          if (autoFocusTarget) {
            (autoFocusTarget as HTMLInputElement).focus({ preventScroll: true });
          } else {
            this.panel.focus({ preventScroll: true });
          }
        }

        // Restore the autofocus attribute
        if (autoFocusTarget) {
          autoFocusTarget.setAttribute('autofocus', '');
        }
      });

      const panelAnimation = getAnimation(this, 'dialog.show');
      const overlayAnimation = getAnimation(this, 'dialog.overlay.show');
      await Promise.all([
        animateTo(this.panel, panelAnimation.keyframes, panelAnimation.options),
        animateTo(this.overlay, overlayAnimation.keyframes, overlayAnimation.options)
      ]);

      this.emit('webmodule-after-show');
    } else {
      // Hide
      this.emit('webmodule-hide');
      this.removeOpenListeners();
      this.modalHelper.deactivate();

      await Promise.all([stopAnimations(this.modal), stopAnimations(this.overlay)]);
      const panelAnimation = getAnimation(this, 'modal.hide');
      const overlayAnimation = getAnimation(this, 'modal.overlay.hide');

      await Promise.all([
        animateTo(this.overlay, overlayAnimation.keyframes, overlayAnimation.options).then(() => {
          this.overlay.hidden = true;
        }),
        animateTo(this.panel, panelAnimation.keyframes, panelAnimation.options).then(() => {
          this.panel.hidden = true;
        })
      ]);

      this.modal.hidden = true;

      this.overlay.hidden = false;
      this.panel.hidden = false;

      unlockBodyScrolling(this);

      // Restore focus to the original trigger
      const trigger = this.originalTrigger;
      if (typeof trigger?.focus === 'function') {
        setTimeout(() => trigger.focus());
      }

      this.emit('webmodule-after-hide');
    }
  }

  async show() {
    if (this.open) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'webmodule-after-show');
  }

  async hide() {
    if (!this.open) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'webmodule-after-hide');
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          modal: true,
          'modal--open': this.open,
          'modal--small': this.size === 'small',
          'modal--medium': this.size === 'medium',
          'modal--large': this.size === 'large',
          'modal--has-footer': this.hasSlotController.checkFor('footer')
        })}
      >
        <div part="overlay" class="modal__overlay" @click=${() => this.requestClose('overlay')} tabindex="-1"></div>

        <div
          part="panel"
          class="modal__panel"
          role="dialog"
          aria-modal="true"
          aria-hidden=${this.open ? 'false' : 'true'}
          aria-label=${ifDefined(this.noHeader ? this.label : undefined)}
          aria-labelledby=${ifDefined(!this.noHeader ? 'title' : undefined)}
          tabindex="-1"
        >
          ${!this.noHeader
            ? html`
                <header part="header" class="modal__header">
                  <h2 part="title" class="modal__title" id="title">
                    <slot name="label">
                      ${
                        this.label.length > 0
                          ? this.label
                          : String.fromCharCode(65279) /*ZERO WIDTH NO-BREAK SPACE char*/
                      }
                    </slot>
                  </h2>
                  <div part="header-actions" class="modal__header-actions">
                    <slot name="header-actions"></slot>
                    <webmodule-icon-button
                      part="close-button"
                      exportparts="base:close-button__base"
                      class="modal__close"
                      name="x-lg"
                      label="close"
                      library="default"
                      @click="${() => this.requestClose('close-button')}"
                    ></webmodule-icon-button>
                  </div>
                </header>
              `
            : ''}

          <div part="body" class="modal__body" tabindex="-1">
            <slot></slot>
          </div>

          <footer part="footer" class="modal__footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    `;
  }

  private requestClose(source: 'close-button' | 'keyboard' | 'overlay') {
    const requestClose = this.emit('webmodule-request-close', {
      cancelable: true,
      detail: { source }
    });

    if (requestClose.defaultPrevented) {
      const animation = getAnimation(this, 'modal.denyClose');
      animateTo(this.panel, animation.keyframes, animation.options);
      return;
    }

    this.hide();
  }

  private addOpenListeners() {
    if ('CloseWatcher' in window) {
      this.closeWatcher?.destroy();
      this.closeWatcher = new CloseWatcher();
      this.closeWatcher.onclose = () => this.requestClose('keyboard');
    } else {
      document.addEventListener('keydown', this.handleDocumentKeyDown);
    }
  }

  private removeOpenListeners() {
    this.closeWatcher?.destroy();
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  }

  private handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.modalHelper.isActive() && this.open) {
      event.stopPropagation();
      this.requestClose('keyboard');
    }
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-modal': WebmoduleModal;
  }
}
