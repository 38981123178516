import { ApiCommunications, ApiProvider } from '../../interop/webmodule-interop';
import { AuthenticationOptions } from '../ui/loginscreen/authenticate-modal';
import { getCurrentUser } from './current-user';

//Provide a global abstraction from the implementation details of an api call

let _apiInjector: ApiProvider = () => {
  throw new Error('please call setApiInjector');
};

export function setApiInjector(apiInjector: ApiProvider) {
  _apiInjector = apiInjector;
}
export function getApiInjectorEvent(): ApiProvider {
  return _apiInjector;
}

export function getApi(): ApiCommunications {
  return _apiInjector();
}

export function authenticationOptions(): AuthenticationOptions {
  return {
    api: getApi(),
    apiHost: globalThis.dealerConfiguration.apiHost,
    licenseServerHost: globalThis.dealerConfiguration.licenseServerHost,
    branding: globalThis.dealerConfiguration.branding,
    deploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
    user: getCurrentUser()
  };
}
