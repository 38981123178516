import { classMap } from 'lit/directives/class-map.js';
import { html, literal } from 'lit/static-html.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './icon-button.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 *
 * @event webmodule-blur - Emitted when the icon button loses focus.
 * @event webmodule-focus - Emitted when the icon button gains focus.
 *
 * @csspart base - The component's base wrapper.
 *
 * @tag webmodule-icon-button
 */
export default class WebmoduleIconButton extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @query('.icon-button') button: HTMLButtonElement | HTMLLinkElement;

  @state() private hasFocus = false;

  @property() name?: string;

  @property() library?: string;

  @property() src?: string;

  @property() href?: string;

  @property() target?: '_blank' | '_parent' | '_self' | '_top';

  @property() download?: string;

  @property() label = '';

  @property({ type: Boolean, reflect: true }) disabled = false;

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }

  private handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  click() {
    this.button.click();
  }

  focus(options?: FocusOptions) {
    this.button.focus(options);
  }

  blur() {
    this.button.blur();
  }

  render() {
    const isLink = this.href ? true : false;
    const tag = isLink ? literal`a` : literal`button`;

    /* eslint-disable lit/binding-positions, lit/no-invalid-html */
    return html`
      <${tag}
        part="base"
        class=${classMap({
          'icon-button': true,
          'icon-button--disabled': !isLink && this.disabled,
          'icon-button--focused': this.hasFocus
        })}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        type=${ifDefined(isLink ? undefined : 'button')}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}
        download=${ifDefined(isLink ? this.download : undefined)}
        rel=${ifDefined(isLink && this.target ? 'noreferrer noopener' : undefined)}
        role=${ifDefined(isLink ? undefined : 'button')}
        tabindex=${this.disabled ? '-1' : '0'}
        @blur=${this.handleBlur}
        @focus=${this.handleFocus}
        @click=${this.handleClick}
      >
        <webmodule-icon
          class="icon-button__icon"
          name=${ifDefined(this.name)}
          library=${ifDefined(this.library)}
          src=${ifDefined(this.src)}
        ></webmodule-icon>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-icon-button': WebmoduleIconButton;
  }
}
