import { isEmptyOrSpace } from './ui/string-helper-functions';

export enum DealerDeploymentOperationType {
  Production = 0,
  Test = 1,
  Dev = 2
}

interface ResultBrandInfo {
  loginImageUrl: string;
  loginBrandLogo: string;
  footerBrandLogo: string;
  operationType: DealerDeploymentOperationType;
}

export function brandInfo(): ResultBrandInfo {
  return globalThis.dealerConfiguration.branding as ResultBrandInfo;
}

export function isTestSite(): boolean {
  const mocking = globalThis.dealerConfiguration.theme;
  if (mocking) return mocking === 'TEST';
  return (brandInfo().operationType ?? DealerDeploymentOperationType.Production) === DealerDeploymentOperationType.Test;
}

export function isDevSite(): boolean {
  const mocking = globalThis.dealerConfiguration.theme;
  if (mocking) return mocking === 'DEV';
  return (brandInfo().operationType ?? DealerDeploymentOperationType.Production) === DealerDeploymentOperationType.Dev;
}

export function loginImageUrl(): string {
  return brandInfo().loginImageUrl;
}

export function loginBrandLogoUrl(): string {
  return brandInfo().loginBrandLogo;
}

export function footerBrandLogoUrl(): string {
  return brandInfo().footerBrandLogo;
}

export function injectThemeClasses() {
  const allThemes = ['webmodule-theme-gray', 'webmodule-theme-dark', 'webmodule-theme-light', 'webmodule-theme-dev'];
  const allAppThemes = ['test-only-ui', 'dev-only-ui', 'prod-only-ui'];

  function setTheme(name: string, appName: string) {
    document.body.classList.remove(...allThemes.filter(x => x !== name), ...allAppThemes.filter(x => x !== appName));
    if (!isEmptyOrSpace(name)) document.body.classList.add(name);
    if (!isEmptyOrSpace(appName)) document.body.classList.add(appName);
  }

  if (isTestSite()) {
    setTheme('webmodule-theme-gray', 'test-only-ui');
  } else if (isDevSite()) {
    setTheme('webmodule-theme-dev', 'dev-only-ui');
  } else {
    setTheme('', '');
  }

  globalThis.dealerConfiguration.litApp.updateLogoTemplate();
  globalThis.dealerConfiguration.litApp.requestUpdate();
}

globalThis.dealerMockDevSite = () => {
  globalThis.dealerConfiguration.theme = 'DEV';
  injectThemeClasses();
};

globalThis.dealerMockTestSite = () => {
  globalThis.dealerConfiguration.theme = 'TEST';
  injectThemeClasses();
};
globalThis.dealerMockProdSite = () => {
  globalThis.dealerConfiguration.theme = 'PROD';
  injectThemeClasses();
};
globalThis.dealerClearMockSite = () => {
  globalThis.dealerConfiguration.theme = undefined;
  injectThemeClasses();
};

globalThis.dealerApiServerBuildNumber = () => {
  globalThis.dealerConfiguration.apiBuildNumber ?? 0;
};

export function getDealerApiServerBuildNumber(): number {
  return globalThis.dealerConfiguration.apiBuildNumber ?? 0;
}
