import { EventSnippet, Snippet } from '../../interop/webmodule-interop';
import { html } from 'lit';
import { LitBaseModal } from './modal/modal-factory-lit';

export function waitPatiently(title: EventSnippet, message: EventSnippet) {
  const modal = new WaitPatiently();
  modal.eventTitle = title;
  modal.message = message;
  modal.delayedShow();
  return modal;
}

export class WaitPatiently extends LitBaseModal {
  private _eventTitle?: EventSnippet | undefined;
  public get eventTitle(): EventSnippet | undefined {
    return this._eventTitle;
  }
  public set eventTitle(value: EventSnippet | undefined) {
    this._eventTitle = value;
    this.requestUpdate();
  }

  private _message?: EventSnippet | undefined;
  public get message(): EventSnippet | undefined {
    return this._message;
  }
  public set message(value: EventSnippet | undefined) {
    this._message = value;
    this.requestUpdate();
  }

  public delayedShow() {
    // we dont want to display things that are fast and flicker.. so wait before showing a dialog.
    setTimeout(() => {
      if (!this.modal.hiding) this.showModal();
    }, 400);
  }
  public async hideModal(): Promise<void> {
    try {
      await super.hideModal();
    } catch {
      //ignore errors here. we might not have a modal to close
    }
  }
  protected get modalSize() {
    return 'modal-md';
  }

  title(): Snippet {
    return this.eventTitle?.() ?? '';
  }

  public bodyTemplate() {
    return html`
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height:3rem;">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="alert alert-light text-center" role="alert">${this.message?.() ?? ''}</div>
    `;
  }
}
