import { html } from 'lit';
import { LocalizeController } from '../../common/localize-controller.js';
import { property, state } from 'lit/decorators.js';
import WebModuleElement from '../../common/webmodule-element.js';

interface UnitConfig {
  max: number;
  value: number;
  unit: Intl.RelativeTimeFormatUnit;
}

const availableUnits: UnitConfig[] = [
  { max: 3540000, value: 60000, unit: 'minute' }, // max 59 minutes
  { max: 82800000, value: 3600000, unit: 'hour' }, // max 23 hours
  { max: 518400000, value: 86400000, unit: 'day' }, // max 6 days
  { max: 2419200000, value: 604800000, unit: 'week' }, // max 28 days
  { max: 28512000000, value: 2592000000, unit: 'month' }, // max 11 months
  { max: Infinity, value: 31536000000, unit: 'year' }
];

function getTimeUntilNextUnit(unit: 'second' | 'minute' | 'hour' | 'day') {
  const units = { second: 1000, minute: 60000, hour: 3600000, day: 86400000 };
  const value = units[unit];
  return value - (Date.now() % value);
}

/**
 * @tag webmodule-format-relative-time
 */
export default class WebmoduleFormatRelativeTime extends WebModuleElement {
  @property() lang: string;
  @property() date: Date | string = new Date();
  @property() format: 'long' | 'short' | 'narrow' = 'long';
  @property() numeric: 'always' | 'auto' = 'auto';
  @property({ type: Boolean }) sync = false;
  @state() private isoTime = '';
  @state() private relativeTime = '';
  private readonly localize = new LocalizeController(this);
  private updateTimeout: number;

  disconnectedCallback() {
    super.disconnectedCallback();
    clearTimeout(this.updateTimeout);
  }

  render() {
    const now = new Date();
    const then = new Date(this.date);

    // Check for an invalid date
    if (isNaN(then.getMilliseconds())) {
      this.relativeTime = '';
      this.isoTime = '';
      return '';
    }

    const diff = then.getTime() - now.getTime();
    const { unit, value } = availableUnits.find(singleUnit => Math.abs(diff) < singleUnit.max)!;

    this.isoTime = then.toISOString();

    this.relativeTime = this.localize.relativeTime(Math.round(diff / value), unit, {
      numeric: this.numeric,
      style: this.format
    });

    // If sync is enabled, update as time passes
    clearTimeout(this.updateTimeout);

    if (this.sync) {
      let nextInterval: number;

      if (unit === 'minute') {
        nextInterval = getTimeUntilNextUnit('second');
      } else if (unit === 'hour') {
        nextInterval = getTimeUntilNextUnit('minute');
      } else if (unit === 'day') {
        nextInterval = getTimeUntilNextUnit('hour');
      } else {
        nextInterval = getTimeUntilNextUnit('day');
      }

      this.updateTimeout = window.setTimeout(() => this.requestUpdate(), nextInterval);
    }

    return html` <time datetime=${this.isoTime} title=${this.relativeTime}>${this.relativeTime}</time> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-format-relative-time': WebmoduleFormatRelativeTime;
  }
}
