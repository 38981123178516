import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './radio.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Select a single option from a group.
 *
 * @slot - The radio's label.
 *
 * @event webmodule-blur - Emitted when the control loses focus.
 * @event webmodule-focus - Emitted when the control gains focus.
 *
 * @csspart base - The component's base wrapper.
 * @csspart control - The circular container that wraps the radio's checked state.
 * @csspart control--checked - The radio control when the radio is checked.
 * @csspart checked-icon - The checked icon, an `<webmodule-icon>` element.
 * @csspart label - The container that wraps the radio's label.
 *
 * @tag webmodule-radio
 */
export default class WebmoduleRadio extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  @state() checked = false;
  /** The radio's value. When selected, the radio group will receive this value. */
  @property() value: string;
  /**
   * The radio's size. When used inside a radio group, the size will be determined by the radio group's size.
   */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the radio. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  @state() protected hasFocus = false;

  constructor() {
    super();
    this.addEventListener('blur', this.handleBlur);
    this.addEventListener('click', this.handleClick);
    this.addEventListener('focus', this.handleFocus);
  }

  connectedCallback() {
    super.connectedCallback();
    this.setInitialAttributes();
  }

  @monitor('checked')
  handleCheckedChange() {
    this.setAttribute('aria-checked', this.checked ? 'true' : 'false');
    this.setAttribute('tabindex', this.checked ? '0' : '-1');
  }

  @monitor('disabled', { delayMonitorUntilFirstUpdate: true })
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  render() {
    return html`
      <span
        part="base"
        class=${classMap({
          radio: true,
          'radio--checked': this.checked,
          'radio--disabled': this.disabled,
          'radio--focused': this.hasFocus,
          'radio--small': this.size === 'small',
          'radio--medium': this.size === 'medium',
          'radio--large': this.size === 'large'
        })}
      >
        <span part="${`control${this.checked ? ' control--checked' : ''}`}" class="radio__control">
          ${when(
            this.checked,
            () => html`
              <webmodule-icon
                part="checked-icon"
                class="radio__checked-icon"
                library="default"
                name="radio"
              ></webmodule-icon>
            `,
            () => ''
          )}
        </span>

        <slot part="label" class="radio__label"></slot>
      </span>
    `;
  }

  private handleBlur = () => {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  };

  private handleClick = () => {
    if (!this.disabled) {
      this.checked = true;
    }
  };

  private handleFocus = () => {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  };

  private setInitialAttributes() {
    this.setAttribute('role', 'radio');
    this.setAttribute('tabindex', '-1');
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-radio': WebmoduleRadio;
  }
}
