import { emptyAddress } from '../../../webmodule-common/other/ui/maps/map-helpers';
import { emptyGuid } from '../../../webmodule-common/other/api/guid';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from './current-user-data-store';

export interface DeploymentLicensePoolData {
  dealerDeploymentId: number;
  licensePoolData: LicensePoolData;
}
export interface LicensePoolData {
  totalLicenses: number;
  licensesAllocatedToDealer: number;
  licensesUsedByDealer: number;
}

export async function getLicenseData(deploymentId?: number): Promise<LicensePoolData> {
  const data: LicensePoolData = {
    totalLicenses: 0,
    licensesAllocatedToDealer: 0,
    licensesUsedByDealer: 0
  };

  await userDataStore.loadCoreDetails();

  const tentantList = userDataStore.allFranchiseeDetails.tenantList;
  const deployments =
    deploymentId === undefined
      ? (tentantList.deployments ?? [])
      : (tentantList.deployments?.filter(x => x.dealerDeploymentId === deploymentId) ?? []);

  deployments.forEach(deployment => {
    deployment.licensePools?.forEach(licensePool => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const licenseCount = licensePool.licensePool!.concurrentUsers;
      data.totalLicenses += licenseCount;
      data.licensesAllocatedToDealer += licenseCount - licensePool.availableAllocations;
      data.licensesUsedByDealer += licenseCount - licensePool.availableUsers;
    });
  });
  return data;
}

export async function getLicenseDataByBranch(deploymentId?: number, branchIds?: string[]): Promise<LicensePoolData> {
  const data: LicensePoolData = {
    totalLicenses: 0,
    licensesAllocatedToDealer: 0,
    licensesUsedByDealer: 0
  };

  await userDataStore.loadCoreDetails();
  const cacheData = userDataStore.allFranchiseeDetails;

  const tentantList = cacheData.tenantList;
  const deployments =
    deploymentId === undefined
      ? (tentantList.deployments ?? [])
      : (tentantList.deployments?.filter(x => x.dealerDeploymentId === deploymentId) ?? []);

  deployments.forEach(deployment => {
    deployment.tenants?.forEach(tenantInfo => {
      const franchiseeDeploymentConfiguration = cacheData.franchisees.find(
        x => x.franchiseeTenantId == tenantInfo.tenantId && x.dealerDeploymentId == deployment.dealerDeploymentId
      );

      const branches =
        branchIds && branchIds.length > 0
          ? cacheData.branches.filter(b => branchIds.includes(b.branchId))
          : cacheData.branches.filter(
              b =>
                b.dealerDeploymentId == franchiseeDeploymentConfiguration?.dealerDeploymentId &&
                b.franchiseeTenantId == franchiseeDeploymentConfiguration?.franchiseeTenantId
            );

      if (branches.length === 0) {
        branches.push({
          abbreviation: tlang``,
          branchId: emptyGuid,
          id: emptyGuid,
          dealerDeploymentId: deployment.dealerDeploymentId,
          franchiseeTenantId: tenantInfo.tenantId,
          name: 'Unavailable',
          billingAddress: null,
          dateCreated: '',
          physicalAddress: emptyAddress(),
          physicalAddressAsDefaultShipping: true,
          recordVersion: '',
          taxRegistrationNumber: ''
        });
      }

      branches.forEach(branch => {
        if (
          deployment.dealerDeploymentId == branch.dealerDeploymentId &&
          tenantInfo.tenantId == branch.franchiseeTenantId
        ) {
          if (tenantInfo.licensePoolAllocated != -1 && tenantInfo.licensePoolAllocations != -1) {
            data.licensesAllocatedToDealer += tenantInfo.licensePoolAllocations;
            data.licensesUsedByDealer += tenantInfo.licensePoolAllocated;
          }
        }
      });
    });

    deployment.licensePools?.forEach(licensePool => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const licenseCount = licensePool.licensePool!.concurrentUsers;
      data.totalLicenses += licenseCount;
    });
  });
  return data;
}
