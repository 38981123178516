import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import componentStyles from '../../styles/component.styles.js';
import styles from './card.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Container to group elements.
 *
 * @slot - Card's body.
 * @slot header - An optional header.
 * @slot footer - An optional footer.
 * @slot image - An optional image.
 *
 * @csspart base
 * @csspart image - Image container.
 * @csspart header - header container
 * @csspart body - Body container
 * @csspart footer - Footer container
 *
 * @cssproperty --border-color - Color of the border
 * @cssproperty --border-radius - Card's corner radius
 * @cssproperty --border-width - Card's border with
 * @cssproperty --padding - The padding to use for the card's sections (slots).
 *
 * @tag webmodule-card
 */
export default class WebmoduleCard extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];

  private readonly hasSlot = new HasSlotController(this, 'footer', 'header', 'image');

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          card: true,
          'card--has-footer': this.hasSlot.checkFor('footer'),
          'card--has-image': this.hasSlot.checkFor('image'),
          'card--has-header': this.hasSlot.checkFor('header')
        })}
      >
        <slot name="image" part="image" class="card__image"></slot>
        <slot name="header" part="header" class="card__header"></slot>
        <!-- Default Slot -->
        <slot part="body" class="card__body"></slot>
        <slot name="footer" part="footer" class="card__footer"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-card': WebmoduleCard;
  }
}
