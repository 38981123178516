export function tryParseFloat(value: string | null | undefined, defaultValue?: number | undefined): number | undefined {
  const v = value ? parseFloat(value) : NaN;
  if (isNaN(v)) return defaultValue;
  return v;
}

export function tryAsNumberString(value?: string, dp: number = 2): string {
  if (!value) return '';
  const v = parseFloat(value);
  if (!isNaN(v)) return v.toFixed(dp);
  return value;
}
