import { appOutOfDate, getLocalBuildNumber, getServerBuildNumberDisplay } from '../../../webmodule-common/other/debug';
import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { customElement } from 'lit/decorators.js';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { goStaticURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { html } from 'lit';
import { PageBase } from './helpers/page-base';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('app-outofdate')
export class AppOutOfDate extends PageBase {
  internalId: string = getInternalId();
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  constructor() {
    super();
  }

  protected divertToLogin(): boolean {
    return false;
  }
  protected divertToOutOfDate(): boolean {
    return false;
  }

  private get elementId(): string {
    return `outofdate-page-${this.internalId}`;
  }

  protected async awaken() {
    //if we ever reach this page and we are not logged in
    //then we want to force a page reload which will
    //take us through our full reload process of the apoplication
    //in index.html including the user login process
    if (!appOutOfDate()) goStaticURL('');
  }
  async dispose() {
    super.dispose();
  }

  render() {
    const content = asMarkdownTemplate(tlang`
            # Page Forced Refresh Required

            The system as been updated to Build "${getServerBuildNumberDisplay()}"

            Your current client build is on "${getLocalBuildNumber()}"

            a force refresh of your browser is required to flush the cache and load the new 
            compatible application.

            ## On a Windows Computer

            ### Chrome or Edge

            + Press **CTRL + SHIFT + R** (Hard Reload)
            + Alternatively, **CTRL - F5**, is also still supported

            ## On Apple Computer

            ### Chrome

            + Press **COMMAND + SHIFT + R** (Hard Refresh)

            ### Safari

            + Press **OPTION + COMMAND + E**

            `);
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container m-5">${content}</div>
      </div>
    `;
  }
  meta() {
    return {
      title: 'SoftTech Dealer',
      titleTemplate: null,
      description: 'SoftTech dealer CPQ'
    };
  }
}
