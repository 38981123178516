import { css } from 'lit';

export default css`
  :host {
    --border-raduis: var(--webmodule-border-radius-pill);
    --color: var(--webmodule-color-neutral-200);
    --shimmer-color: var(--webmodule-color-neutral-300);

    display: block;
    position: relative;
  }

  .placeholder {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 1rem;
  }

  .placeholder--display {
    flex: 1 1 auto;
    background: var(--color);
    border-radius: var(--border-raduis);
  }

  .placeholder--shimmer .placeholder--display {
    background: linear-gradient(270deg, var(--shimmer-color), var(--color), var(--color), var(--shimmer-color));
    background-size: 400% 100%;
    animation: shimmer 8s ease-in-out infinite;
  }

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    to {
      background-position: -200% 0;
    }
  }
`;
