import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './tab-panel.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

let id = 0;

/**
 * @slot - The tab panel's content.
 *
 * @csspart base - The component's base wrapper.
 *
 * @cssproperty --padding - The tab panel's padding.
 *
 * @tag webmodule-tab-panel
 */
export default class WebmoduleTabPanel extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];
  @property({ reflect: true }) name = '';
  @property({ type: Boolean, reflect: true }) active = false;
  private readonly attrId = ++id;
  private readonly componentId = `webmodule-tab-panel-${this.attrId}`;

  connectedCallback() {
    super.connectedCallback();
    this.id = this.id.length > 0 ? this.id : this.componentId;
    this.setAttribute('role', 'tabpanel');
  }

  @monitor('active')
  handleActiveChange() {
    this.setAttribute('aria-hidden', this.active ? 'false' : 'true');
  }

  render() {
    return html`
      <slot
        part="base"
        class=${classMap({
          'tab-panel': true,
          'tab-panel--active': this.active
        })}
      ></slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-tab-panel': WebmoduleTabPanel;
  }
}
