import { createContext } from '@lit/context';
import { html, nothing, render } from 'lit';

export class ImagePreview {
  private imgPreview: HTMLDivElement = document.createElement('DIV') as HTMLDivElement;

  showImagePreview(imgSrc: string, classes?: string) {
    const panel = document.body;
    const template = () => {
      return html`
        <div class="m-3 p-3 border border-1 position-fixed image-preview-context ${classes ?? nothing}">
          <img class="" src=${imgSrc} alt="A big preview" />
        </div>
      `;
    };
    render(template(), this.imgPreview);

    if (!panel) return;
    const offset = $(panel).offset() ?? { top: 0, left: 0 };
    const clientHeight = panel.clientHeight / 100;
    const clientWidth = panel.clientWidth / 100;
    const div = $(this.imgPreview).find('div')[0];
    div.style.left = `${200}px`;
    div.style.top = `${offset.top + clientHeight * 10}px`;
    div.style.width = `${clientWidth * 100 - 240}px`;
    div.style.height = `${clientHeight * 80}px`;
    $(this.imgPreview).appendTo(document.body);
    $(this.imgPreview).show();
  }

  hideImagePreview() {
    $(this.imgPreview).hide();
  }
}

export const imagePreviewContext = createContext<ImagePreview>('imagepreview');
