import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import {
  RequestGetUserProfile,
  ResultGetUserProfile,
  TenantLoginPublicInfo
} from '../../api/supplier-api-interface-user';

export class UserProfileCache extends DataCacheBase<TenantLoginPublicInfo> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }
  //override;
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<TenantLoginPublicInfo>[]> {
    const input: RequestGetUserProfile = {
      systemUserIds: requestIds
    };
    const result = await this.api.post<ResultGetUserProfile>(`api/user/GetUserProfile`, input);
    if (!result || !result?.userProfiles) return null;
    return result.userProfiles.map(c => {
      const displayValue = c.friendlyName && c.friendlyName !== '' ? c.friendlyName : (c.userName ?? '');
      const qcr: ItemReference<TenantLoginPublicInfo> = {
        id: c.id,
        displayValue: `${displayValue}`,
        data: c,
        onClick: null
      };
      return qcr;
    });
  }
}
