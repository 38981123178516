import { getApiFactory } from '../../api/api-injector';
import { NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { SupplierSettingsManager } from './supplier-settings-manager';
import { userDataStore } from './current-user-data-store';

export async function getFileContent(filePath: string): NullPromise<string> {
  const url = getApiFactory().blob().fullUrl(filePath);
  try {
    const response = await fetch(`${url}`);
    if (response.status == 200 || response.status == 206) {
      return await response.text();
    }
  } catch (error) {
    let message = '';
    if (error instanceof Error) {
      message = `Unable to get content for virtual file: ${url}. Reason: ${error.message}`;
    } else {
      message = String(error);
    }
    console.log(message);
  }
  return null;
}

export function getSettingsManager(): SupplierSettingsManager {
  return userDataStore.supplierSettingsManager;
}
