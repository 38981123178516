import { html } from 'lit';

export function getMarginMarkupHelpPage() {
  return html` <div class="marginmarkup-container">
    <header>
      <h1>MARKUP VS. MARGIN</h1>
      <p>Getting to know the difference between margin and markup.</p>
    </header>

    <section class="cheat-sheet">
      <h2>TERMINOLOGY</h2>
      <div class="terms">
        <div class="term">
          <h3>Revenue</h3>
          <p>Earnings before deducting costs</p>
        </div>
        <div class="term">
          <h3>COGS <br /><small>Cost Of Goods Sold</small></h3>
          <p>Costs to create sales items</p>
        </div>
        <div class="term">
          <h3>Gross Profit</h3>
          <p>Revenue - COGS</p>
        </div>
      </div>
    </section>

    <section class="formulas">
      <div class="formula">
        <h2>Margin</h2>
        <p>Margin shows the revenue earned after paying the COGS as a percentage of the gross profit.</p>
        <p><strong>(Gross Profit / Revenue) X 100</strong></p>
      </div>
      <div class="formula">
        <h2>Markup</h2>
        <p>Markup shows how much more your selling price is than the amount sales items cost you.</p>
        <p><strong>(Gross Profit / Cost) X 100</strong></p>
      </div>
    </section>

    <section class="example">
      <div class="margin-example">
        <h3>Find the Margin</h3>
        <ul>
          <li>
            Step 1: <strong>Find the Gross Profit</strong><br />
            <math>
              <mtext>$</mtext>
              <mn>200</mn>
              <mo>-</mo>
              <mtext>$</mtext>
              <mn>150</mn>
              <mo>=</mo>
              <mtext>$</mtext>
              <mn>50</mn>
            </math>
          </li>
          <li>
            Step 2: <strong>Gross Profit/Revenue</strong><br />
            <math>
              <mfrac>
                <mrow>
                  <mtext>$</mtext>
                  <mn>50</mn>
                </mrow>
                <mrow>
                  <mtext>$</mtext>
                  <mn>200</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mn>0.25</mn>
            </math>
          </li>
          <li>
            Step 3: <strong>Margin X 100</strong><br />
            <math>
              <mn>0.25</mn>
              <mo>x</mo>
              <mn>100</mn>
              <mo>=</mo>
              <mn>25</mn>
              <mtext>%</mtext>
            </math>
          </li>
        </ul>
      </div>

      <div class="markup-example">
        <h3>Find the Markup</h3>
        <ul>
          <li>
            Step 1: <strong>Find the Gross Profit</strong><br />
            <math>
              <mtext>$</mtext>
              <mn>200</mn>
              <mo>-</mo>
              <mtext>$</mtext>
              <mn>150</mn>
              <mo>=</mo>
              <mtext>$</mtext>
              <mn>50</mn>
            </math>
          </li>
          <li>
            Step 2: <strong>Gross Profit/COGS</strong>
            <math>
              <mfrac>
                <mrow>
                  <mtext>$</mtext>
                  <mn>50</mn>
                </mrow>
                <mrow>
                  <mtext>$</mtext>
                  <mn>150</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mn>0.33</mn>
            </math>
          </li>
          <li>
            Step 3: <strong>Markup X 100</strong><br />
            <math>
              <mn>0.33</mn>
              <mo>x</mo>
              <mn>100</mn>
              <mo>=</mo>
              <mn>33</mn>
              <mtext>%</mtext>
            </math>
          </li>
        </ul>
      </div>
    </section>

    <section class="conversion-chart">
      <h2>Markup vs. Margin Comparison</h2>
      <table>
        <thead>
          <tr>
            <th>Markup</th>
            <th>Margin</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>15%</td>
            <td>13%</td>
          </tr>
          <tr>
            <td>20%</td>
            <td>16.7%</td>
          </tr>
          <tr>
            <td>25%</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>30%</td>
            <td>23%</td>
          </tr>
          <tr>
            <td>33.33%</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>40%</td>
            <td>28.6%</td>
          </tr>
          <tr>
            <td>43%</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>50%</td>
            <td>33%</td>
          </tr>
          <tr>
            <td>75%</td>
            <td>42.9%</td>
          </tr>
          <tr>
            <td>100%</td>
            <td>50%</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>`;
}
