import { strNetworkFailed, strNetworkUnavailable } from './network-consts.js';

export interface JsonResult<T> {
  value: T | null;
  status: number;
  statusText: string;
}

export async function jsonRequest<T>(url: string, data: object, host: string): Promise<JsonResult<T>> {
  // Default options are marked with *
  try {
    const response = await fetch(`${host}/${url}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    if (response.status !== 200)
      return { value: null, status: response.status, statusText: response.statusText + ' ' + (await response.text()) };
    const r: JsonResult<T> = { value: (await response.json()) as T, status: 200, statusText: '' };
    return r;
  } catch (e) {
    if (e instanceof TypeError && e.message === strNetworkFailed) {
      return { value: null, status: 100, statusText: strNetworkUnavailable };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const msg = ((e as any).message as string) ?? '';
      return { value: null, status: 99, statusText: `strNetworkUnavailable ${msg}` };
    }
  }
}
